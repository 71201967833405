import axios from 'axios'

export default {
  computed: {
    isAdmin () {
      return this.$store.getters['misc/isAdmin']
    },
    isAdminChecking () {
      return this.$store.getters['misc/isAdminChecking']
    },
    storeUser () {
      return this.$store.getters['user/data']
    }
  },
  mounted () {
    this.adminCheck()
  },
  watch: {
    storeUser (newVal, oldVal) {
      this.adminCheck()
    }
  },
  methods: {
    async adminCheck () {
      if (!this.isAdminChecking) {
        if (this.storeUser && this.storeUser.id) {
          const url = 'https://firestore.googleapis.com/v1/projects/' + process.env.VUE_APP_FIREBASE_PROJECT_ID + '/databases/(default)/documents/admins/' + this.storeUser.id
          try {
            this.$store.commit('misc/setIsAdminChecking', { isAdminChecking: true })
            await axios.get(url)
            this.$store.commit('misc/setIsAdmin', { isAdmin: true })
          } catch (err) {
            console.log(err)
            this.$store.commit('misc/setIsAdmin', { isAdmin: false })
          }
        }
      }
    }
  }
}
