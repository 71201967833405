<template>
  <v-ons-page>
    <div class="container">
      <div class="page-content">
        <div class="page-content-inner">
          <h1>miruru</h1>
          <div class="lead-text">
            miruruは、タイムラインがヌルヌル流れて快適にテレビ実況できるTwitterクライアントです。<br>
            ツイートを通じて他の視聴者と共感し合いながら、テレビ視聴を楽しみましょう。
          </div><!-- /.lead-text -->
          <div class="twitter-login-block">
            <router-view/>
          </div><!-- /.twitter-login-block -->
        </div><!-- /.page-content-inner -->
      </div><!-- /.page-content -->
    </div><!-- /.container -->
  </v-ons-page>
</template>

<script>
export default {
  name: 'Signin'
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.container {
  height: 100%;
  background-color: white;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.page-content {
  padding: 16px 32px 32px;
  background-color: rgba(255, 255, 255, 0.9);

  .page-content-inner {
    margin: 0 auto;
    max-width: $max-width;
  }

  h1 {
    width: 100%;
    height: 60px;
    margin: 16px 0;
    background-image: url('../assets/images/logo-w.svg');
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    text-indent: -9999px;
  }

  .lead-text {
    padding: 16px 0;
    color: $text-black;
  }
}

@media screen and (min-width:800px) {
  .container {
    padding-top: 32px;
  }

  .page-content {
    padding: 32px;
    margin: 0 auto 0;
    width: fit-content;
    border-radius: 4px;

    h1 {
      height: 80px;
      margin-bottom: 32px;
    }
  }

}

.twitter-login-block {
  padding: 16px 0;
  text-align: center;
}
</style>
