export default {
  computed: {
    firestoreNgWords () {
      return this.$store.getters['firestoreNgItems/words']
    },
    firestoreNgAccounts () {
      return this.$store.getters['firestoreNgItems/accounts']
    }
  },
  watch: {
    async firestoreNgAccounts (newVal, oldVal) {
      this.tweets = await this.filterTweets(this.tweets)
    }
  },
  methods: {
    /* ============================================================
      ツイートをフィルタリング
    ============================================================ */
    async filterTweets (tweets) {
      // workerに処理を委託
      const tweetFilterWorker = new Worker('../workers/TweetFilter.worker.js', { type: 'module' })
      tweetFilterWorker.postMessage(JSON.stringify({ tweets: tweets, ngWords: this.firestoreNgWords, ngAccounts: this.firestoreNgAccounts, searchWords: this.storeTopic.searchWords }))

      // workerから返ってきた結果を処理
      const workerResultEvent = await new Promise(resolve => { tweetFilterWorker.onmessage = resolve })

      try {
        const tweets = JSON.parse(workerResultEvent.data)
        return tweets
      } catch (err) {
        console.log(err)
      }
    }
  }
}
