<template>
  <v-ons-page id="epgTables">
    <div class="epg-table-scrollview" ref="epgTableScrollview">
      <EpgTable :stations="programsOfCategories[segmentIndex]"/>
    </div>
    <v-ons-toolbar class="segment-toolbar" :inline="true">
      <div class="center segment-buttons-container">
        <v-ons-segment class="segment-buttons" @update:index="onChangeSegment">
          <button>地上波</button>
          <button>BS</button>
        </v-ons-segment>
      </div>
    </v-ons-toolbar>
    <div class="loader-container" v-show="epgTableLoadStatus[segmentIndex]">
      <ons-progress-circular class="loader" indeterminate></ons-progress-circular>
    </div><!-- /.loader-container -->
  </v-ons-page>
</template>

<script>
import HomeCommon from '@/mixins/HomeCommon'
import EpgTable from '@/components/organisms/EpgTable'
import Config from '@/plugins/Config'
import EpgTableData from '@/mixins/EpgTableData'

export default {
  name: 'EpgTables',
  components: {
    EpgTable
  },
  mixins: [HomeCommon, EpgTableData],
  data () {
    return {
      segmentIndex: 0
    }
  },
  computed: {
    epgTableLoadStatus () {
      return [0, 1].map(index => {
        return this.epgTableUrls.filter(url => url.category === index).some(url => {
          const lastLoadTime = this.epgTableDataLoadStatus[url.url] ? this.epgTableDataLoadStatus[url.url].lastLoadTime : 0
          return (this.epgTableDataLoadStatus[url.url] ? this.epgTableDataLoadStatus[url.url].loading : false) && (this.$dayjs().unix() - lastLoadTime > 90)
        })
      })
    }
  },
  mounted () {
    // 番組クリック時のイベントを処理
    this.$eventHub.$on('go-program-from-epg-table', ($event) => {
      this.goProgramFromEpgTable($event)
    })

    // 番組長押し時のイベントを処理
    this.$eventHub.$on('set-remind', ($event) => {
      this.setRemind($event.program, $event.bool)
    })

    // 現在位置にスクロール
    setTimeout(this.scrollToNow, 1000)
  },
  destroyed () {
    // 番組クリック時のイベントを削除
    this.$eventHub.$off('go-program-from-epg-table')

    // 番組長押し時のイベントを削除
    this.$eventHub.$off('set-remind')
  },
  methods: {
    /* ============================================================
      セグメント切り替え
    ============================================================ */
    onChangeSegment (index) {
      this.segmentIndex = index
    },

    /* ============================================================
      ProgramTimelinePageへ
    ============================================================ */
    goProgramFromEpgTable (program) {
      if (program.topics.length > 0) {
        this.goProgram(program)
      }
    },

    /* ============================================================
      リマインダーセット
    ============================================================ */
    setRemind (program, bool) {
      if (bool) {
        this.$store.dispatch('firestoreReminds/set', { program: this._.cloneDeep(program), userId: this.storeUser.id })
      } else {
        this.$store.dispatch('firestoreReminds/remove', { programId: program.id, userId: this.storeUser.id })
      }
    },

    /* ============================================================
      現在位置にスクロール
    ============================================================ */
    scrollToNow () {
      const tableStartTime = this.$dayjs().subtract(5, 'hours').startOf('day').add(5, 'hours')
      const startOfHour = this.$dayjs().startOf('hour')
      this.$refs.epgTableScrollview.scrollTop = startOfHour.diff(tableStartTime, 'minutes') * Config.epgTable.heightPerMin
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.epg-table-scrollview {
  width: 100%;
  height: 100%;
  background-color: $base-semi-medium-light-gray;
  overflow: auto;
}

.segment-toolbar {
  position: absolute;
  width: fit-content;
  top: auto;
  left: 50%;
  bottom: 16px;
  transform: translate(-50%, 0);
  background: none;
  box-shadow: none;
  z-index: 100000;
}

.segment-buttons-container {
  max-width: 196px;
  margin: 0;
  padding: 0 8px;
  background-color: $white-opacity06;
  border-radius: 8px;
}

.segment-buttons {
  width: 180px;
}

.segment-buttons-container.toolbar--material__center {
  height: 52px;

  .segment-buttons {
    transform: translate(0, -4px);
  }
}

.loader-container {
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
