<template>
  <v-ons-page id="tweetDetailPage">
    <v-ons-toolbar>
      <div class="left">
        <v-ons-back-button class="toolbar-button-icon"></v-ons-back-button>
      </div>
      <div class="center"></div>
    </v-ons-toolbar>

    <div class="twitter-article-block page-body">
      <twitter-article-detail :tweet="tweet" :now="$dayjs().unix()" :quote="true"/>
    </div><!-- /.twitter-article-block -->

    <div class="fabs">
      <ons-fab modifier="mini" @click="goReply">
        <ons-icon class="ion" icon="ion-ios-chatbubbles"></ons-icon>
      </ons-fab>
      <ons-fab modifier="mini" @click="openRetweetActionSheet">
        <ons-icon class="ion" icon="ion-ios-repeat"></ons-icon>
      </ons-fab>
      <ons-fab modifier="mini" class="favorite-button" :class="{favorited: this.favorited}" @click="toggleFavorites">
        <ons-icon class="ion" icon="ion-ios-heart"></ons-icon>
      </ons-fab>
      <ons-fab modifier="mini" class="ng-user-button" :class="{'is-ng-user': isNgUser}" @click="toggleNgUser" v-if="isAdmin">
        <ons-icon class="ion" icon="ion-ios-thumbs-down"></ons-icon>
      </ons-fab>
    </div><!-- /.fabs -->
  </v-ons-page>
</template>

<script>
import TwitterArticleDetail from '@/components/organisms/TwitterArticleDetail'
import TweetActions from '@/mixins/TweetActions'
import AdminCheck from '@/mixins/AdminCheck'

export default {
  name: 'TweetDetailPage',
  components: {
    TwitterArticleDetail
  },
  mixins: [TweetActions, AdminCheck],
  data () {
    return {
      favorited: false,
      retweeted: false,
      isNgUser: false
    }
  },
  computed: {
    firestoreNgAccounts () {
      return this.$store.getters['firestoreNgItems/accounts']
    }
  },
  mounted () {
    this.getRetweetedBy()
    this.getLikingUsers()
  },
  watch: {
    firestoreNgAccounts (newVal, oldVal) {
      this.isNgUser = this.firestoreNgAccounts.includes(this.tweet.author.id) || this.firestoreNgAccounts.includes(this.tweet.author.username)
    }
  },
  methods: {
    async getRetweetedBy () {
      try {
        const response = await this.retweetedByApi({ tweetId: this.tweet.id })
        const retweetedBy = response.data
        if (retweetedBy.meta.result_count > 0) {
          this.retweeted = !!retweetedBy.data.find(user => user.id === String(this.storeUser.twitterUserId))
        }
      } catch (error) {
        console.log(error)
      }
    },

    async getLikingUsers () {
      try {
        const response = await this.likingUsersApi({ tweetId: this.tweet.id })
        const likingUsers = response.data
        if (likingUsers.meta.result_count > 0) {
          this.favorited = !!likingUsers.data.find(user => user.id === String(this.storeUser.twitterUserId))
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.twitter-article-block {
  position: relative;
  margin: 0 auto;
  padding: 16px 16px 72px;
  overflow: auto;
}

.iphonex .twitter-article-block {
  padding-bottom: 106px;
}

.fabs {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  white-space: nowrap;
}

.iphonex {
  .fabs {
    bottom: 54px;
  }
}

.fab {
  margin: 8px;
  cursor: pointer;
}

.fab--mini__icon .ons-icon {
  transform: scale(0.8, 0.8) translate(0, -0.2em);
}

.favorite-button.favorited {
  background-color: $effect-color;
}

.ng-user-button.is-ng-user {
  background-color: $effect-color;
}
</style>
