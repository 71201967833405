import Api from '@/mixins/Api'

export default {
  mixins: [Api],
  computed: {
    storeTopic () {
      return this.$store.getters['topic/data']
    }
  },
  methods: {
    goReply () {
      this.$emit('push-page', { page: 'Post', data: { postType: 'reply', tweet: this.tweet } })
    },
    openRetweetActionSheet () {
      const retweetLabel = this.retweeted ? 'リツイートを解除' : 'リツイート'
      this.$ons.openActionSheet({
        title: '',
        cancelable: true,
        buttons: [
          retweetLabel,
          'コメントを付けてリツイート',
          {
            label: 'キャンセル',
            icon: 'md-close'
          }
        ]
      }).then(index => {
        switch (index) {
          case 0:
            if (this.retweeted) {
              this.unretweet()
            } else {
              this.retweet()
            }
            break
          case 1:
            this.goQuote()
            break
        }
      })
    },
    async retweet () {
      try {
        const res = await this.retweetApi({ twitterUserId: this.storeUser.twitterUserId, tweetId: this.tweet.id })
        console.log(res)
        if (res.data && res.data.data && res.data.data.retweeted) {
          this.retweeted = true
          this.$ons.notification.toast('リツイートしました', { timeout: 1000, animation: 'fall' })
        } else {
          this.$ons.notification.toast('リツイートに失敗しました', { timeout: 1000, animation: 'fall' })
        }
      } catch (err) {
        this.$ons.notification.toast('リツイートに失敗しました', { timeout: 1000, animation: 'fall' })
      }
    },
    async unretweet () {
      try {
        const res = await this.unretweetApi({ twitterUserId: this.storeUser.twitterUserId, tweetId: this.tweet.id })
        console.log(res)
        if (res.data && res.data.data && !res.data.data.retweeted) {
          this.retweeted = false
          this.$ons.notification.toast('リツイートを解除しました', { timeout: 1000, animation: 'fall' })
        } else {
          this.$ons.notification.toast('リツイート解除に失敗しました', { timeout: 1000, animation: 'fall' })
        }
      } catch (err) {
        this.$ons.notification.toast('リツイート解除に失敗しました', { timeout: 1000, animation: 'fall' })
      }
    },
    goQuote () {
      this.$emit('push-page', { page: 'Post', data: { postType: 'quote', tweet: this.tweet, topic: this.storeTopic } })
    },

    toggleFavorites () {
      if (this.favorited) {
        this.favoritesDestroy()
      } else {
        this.favoritesCreate()
      }
    },
    async favoritesCreate () {
      try {
        const res = await this.favoritesCreateApi({ twitterUserId: this.storeUser.twitterUserId, tweetId: this.tweet.id })
        if (res.data && res.data.data && res.data.data.liked) {
          this.favorited = true
          this.$ons.notification.toast('いいねしました', { timeout: 1000, animation: 'fall' })
        } else {
          this.$ons.notification.toast('いいねに失敗しました', { timeout: 1000, animation: 'fall' })
        }
      } catch (err) {
        this.$ons.notification.toast('いいねに失敗しました', { timeout: 1000, animation: 'fall' })
      }
    },
    async favoritesDestroy () {
      try {
        const res = await this.favoritesDestroyApi({ twitterUserId: this.storeUser.twitterUserId, tweetId: this.tweet.id })
        if (res.data && res.data.data && !res.data.data.liked) {
          this.favorited = false
          this.$ons.notification.toast('いいねを解除しました', { timeout: 1000, animation: 'fall' })
        } else {
          this.$ons.notification.toast('いいね解除に失敗しました', { timeout: 1000, animation: 'fall' })
        }
      } catch (err) {
        this.$ons.notification.toast('いいね解除に失敗しました', { timeout: 1000, animation: 'fall' })
      }
    },

    toggleNgUser () {
      if (this.isNgUser) {
        this.$store.dispatch('firestoreNgItems/removeAccount', { account: this.tweet.author.id })
      } else {
        this.$store.dispatch('firestoreNgItems/setAccount', { account: this.tweet.author.id })
      }
    }
  }
}
