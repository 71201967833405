<template>
  <v-ons-toast :visible.sync="toastVisible" animation="fall" @click="hideToast">
    <div>
      <p class="title">{{title}}</p>
      <p class="station">{{station}}</p>
      <p class="start-time">{{startTimeStr}} -</p>
    </div>
  </v-ons-toast>
</template>

<script>
export default {
  name: 'ReminderToast',
  data () {
    return {
      notifyPrograms: [],
      title: null,
      startTimeStr: null,
      station: null,
      toastVisible: false,
      reminderCheckTimer: []
    }
  },
  computed: {
    storeUser () {
      return this.$store.getters['user/data']
    },
    firestoreReminds () {
      return this.$store.getters['firestoreReminds/data']
    }
  },
  mounted () {
    // チェックスタート
    this.startRemindersChecking()
  },
  watch: {
    notifyPrograms (newVal, oldVal) {
      if (this.notifyPrograms.length > 0) {
        this.showToast(this.notifyPrograms[0])
      }
    }
  },
  methods: {
    /* ============================================================
      Reminder
    ============================================================ */
    /* ------------------------------------------------------------
      定期チェックをスタート
    ------------------------------------------------------------ */
    startRemindersChecking () {
      this.stopRemindersChecking()

      this.checkReminders()

      const nextMinute = this.$dayjs().startOf('minute').add(1, 'minutes')
      const delay = (nextMinute.unix() - this.$dayjs().unix()) * 1000

      this.reminderCheckTimer = setTimeout(() => {
        this.startRemindersChecking()
      }, delay)
    },

    /* ------------------------------------------------------------
      定期チェックを停止
    ------------------------------------------------------------ */
    stopRemindersChecking () {
      clearTimeout(this.reminderCheckTimer)
    },

    /* ------------------------------------------------------------
      リマインダーをチェック
    ------------------------------------------------------------ */
    checkReminders () {
      this.firestoreReminds.forEach(program => {
        if (this.$dayjs(program.startTime).isBefore(this.$dayjs())) {
          if (this.$dayjs(program.endTime).isBefore(this.$dayjs())) {
            this.removeReminds(program.id)
          } else if (this.notifyPrograms.findIndex(notifyProgram => notifyProgram.id === program.id) === -1) {
            this.notifyPrograms.push(program)
          }
        }
      })
    },

    /* ------------------------------------------------------------
      アラートを表示
    ------------------------------------------------------------ */
    showToast (program) {
      this.title = program.title
      this.startTimeStr = this.$dayjs(program.startTime).format('H:mm')
      this.station = program.station
      this.toastVisible = true
    },

    hideToast () {
      this.toastVisible = false
      this.onHideToast(this.notifyPrograms[0].id)
      setTimeout(() => {
        this.title = null
        this.programStartTime = null
        this.station = null
        this.notifyPrograms.splice(0, 1)
      }, 600)
    },

    /* ------------------------------------------------------------
      アラートクローズ時の処理
    ------------------------------------------------------------ */
    onHideToast (programId) {
      this.removeReminds(programId)
    },

    /* ------------------------------------------------------------
      リマインダーを削除
    ------------------------------------------------------------ */
    removeReminds (programId) {
      this.$store.dispatch('firestoreReminds/remove', { programId: programId, userId: this.storeUser.id })
    }
  }
}
</script>

<style scoped lang="scss">
.station,
.start-time {
  display: inline-block;
  color: #ffffff88;
}

.station {
  margin-right: 1em;
}
</style>
