export default {
  namespaced: true,
  state () {
    return {
      isAdmin: null,
      isAdminChecking: false
    }
  },
  mutations: {
    setIsAdmin (state, payload) {
      state.isAdmin = payload.isAdmin
    },
    setIsAdminChecking (state, payload) {
      state.isAdminChecking = payload.isAdminChecking
    }
  },
  getters: {
    isAdmin (state) {
      return state.isAdmin
    },
    isAdminChecking (state) {
      return state.isAdminChecking
    }
  }
}
