export default {
  /* ================================================================================
    ツイートを扱いやすい形式に変換
  ================================================================================ */
  convertTweetsFormat (tweetsData) {
    /* ReferencedTweetsにuser/mediaを紐づける処理→mediaを取得できないので封印
    let convertedReferencedTweets = []
    if (tweetsData.includes && tweetsData.includes.tweets && tweetsData.includes.users) {
      convertedReferencedTweets = tweetsData.includes.tweets.map(referencedTweet => {
        console.log(referencedTweet)
        referencedTweet.author = tweetsData.includes.users.find(user => user.id === referencedTweet.author_id)
        if (referencedTweet.attachments && referencedTweet.attachments.media_keys) {
          referencedTweet.media = referencedTweet.attachments.media_keys.map(key => tweetsData.includes.media.find(media => media.media_key === key))
        }
        return referencedTweet
      })
    }
    */

    const convertedTweets = tweetsData.data.map(tweet => {
      tweet.author = tweetsData.includes.users.find(user => user.id === tweet.author_id)
      if (tweet.attachments && tweet.attachments.media_keys) {
        tweet.media = tweet.attachments.media_keys.map(key => tweetsData.includes.media.find(media => media.media_key === key))
      }
      if (tweet.referenced_tweets) {
        tweet.referenced_tweets.map(referencedTweet => {
          if (referencedTweet.type === 'quoted') {
            referencedTweet.tweet = tweetsData.includes.tweets.find(tweet => tweet.id === referencedTweet.id)
          }
          return referencedTweet
        })
      }
      return tweet
    })
    return convertedTweets
  },

  /* ================================================================================
    ツイート本文のリンク箇所をリンク化
  ================================================================================ */
  convertToLink (str) {
    let text = str
    // eslint-disable-next-line
    text = text.replace(/((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g, '<a href="$1" target="_blank">$1</a> ')
    text = text.replace(/(^|\s)#(w*[一-龠_ぁ-ん_ァ-ヴーａ-ｚＡ-Ｚa-zA-Z0-9]+|[a-zA-Z0-9_]+|[a-zA-Z0-9_]w*)/g, ' <a href="https://twitter.com/hashtag/$2?src=hash" target="_blank">#$2</a>')
    text = text.replace(/(@[A-Za-z0-9_]{1,15})/g, ' <a href="https://twitter.com/$1" target="_blank">$1</a>')

    return text
  }
}
