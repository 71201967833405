import axios from 'axios'
export default {
  computed: {
    storeUser () {
      return this.$store.getters['user/data']
    }
  },
  methods: {
    /* ================================================================================
      GET /tweets/search/recent
    ================================================================================ */
    async searchTweetsApi (params) {
      params.token = this.storeUser.token
      params.secret = this.storeUser.secret

      const paramStr = Object.keys(params).map(key => {
        return key + '=' + encodeURIComponent(params[key])
      }).join('&')

      try {
        const res = await axios.get(process.env.VUE_APP_SERVER_URL + '/tweets/search/recent?' + paramStr)
        return res
      } catch (err) {
        this.$eventHub.$emit('show-error-toast')
        return err
      }
    },

    /* ================================================================================
      POST /tweet
    ================================================================================ */
    async postApi (params) {
      params.token = this.storeUser.token
      params.secret = this.storeUser.secret

      const paramStr = Object.keys(params).map(key => {
        return key + '=' + params[key]
      }).join('&')

      try {
        const res = await axios.get(process.env.VUE_APP_SERVER_URL + '/post-tweets?' + paramStr)
        return res
      } catch (err) {
        return err
      }
    },

    /* ================================================================================
      POST /retweet
    ================================================================================ */
    async retweetApi (params) {
      params.token = this.storeUser.token
      params.secret = this.storeUser.secret

      const paramStr = Object.keys(params).map(key => {
        return key + '=' + params[key]
      }).join('&')

      try {
        const res = await axios.get(process.env.VUE_APP_SERVER_URL + '/retweet?' + paramStr)
        return res
      } catch (err) {
        return err
      }
    },

    /* ================================================================================
      POST /unretweet
    ================================================================================ */
    async unretweetApi (params) {
      params.token = this.storeUser.token
      params.secret = this.storeUser.secret

      const paramStr = Object.keys(params).map(key => {
        return key + '=' + params[key]
      }).join('&')

      try {
        const res = await axios.get(process.env.VUE_APP_SERVER_URL + '/unretweet?' + paramStr)
        return res
      } catch (err) {
        return err
      }
    },

    /* ================================================================================
      POST /like
    ================================================================================ */
    async favoritesCreateApi (params) {
      params.token = this.storeUser.token
      params.secret = this.storeUser.secret

      const paramStr = Object.keys(params).map(key => {
        return key + '=' + params[key]
      }).join('&')

      try {
        const res = await axios.get(process.env.VUE_APP_SERVER_URL + '/like?' + paramStr)
        return res
      } catch (err) {
        return err
      }
    },

    /* ================================================================================
      POST /unlike
    ================================================================================ */
    async favoritesDestroyApi (params) {
      params.token = this.storeUser.token
      params.secret = this.storeUser.secret

      const paramStr = Object.keys(params).map(key => {
        return key + '=' + params[key]
      }).join('&')

      try {
        const res = await axios.get(process.env.VUE_APP_SERVER_URL + '/unlike?' + paramStr)
        return res
      } catch (err) {
        return err
      }
    },

    /* ================================================================================
      GET /retweeted_by
    ================================================================================ */
    async retweetedByApi (params) {
      params.token = this.storeUser.token
      params.secret = this.storeUser.secret

      const paramStr = Object.keys(params).map(key => {
        return key + '=' + params[key]
      }).join('&')

      try {
        const res = await axios.get(process.env.VUE_APP_SERVER_URL + '/tweets/' + params.tweetId + '/retweeted_by?' + paramStr)
        return res
      } catch (err) {
        return err
      }
    },

    /* ================================================================================
      GET /liking_users
    ================================================================================ */
    async likingUsersApi (params) {
      params.token = this.storeUser.token
      params.secret = this.storeUser.secret

      const paramStr = Object.keys(params).map(key => {
        return key + '=' + params[key]
      }).join('&')

      try {
        const res = await axios.get(process.env.VUE_APP_SERVER_URL + '/tweets/' + params.tweetId + '/liking_users?' + paramStr)
        return res
      } catch (err) {
        return err
      }
    }
  }
}
