<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { messaging, vapidKey } from '@/firebase/firebase'
import { getToken } from 'firebase/messaging'

export default {
  created () {
    this.setOnsPlatform()
    this.hoverForTouchDevice()
    this.getMessageToken()
  },
  methods: {
    setOnsPlatform () {
      if (!this.$ons.platform.isIOS() && !this.$ons.platform.isIPad()) {
        this.$ons.platform.select('android')
      }

      if (this.$ons.platform.isIPhoneX()) {
        document.documentElement.classList.add('iphonex')
      }
    },

    hoverForTouchDevice () {
      document.getElementsByTagName('body')[0].setAttribute('ontouchstart', '')
      const touch = 'ontouchstart' in window || navigator.msPointerEnabled
      if (touch) {
        try {
          for (var si in document.styleSheets) {
            const styleSheet = document.styleSheets[si]
            if (!styleSheet.rules) continue
            for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
              if (!styleSheet.rules[ri].selectorText) continue
              if (styleSheet.rules[ri].selectorText.match(':hover')) {
                styleSheet.rules[ri].selectorText = styleSheet.rules[ri].selectorText.replace(':hover', ':active')
              }
            }
          }
        } catch (ex) {}
      }
    },

    getMessageToken () {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          getToken(messaging, vapidKey)
            .then(async (currentToken) => {
              console.log(currentToken) // currentTokenがデバイスのトークンとなり、これは適宜データベースに保存して使用する。
            })
            .catch((e) => {
              console.log(e)
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/common';
@import '@/assets/styles/onsen-customize';
</style>
