<template>
  <v-ons-page id="programTimelinePage">
    <v-ons-toolbar>
      <div class="left">
        <v-ons-back-button class="toolbar-button-icon"></v-ons-back-button>
      </div>
      <div class="center">
        <div class="title">
          <div class="title-inner">{{storeProgram ? storeProgram.title : null}}</div>
        </div><!-- /.title -->
        <div class="program-info">
          <div class="station">{{storeProgram.station}}</div>
          <div class="time">{{storeProgram.startTimeStr}} - {{storeProgram.endTimeStr}}</div>
        </div><!-- /.program-info -->
      </div>
      <div class="right">
        <v-ons-toolbar-button @click="goProgramSetting">
          <ons-icon class="toolbar-button-icon" icon="ion-ios-help-circle" size="24px" fixed-width="false"></ons-icon>
        </v-ons-toolbar-button>
      </div>
    </v-ons-toolbar>

    <div class="page-body">
      <Timeline/>
    </div><!-- /.page-body -->
  </v-ons-page>
</template>

<script>
import Timeline from '@/components/organisms/Timeline'
import EpgConvert from '@/plugins/EpgConvert'

export default {
  name: 'ProgramTimelinePage',
  components: {
    Timeline
  },
  data () {
    return {
      localProgram: {}
    }
  },
  computed: {
    storeProgram () {
      return this.$store.getters['program/data']
    },
    firestorePublicTopics () {
      return this.$store.getters['firestorePublicTopics/data']
    }
  },
  mounted () {
    // ページ表示/非表示イベント
    document.getElementById('programTimelinePage').onShow = (event) => {
      this.$eventHub.$emit('on-show-timeline')
    }
    document.getElementById('programTimelinePage').onHide = (event) => {
      this.$eventHub.$emit('on-hide-timeline')
    }

    // mixedTopicsを生成
    this.updateMixedTopics()

    // ga tracking
    this.$gtag.event('program_timeline', {
      program_title: this.storeProgram.title
    })
  },
  watch: {
    firestorePublicTopics (newVal, oldVal) {
      const program = this._.cloneDeep(this.storeProgram)
      const convertedProgram = EpgConvert.setTopicsToProgram(program, this.firestorePublicTopics)
      this.$store.commit('program/set', { program: convertedProgram })
      this.updateMixedTopics()
    }
  },
  methods: {
    /* ============================================================
      firestorePublicTopicsが変更されたらlocalProgram.topicsとmixedTopicを更新する
    ============================================================ */
    updateMixedTopics () {
      // mixedTopicを生成
      const mixedTopic = {
        searchWords: [],
        additionalSearchWords: [],
        notWords: [],
        hashtags: [],
        additionalHashtags: []
      }
      this.storeProgram.topics.forEach(topic => {
        mixedTopic.searchWords = this._.uniq(mixedTopic.searchWords.concat(topic.searchWords))
        mixedTopic.additionalSearchWords = this._.uniq(mixedTopic.additionalSearchWords.concat(topic.additionalSearchWords))
        mixedTopic.notWords = this._.uniq(mixedTopic.notWords.concat(topic.notWords))
        mixedTopic.hashtags = this._.uniqBy(mixedTopic.hashtags.concat(topic.hashtags), 'surface')
        mixedTopic.additionalHashtags = this._.uniq(mixedTopic.additionalHashtags.concat(topic.additionalHashtags))
      })

      // storeTopicに保存
      this.$store.commit('topic/set', { topic: mixedTopic })
    },

    /* ============================================================
      番組設定ページに移動
    ============================================================ */
    goProgramSetting () {
      this.$eventHub.$emit('push-page', {
        page: 'ProgramSetting',
        data: {
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';
.toolbar {
  justify-content: stretch;
  width: 100%;
}
.toolbar__center {
  flex-grow: 1;
  width: auto;

  .title {
    position: relative;
    height: 60%;

    .title-inner {
      position: absolute;
      width: 100%;
      top: calc(53%);
      transform: translate(0, -50%);
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1em;
    }
  }

  .program-info {
    text-align: inherit;
    height: 40%;
    line-height: 1em;
    font-weight: normal;
    font-size: 12px;
    color: $text-light;

    .station,
    .time {
      display: inline-block;
    }

    .station {
      margin-right: 1em;
    }
  }
}

.left {
  width: auto;
  margin-right: 10px;
}
.right {
  width: auto;
}

.page-body {
  display: flex;
  position: absolute;
  padding-top: 5px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
}
</style>
