<template>
  <v-ons-page>
    <ul class="remocon-buttons">
      <li class="remocon-button-container" :class="'button-' + program.channel" v-for="program in programs" :key="program.link[0]">
        <RemoconButton :program="program" @click="goProgramFromCurrentTvProgram($event)"/>
      </li>
    </ul>
    <div class="loader-container" v-show="loading">
      <ons-progress-circular class="loader" indeterminate></ons-progress-circular>
    </div><!-- /.loader-container -->
  </v-ons-page>
</template>

<script>
import RemoconButton from '@/components/molecules/RemoconButton'
import HomeCommon from '@/mixins/HomeCommon'

export default {
  components: {
    RemoconButton
  },
  mixins: [HomeCommon],
  props: ['programs', 'loading'],
  methods: {
    goProgramFromCurrentTvProgram (program) {
      if (program.topics.length > 0) {
        this.goProgram(program)
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/styles/import';

.remocon-buttons {
  display: grid;
  grid-template-columns: auto auto auto;
  width: fit-content;
  margin: 0 auto;
  padding: 24px 3px 60px;
  transform-origin: 50% 0%;
  transition-duration: 0.3s;
  transition-property: transform;
}

.remocon-button-container {
  padding: 8px 3px;
}

.button-1 {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.button-2 {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
}

.button-3 {
  grid-row: 1 / 2;
  grid-column: 3 / 4;
}

.button-4 {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
}

.button-5 {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}

.button-6 {
  grid-row: 2 / 3;
  grid-column: 3 / 4;
}

.button-7 {
  grid-row: 3 / 4;
  grid-column: 1 / 2;
}

.button-8 {
  grid-row: 3 / 4;
  grid-column: 2 / 3;
}

.button-9 {
  grid-row: 3 / 4;
  grid-column: 3 / 4;
}

.button-10 {
  grid-row: 4 / 5;
  grid-column: 1 / 2;
}

.button-11 {
  grid-row: 4 / 5;
  grid-column: 2 / 3;
}

.button-12 {
  grid-row: 4 / 5;
  grid-column: 3 / 4;
}

.button-101 {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.button-102 {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
}

.button-103 {
  grid-row: 1 / 2;
  grid-column: 3 / 4;
}

.button-141 {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
}

.button-151 {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}

.button-161 {
  grid-row: 2 / 3;
  grid-column: 3 / 4;
}

.button-171 {
  grid-row: 3 / 4;
  grid-column: 1 / 2;
}

.button-181 {
  grid-row: 3 / 4;
  grid-column: 2 / 3;
}

.button-191 {
  grid-row: 4 / 5;
  grid-column: 1 / 2;
}

.button-192 {
  grid-row: 4 / 5;
  grid-column: 2 / 3;
}

.button-193 {
  grid-row: 4 / 5;
  grid-column: 3 / 4;
}

.button-211 {
  grid-row: 5 / 6;
  grid-column: 2 / 3;
}

.button-222 {
  grid-row: 5 / 6;
  grid-column: 3 / 4;
}

@media screen and (max-width:374px) {
  .remocon-buttons {
    transform-origin: 0% 0%;
    transform: scale(0.85, 0.85);
  }
}

@media screen and (min-width:600px) {
  .remocon-buttons {
    transform: scale(1.1, 1.1);
  }

  .remocon-button {
    padding: 0 8px;
  }
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

</style>
