import Vue from 'vue'
import Vuex from 'vuex'
import Misc from '@/store/modules/Misc'
import User from '@/store/modules/User'
import Program from '@/store/modules/Program'
import Topic from '@/store/modules/Topic'
import FirestoreHistory from '@/store/modules/FirestoreHistory'
import FirestoreReminds from '@/store/modules/FirestoreReminds'
import FirestorePublicTopics from '@/store/modules/FirestorePublicTopics'
import FirestoreNgItems from '@/store/modules/FirestoreNgItems'
import FirestoreSettings from '@/store/modules/FirestoreSettings'
import FirestoreUserSettings from '@/store/modules/FirestoreUserSettings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    misc: Misc,
    user: User,
    program: Program,
    topic: Topic,
    firestoreHistory: FirestoreHistory,
    firestoreReminds: FirestoreReminds,
    firestorePublicTopics: FirestorePublicTopics,
    firestoreNgItems: FirestoreNgItems,
    firestoreSettings: FirestoreSettings,
    firestoreUserSettings: FirestoreUserSettings
  },
  strict: process.env.NODE_ENV !== 'production'
})
