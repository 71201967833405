export default {
  mounted () {
    // PublicTopics
    this.initFirestorePublicTopics()
    this.startFirestorePublicTopics()

    // Settings
    this.initFirestoreSettings()
    this.startFirestoreSettings()

    // History
    this.initFirestoreHistory()
    this.startFirestoreHistory()

    // Reminds
    this.initFirestoreReminds()
    this.startFirestoreReminds()

    // NgItems
    this.initFirestoreNgItems()
    this.startFirestoreNgItems()

    // UserSettings
    this.initFirestoreUserSettings()
    this.startFirestoreUserSettings()
  },
  watch: {
    storeUser (newVal, oldVal) {
      // History
      this.initFirestoreHistory()
      this.startFirestoreHistory()

      // Reminds
      this.initFirestoreReminds()
      this.startFirestoreReminds()

      // UserSettings
      this.initFirestoreUserSettings()
      this.startFirestoreUserSettings()
    }
  },
  destroyed () {
    // PublicTopics
    this.stopFirestorePublicTopics()

    // Settings
    this.stopFirestoreSettings()

    // History
    this.stopFirestoreHistory()

    // Reminds
    this.stopFirestoreReminds()

    // NgItems
    this.stopFirestoreNgItems()

    // UserSettings
    this.stopFirestoreUserSettings()
  },
  methods: {
    /* ============================================================
      PublicTopics
    ============================================================ */
    initFirestorePublicTopics () {
      this.$store.dispatch('firestorePublicTopics/clear')
    },
    startFirestorePublicTopics () {
      this.$store.dispatch('firestorePublicTopics/startListener')
    },
    stopFirestorePublicTopics () {
      this.$store.dispatch('firestorePublicTopics/stopListener')
    },

    /* ============================================================
      Settings
    ============================================================ */
    initFirestoreSettings () {
      this.$store.dispatch('firestoreSettings/clear')
    },
    startFirestoreSettings () {
      this.$store.dispatch('firestoreSettings/startListener')
    },
    stopFirestoreSettings () {
      this.$store.dispatch('firestoreSettings/stopListener')
    },

    /* ============================================================
      History
    ============================================================ */
    initFirestoreHistory () {
      this.$store.dispatch('firestoreHistory/clear')
    },
    startFirestoreHistory () {
      if (this.storeUser && this.storeUser.id) {
        this.$store.dispatch('firestoreHistory/startListener', { userId: this.storeUser.id })
      }
    },
    stopFirestoreHistory () {
      this.$store.dispatch('firestoreHistory/stopListener')
    },

    /* ============================================================
      Reminds
    ============================================================ */
    initFirestoreReminds () {
      this.$store.dispatch('firestoreReminds/clear')
    },
    startFirestoreReminds () {
      if (this.storeUser && this.storeUser.id) {
        this.$store.dispatch('firestoreReminds/startListener', { userId: this.storeUser.id })
      }
    },
    stopFirestoreReminds () {
      this.$store.dispatch('firestoreReminds/stopListener')
    },

    /* ============================================================
      NgItems
    ============================================================ */
    initFirestoreNgItems () {
      this.$store.dispatch('firestoreNgItems/clear')
    },
    startFirestoreNgItems () {
      this.$store.dispatch('firestoreNgItems/startListener')
    },
    stopFirestoreNgItems () {
      this.$store.dispatch('firestoreNgItems/stopListener')
    },

    /* ============================================================
      UserSettings
    ============================================================ */
    initFirestoreUserSettings () {
      this.$store.dispatch('firestoreUserSettings/clear')
    },
    startFirestoreUserSettings () {
      if (this.storeUser && this.storeUser.id) {
        this.$store.dispatch('firestoreUserSettings/startListener', { userId: this.storeUser.id })
      }
    },
    stopFirestoreUserSettings () {
      this.$store.dispatch('firestoreUserSettings/stopListener')
    }
  }
}
