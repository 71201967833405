<template>
  <div class="user">
    <div class="avatar" :style="{backgroundImage: 'url(' + user.photoURL + ')'}"></div>
    <div class="user-info">
      <div class="name">{{user.displayName}}</div>
    </div>
  </div><!-- /.user -->
</template>

<script>
export default {
  name: 'User',
  props: ['user']
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.user {
  display: grid;
  width: 100%;
  grid-template-columns: 36px 1fr;
  grid-gap: 12px;
}

.avatar {
  grid-column: 1 / 2;
  width: 36px;
  height: 36px;
  background-size: cover;
  background-color: $base-dark-gray;
  border-radius: 50%;
}

.user-info {
  position: relative;
  width: 100%;
  color: $text-black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .name {
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    padding-right: 32px;
    line-height: 1.4em;
    font-weight: bold;
    color: $text-black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
