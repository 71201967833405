<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-toolbar-button @click="pop">キャンセル</v-ons-toolbar-button>
      </div>
      <div class="center">
        番組設定
      </div>
      <div class="right">
        <v-ons-toolbar-button @click="submit">完了</v-ons-toolbar-button>
      </div>
    </v-ons-toolbar>

    <div class="page-body">
      <div class="program-info-block">
        <div class="program-title">{{storeProgram ? storeProgram.title : null}}</div>
        <div class="program-subinfo" v-if="storeProgram">
          <span class="station">{{storeProgram.station}}</span>
          <span clas="time">{{storeProgram.startTimeStr}} - {{storeProgram.endTimeStr}}</span>
        </div><!-- /.program-subinfo -->
        <a class="reminder-button" :class="{on: reminding}" @click="toggleRemind" v-if="$dayjs().isBefore(storeProgram.startTime)">
          <ons-icon
            icon="ion-ios-notifications"
            size="20px"
            fixed-width="false"
          ></ons-icon>
        </a>
      </div><!-- /.program-info-block -->

      <div class="go-program-topics-list-button-block" v-if="isAdmin">
        <a class="go-program-topics-list-button" @click="goProgramTopicsList">この番組に紐付いたトピックを編集</a>
      </div><!-- /.go-program-topics-list-button-block -->

      <div class="topic-setting-block">
        <TopicSetting :topic="localTopic" :is-mixed-topic="true" />
      </div><!-- /.topic-setting-block -->
    </div><!-- /.page-body -->
  </v-ons-page>
</template>

<script>
import TopicSetting from '@/components/organisms/TopicSetting'
import TopicSettingCommon from '@/mixins/TopicSettingCommon'
import AdminCheck from '@/mixins/AdminCheck'

export default {
  name: 'ProgramSettingPage',
  components: {
    TopicSetting
  },
  mixins: [
    TopicSettingCommon,
    AdminCheck
  ],
  data () {
    return {
      localTopic: {}
    }
  },
  computed: {
    storeProgram () {
      return this.$store.getters['program/data']
    },
    storeTopic () {
      return this.$store.getters['topic/data']
    },
    firestoreReminds () {
      return this.$store.getters['firestoreReminds/data']
    },
    reminding () {
      return this.firestoreReminds.some(remind => remind.id === this.storeProgram.id)
    }
  },
  mounted () {
    // ga tracking
    /*
    this.$gtag.event('program_setting', {
      program_title: this.storeProgram.title
    })
    */

    this.setLocalTopic()
  },
  watch: {
    storeTopic (newVal, oldVal) {
      this.setLocalTopic()
    }
  },
  methods: {
    setLocalTopic () {
      this.localTopic = this._.cloneDeep(this.storeTopic)
    },

    goProgramTopicsList () {
      this.$eventHub.$emit('push-page', { page: 'ProgramTopicsList', data: {} })
    },

    submit () {
      this.$eventHub.$emit('submit-topic-setting', (topic) => {
        this.$store.commit('topic/set', { topic: topic })
      })
      this.pop()
    },

    pop () {
      this.$emit('pop-page')
    },

    toggleRemind () {
      if (this.reminding) {
        this.$store.dispatch('firestoreReminds/remove', { programId: this.storeProgram.id, userId: this.storeUser.id })
      } else {
        this.$store.dispatch('firestoreReminds/set', { program: this._.cloneDeep(this.storeProgram), userId: this.storeUser.id })
        this.$ons.notification.toast(this.storeProgram.title + 'のリマインダーをセットしました', { timeout: 3000, animation: 'lift' })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.toolbar__center {
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-body {
  background-color: $base-ultra-light-gray;
  padding: 12px;
}

.program-info-block,
.go-program-topics-list-button-block {
  position: relative;
  max-width: $max-width;
  margin: 0 auto 24px;

  .program-title {
    font-size: 1.2rem;
  }
  .program-subinfo {
    margin-top: 12px;
    color: $base-gray;

    .station {
      margin-right: 1rem;
    }
  }
  .reminder-button {
    display: block;
    position: absolute;
    width: 24px;
    right: 0;
    bottom: 0;
    line-height: inherit;
    color: $base-black-opacity01;
    transition-property: color;
    transition-duration: 0.3s;
  }

  .reminder-button.on {
    color: $text-black;
  }
}

.go-program-topics-list-button-block {
  padding-bottom: 12px;

  .go-program-topics-list-button {
    display: block;
    width: 100%;
    background-color: $theme-color;
    border-radius: 4px;
    line-height: 44px;
    text-align: center;
    color: white;
  }

  .go-program-topics-list-button:hover {
    background-color: $theme-color-light;
  }
}
</style>
