<template>
  <div class="epg-table" :style="tableStyle">
    <div class="space"></div>
    <ul class="station-header">
      <li class="station" v-for="(station, index) in stations" :key="index">
        {{station.name}}
      </li>
    </ul>
    <ul class="time-scale">
      <li class="hour" v-for="n in 24" :key="n">
        {{hourLabel(n)}}
      </li>
    </ul>
    <ul class="station-list">
      <li class="station" v-for="(station, stationIndex) in stations" :key="stationIndex">
        <EpgPrograms :programs="station.programs"/>
      </li>
    </ul>
    <div class="now-line" ref="nowLine"></div>
  </div><!-- /.epg-table -->
</template>

<script>
import EpgPrograms from '@/components/organisms/EpgPrograms'
import Config from '@/plugins/Config'

export default {
  components: {
    EpgPrograms
  },
  data () {
    return {
      nowLineTimer: null,
      nowLinePos: null
    }
  },
  props: ['stations', 'loading'],
  computed: {
    tableStyle () {
      const style = {
        minWidth: (this.stations ? this.stations.length : 0) * Config.epgTable.columnWidth + 24 + 'px',
        height: Config.epgTable.hours * 60 * Config.epgTable.heightPerMin + 24 + 'px'
      }
      return style
    }
  },
  mounted () {
    this.startMoveNowLine()
  },
  destroyed () {
    this.stopMoveNowLine()
  },
  methods: {
    /* ------------------------------------------------------------
      NowLineの自動位置変更をスタート
    ------------------------------------------------------------ */
    startMoveNowLine () {
      this.stopMoveNowLine()

      this.setNowLinePos()

      const nextMinute = this.$dayjs().startOf('minute').add(1, 'minutes')
      const delay = (nextMinute.unix() - this.$dayjs().unix()) * 1000

      this.nowLineTimer = setTimeout(() => {
        this.startMoveNowLine()
      }, delay)
    },

    /* ------------------------------------------------------------
      NowLineの自動位置変更を停止
    ------------------------------------------------------------ */
    stopMoveNowLine () {
      clearTimeout(this.nowLineTimer)
    },

    /* ------------------------------------------------------------
      NowLineの位置を設定
    ------------------------------------------------------------ */
    setNowLinePos () {
      const tableStartTime = this.$dayjs().subtract(5, 'hours').startOf('day').add(5, 'hours')
      const now = this.$dayjs().startOf('minute')
      const diff = now.diff(tableStartTime, 'minutes')
      this.$refs.nowLine.style.top = diff * Config.epgTable.heightPerMin + 24 + 'px'
    },

    /* ------------------------------------------------------------
      タイムスケールのラベル
    ------------------------------------------------------------ */
    hourLabel (index) {
      return index < 20 ? index + 4 : index - 20
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/styles/import';

.epg-table {
  display: grid;
  position: relative;
  background-color: $base-medium-light-gray;
  grid-template-columns: 24px auto;
  grid-template-rows: 24px auto;
  font-size: 13px;
}

.space {
  background-color: $base-dark-gray;
  grid-column: 1/2;
  grid-row: 1/2;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 4;
}

.station-header {
  grid-column: 2/3;
  grid-row: 1/2;
  display: flex;
  position: sticky;
  background-color: $base-gray;
  top: 0;
  z-index: 3;

  .station {
    flex-basis: 120px;
    flex-grow: 1;
    flex-shrink: 0;
    background-color: $base-gray;
    border-right: solid 1px $base-dark-gray;
    font-size: 0.875rem;
    color: white;
    text-align: center;
    line-height: 24px;
  }
}

.time-scale {
  grid-column: 1/2;
  grid-row: 2/3;
  position: sticky;
  left: 0;
  width: 24px;
  background-color: $base-gray;
  z-index: 2;

  .hour {
    height: 180px;
    padding: 8px 0;
    border-top: solid 1px $base-dark-gray;
    font-size: 0.875rem;
    color: white;
    text-align: center;
  }

  .hour:first-child {
    margin-top: -1px;
  }
}

.station-list {
  grid-column: 2/3;
  grid-row: 2/3;
  display: flex;

  .station {
    flex-basis: 120px;
    flex-grow: 1;
    flex-shrink: 0;
    position: relative;
    background-color: $base-medium-light-gray;
    border-right: solid 1px $base-gray;
  }
}

.now-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: $effect-color;
  z-index: 1;
  transition-duration: 0.3s;
  transition-property: top;
}
</style>
