<template>
  <a
    class="epg-program"
    :class="{disabled: !program || !program.topics || program.topics.length === 0, reminding: reminding}"
    @mousedown="onMousedown"
    @touchstart="onTouchstart"
    @mouseup="onMouseup"
    @touchend="onTouchend"
    @touchmove="onTouchmove"
    :style="style"
  >
    <div class="epg-program-inner">
      <div class="start-time">{{program.startTimeStr}}</div>
      <div class="title">{{program.title}}</div>
      <div class="reminding">
        <ons-icon
          icon="ion-ios-notifications"
          size="12px"
          fixed-width="false"
        ></ons-icon>
      </div><!-- /.reminding -->
    </div><!-- /.epg-program-inner -->
  </a>
</template>

<script>
import Config from '@/plugins/Config'
export default {
  name: 'EpgProgram',
  data () {
    return {
      clickTimer: null,
      mousedown: false
    }
  },
  props: ['program'],
  computed: {
    style () {
      const tableStartTime = this.$dayjs().subtract(5, 'hours').startOf('day').add(5, 'hours')
      const style = {
        top: Math.max(this.$dayjs(this.program.startTime).diff(tableStartTime, 'minutes') * Config.epgTable.heightPerMin, 0) + 'px',
        height: this.program.minutes * Config.epgTable.heightPerMin + 'px'
      }
      return style
    },
    firestoreReminds () {
      return this.$store.getters['firestoreReminds/data']
    },
    reminding () {
      return this.firestoreReminds.some(remind => remind.id === this.program.id)
    },
    isTouchable () {
      return 'ontouchstart' in window || navigator.msPointerEnabled
    }
  },
  methods: {
    onMousedown () {
      if (!this.isTouchable) {
        this.onTouchstart()
      }
    },
    onTouchstart () {
      this.clickTimer = setTimeout(() => {
        this.mousedown = false
        this.onLongPress()
      }, 500)
      this.mousedown = true
    },
    onMouseup () {
      if (!this.isTouchable) {
        this.onTouchend()
      }
    },
    onTouchend () {
      if (this.mousedown) {
        this.mousedown = false
        this.onShortClick()
      }
      clearTimeout(this.clickTimer)
    },
    onTouchmove () {
      clearTimeout(this.clickTimer)
      this.mousedown = false
    },
    onShortClick () {
      this.$eventHub.$emit('go-program-from-epg-table', this.program)
    },
    onLongPress () {
      if (this.$dayjs().isBefore(this.program.startTime)) {
        this.$eventHub.$emit('set-remind', { program: this.program, bool: !this.reminding })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.epg-program {
  display: block;
  position: absolute;
  width: 100%;
  background-color: white;
  border-top: solid 1px $base-medium-light-gray;
  color: $text-black;
  line-height: 1.2rem;
  overflow: hidden;
  transition: none;

  .epg-program-inner {
    padding: 8px;
    min-height: 100%;
    transition-property: background-color;
    transition-duration: 0.3s;
  }

  .epg-program-inner:hover {
    background-color: $theme-color-opacity02;
  }

  .start-time {
    display: inline;
    padding: 0 4px;
    margin-right: 8px;
    background-color: $theme-color;
    border-radius: 3px;
    color: white;
  }

  .title {
    display: inline;
  }

  .reminding {
    display: none;
    position: absolute;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 12px;
    border-color: $reminder-orange transparent transparent $reminder-orange;
    top: 0;
    left: 0;
    color: white;

    ons-icon {
      position: absolute;
      width: 12px;
      top: -10px;
      left: -12px;
    }
  }
}

.epg-program.disabled {
  background-color: $base-light-gray;
  pointer-events: none;
  color: $text-light;

  .start-time {
    opacity: 0.5;
  }
}

.epg-program.reminding {
  // box-shadow: 0 0 0 3px inset $theme-color-opacity04;
  .reminding {
    display: block;
  }
}
</style>
