import { firestore } from '@/firebase/firebase'

const settingsRef = firestore.collection('settings')

export default {
  namespaced: true,
  unsubscribeSettings: null,
  state () {
    return {
      data: []
    }
  },
  mutations: {
    set (state, payload) {
      state.data = payload
    }
  },
  getters: {
    data (state) {
      return state.data
    }
  },
  actions: {
    startListener ({ commit }, payload) {
      if (this.unsubscribeSettings) {
        console.warn('listener is running. ', this.unsubscribeSettings)
        this.unsubscribeSettings()
        this.unsubscribeSettings = null
      }

      // 3. Firestoreからデータを検索する
      this.unsubscribeSettings = settingsRef.doc('settings').onSnapshot(doc => {
        // 6. データが更新されるたびに呼び出される
        if (doc.data()) {
          commit('set', doc.data())
        }
      },
      (error) => {
        console.error(error.name)
      })
    },
    // 8. リスナーの停止
    stopListener () {
      if (this.unsubscribeSettings) {
        console.log('listener is stopping. ', this.unsubscribeSettings)
        this.unsubscribeSettings()
        this.unsubscribeSettings = null
      }
    },
    set ({ commit, state }, payload) {
      settingsRef.doc('settings').set(payload)
        .then(() => {
        })
        .catch(err => {
          console.error('Error setting document: ', err)
        })
    },
    clear ({ commit }) {
      commit('set', [])
    }
  }
}
