<template>
  <div class="main-navigation">
    <v-ons-navigator swipeable
      :page-stack="pageStack"
      @push-page="pushPage"
      @pop-page="popPage"
    ></v-ons-navigator>
    <ReminderToast/>
  </div>
</template>

<script>
// @ is an alias to /src
import HomePage from '@/components/pages/HomePage'
import ProgramTimelinePage from '@/components/pages/ProgramTimelinePage'
import ProgramSettingPage from '@/components/pages/ProgramSettingPage'
import ProgramTopicsListPage from '@/components/pages/ProgramTopicsListPage'
import TopicTimelinePage from '@/components/pages/TopicTimelinePage'
import TopicSettingPage from '@/components/pages/TopicSettingPage'
import PublicTopicSettingPage from '@/components/pages/PublicTopicSettingPage'
import TweetDetailPage from '@/components/pages/TweetDetailPage'
import PostPage from '@/components/pages/PostPage'
import AreaSettingPage from '@/components/pages/AreaSettingPage'
import ReminderToast from '@/components/organisms/ReminderToast'
import FirestoreManagement from '@/mixins/FirestoreManagement'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import AdminCheck from '@/mixins/AdminCheck'

export default {
  name: 'MainNavigation',
  components: {
    ReminderToast
  },
  mixins: [AdminCheck, FirestoreManagement],
  data () {
    return {
      pageStack: [HomePage]
    }
  },
  computed: {
    storeUser () {
      return this.$store.getters['user/data']
    }
  },
  mounted () {
    // オブザーバーの登録
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
      } else {
        this.$router.replace('/signin')
      }
    })

    // トークン情報がなかったらLocalStorageから読み込む
    if (!this.storeUser || !this.storeUser.token || !this.storeUser.secret) {
      if (localStorage.user) {
        const user = JSON.parse(localStorage.user)

        if (user.token && user.secret) {
          this.$store.commit('user/set', user)
        } else {
          this.$router.replace('/signin')
        }
      } else {
        this.$router.replace('/signin')
      }
    }
  },
  methods: {
    popPage () {
      this.pageStack.pop()
      this.$emit('backButton', this.pageStack)
    },
    pushPage (e) {
      if (e.page === 'Home') e.page = HomePage
      if (e.page === 'ProgramTimeline') e.page = ProgramTimelinePage
      if (e.page === 'ProgramSetting') e.page = ProgramSettingPage
      if (e.page === 'ProgramTopicsList') e.page = ProgramTopicsListPage
      if (e.page === 'TopicTimeline') e.page = TopicTimelinePage
      if (e.page === 'TopicSetting') e.page = TopicSettingPage
      if (e.page === 'PublicTopicSetting') e.page = PublicTopicSettingPage
      if (e.page === 'TweetDetail') e.page = TweetDetailPage
      if (e.page === 'Post') e.page = PostPage
      if (e.page === 'AreaSetting') e.page = AreaSettingPage
      this.pageStack.push({
        extends: e.page,
        data: () => e.data || {}
      })
      this.$emit('backButton', this.pageStack)
    }
  }
}
</script>
