import Uuid from '@/plugins/Uuid'
import AdminCheck from '@/mixins/AdminCheck'

export default {
  mixins: [AdminCheck],
  methods: {
    publishTopic (topic) {
      if (this.isAdmin) {
        const newTopic = this._.cloneDeep(topic)
        newTopic.publicId = Uuid.generate()
        this.$store.dispatch('firestorePublicTopics/set', { topic: this._.cloneDeep(newTopic) })
        this.$store.commit('topic/set', { topic: this._.cloneDeep(newTopic) })
        this.$ons.notification.toast('"' + (newTopic.manualTitle || newTopic.autoTitle) + '"をパブリッシュしました', { timeout: 1000, animation: 'fall' })

        // firestoreHistoryを更新
        this.$store.dispatch('firestoreHistory/set', { userId: this.storeUser.id, topic: this._.cloneDeep(newTopic) })
      }
    },

    updatePublicTopic (topic) {
      if (this.isAdmin) {
        this.$store.dispatch('firestorePublicTopics/set', { topic: this._.cloneDeep(topic) })

        // firestoreHistoryを更新
        this.$store.dispatch('firestoreHistory/set', { userId: this.storeUser.id, topic: this._.cloneDeep(topic) })
      }
    },

    removePublicTopic (topicId) {
      this.$store.dispatch('firestorePublicTopics/remove', { topicId: topicId })
    }
  }
}
