<template>
  <v-ons-page id="programTopicsListPage">
    <v-ons-toolbar>
      <div class="left">
        <v-ons-back-button class="toolbar-button-icon"></v-ons-back-button>
      </div>
      <div class="center">
        番組に紐づいたトピックを編集
      </div>
      <div class="right">
      </div>
    </v-ons-toolbar>

    <div class="page-body">
      <ons-list class="program-topics-list">
        <ons-list-item-with-commands
          class="list-item"
          :label="topic.manualTitle || topic.autoTitle"
          v-for="topic in storeProgram ? storeProgram.topics : []"
          :key="topic.publicId"
          tappable
          modifier="longdivider"
          @click="goPublicTopicSetting(topic)"
        />
      </ons-list>
    </div><!-- /.page-body -->
  </v-ons-page>
</template>

<script>
import OnsListItemWithCommands from '@/components/molecules/OnsListItemWithCommands'

export default {
  name: 'ProgramTopicsListPage',
  components: {
    OnsListItemWithCommands
  },
  data () {
    return {
    }
  },
  computed: {
    storeProgram () {
      return this.$store.getters['program/data']
    },
    firestorePublicTopics () {
      return this.$store.getters['firestorePublicTopics/data']
    }
  },
  mounted () {
    // ga tracking
    /*
    this.$gtag.event('program_topic_list', {
      program_title: this.storeProgram.title
    })
    */
  },
  methods: {
    goPublicTopicSetting (topic) {
      // 設定ページに移動
      this.$eventHub.$emit('push-page', {
        page: 'PublicTopicSetting',
        data: {
          topic: this._.cloneDeep(topic)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.toolbar__center {
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-body {
  ons-list {
    max-width: $max-width;
    margin: 0 auto;
    padding-bottom: 1px; // 一番下のカテゴリーを展開したときに、一番下の線が消えるのを回避する措置
  }
}
</style>
