import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import MainNavigation from '@/views/MainNavigation.vue'
import Signin from '@/views/Signin.vue'
import BeforeSignin from '@/components/organisms/BeforeSignin'
import ProcessingSignin from '@/components/organisms/ProcessingSignin'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'MainNavigation',
    component: MainNavigation,
    meta: { requiresAuth: true }
  },
  {
    path: '/signin',
    component: Signin,
    children: [
      {
        path: '',
        name: 'BeforeSignin',
        component: BeforeSignin
      },
      {
        path: 'processing',
        name: 'ProcessingSignin',
        component: ProcessingSignin
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth) {
    // このルートはログインされているかどうか認証が必要です。
    // もしされていないならば、ログインページにリダイレクトします。
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        next()
      } else {
        next({
          path: '/signin'
        })
      }
    })
  } else {
    next() // next() を常に呼び出すようにしてください!
  }
})

export default router
