<template>
  <v-ons-page id="search">
    <h1 class="page-title">
      <div class="page-title-inner">
        <ons-icon
          class="title-icon"
          icon="ion-ios-search"
          fixed-width="false"
        ></ons-icon>
        <div class="label">フリー検索</div>
      </div>
    </h1>
    <div class="search-block">
      <div class="search-block-inner">
        <word-input-set
          :words="searchWords"
          placeholder="検索ワード"
          :submit-enable="true"
          :initial-focus="false"
          @go-timeline="goTopicWithSearchWords(searchWords)"
          @update = "searchWords = $event.words"
        />
        <div class="go-timeline-button-block">
          <a class="go-timeline-button" @click="goTopicWithSearchWords(searchWords)">
            タイムラインへ
            <ons-icon class="ion" icon="ion-ios-arrow-forward" fixed-width="false"></ons-icon>
          </a>
        </div><!-- /.go-timeline-button-block -->
      </div><!-- /.search-block-inner -->
    </div><!-- /.search-block -->
  </v-ons-page>
</template>

<script>
import WordInputSet from '@/components/organisms/WordInputSet'
import HomeCommon from '@/mixins/HomeCommon'
import TopicSettingCommon from '@/mixins/TopicSettingCommon'

export default {
  name: 'Search',
  components: {
    WordInputSet
  },
  mixins: [HomeCommon, TopicSettingCommon],
  data () {
    return {
      searchWords: []
    }
  },
  methods: {
    goTopicWithSearchWords (searchWords) {
      const topic = this.createTopic(searchWords)

      if (topic.searchWords && topic.searchWords.length > 0) {
        this.goTopic(topic)
      } else {
        this.$ons.notification.toast('検索ワードを入力してください', { timeout: 1000, animation: 'fall' })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.search-block-inner {
  max-width: $max-width;
  margin: 0 auto;
}

.go-timeline-button-block {
  padding: 0 16px 16px;

  a.go-timeline-button {
    display: block;
    padding: 6px 12px;
    background-color: $theme-color;
    border-radius: 4px;
    color: white;
    text-align: center;
    white-space: nowrap;
  }
  a.go-timeline-button:hover {
    background-color: $theme-color-light;
  }
  a.go-timeline-button.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
}
</style>
