<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-toolbar-button @click="pop">キャンセル</v-ons-toolbar-button>
      </div>
      <div class="center">トピック設定</div>
      <div class="right">
        <v-ons-toolbar-button @click="submit">完了</v-ons-toolbar-button>
      </div>
    </v-ons-toolbar>

    <div class="topic-setting-block page-body">
      <TopicSetting :topic="storeTopic" />
    </div><!-- /.topic-setting-block -->
  </v-ons-page>
</template>

<script>
import TopicSetting from '@/components/organisms/TopicSetting'
import TopicSettingCommon from '@/mixins/TopicSettingCommon'
import Publish from '@/mixins/Publish'
import AdminCheck from '@/mixins/AdminCheck'

export default {
  name: 'TopicSettingPage',
  components: {
    TopicSetting
  },
  mixins: [
    Publish,
    AdminCheck,
    TopicSettingCommon
  ],
  data () {
    return {
      topic: {}
    }
  },
  computed: {
    storeTopic () {
      return this.$store.getters['topic/data']
    }
  },
  mounted () {
    this.$eventHub.$on('submit-post-done', this.pop)
  },
  destroyed () {
    this.$eventHub.$off('submit-post-done')
  },
  watch: {
    storeTopic (newVal, oldVal) {
      this.setTopic()
    }
  },
  methods: {
    setTopic () {
      this.topic = this._.cloneDeep(this.storeTopic)
    },

    submit () {
      this.$eventHub.$emit('submit-topic-setting', (topic) => {
        this.$store.commit('topic/set', { topic: topic })
      })
      this.pop()
    },

    pop () {
      this.$emit('pop-page')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.page-body {
  background-color: $base-ultra-light-gray;
  padding: 12px;
}
</style>
