export default {
  mounted () {
    this.$eventHub.$on('show-error-toast', this.showErrorToast)
  },
  destroyed () {
    this.$eventHub.$off('show-error-toast')
  },
  methods: {
    showErrorToast () {
      if (!this.toastReady) {
        this.toastReady = true
        this.$ons.notification.toast('読み込みエラー', { timeout: 1000, animation: 'fall', callback: this.onCloseToast })
      }
    },

    onCloseToast () {
      this.toastReady = false
    }
  }
}
