<template>
  <ul class="epg-programs">
    <li class="program" v-for="(program, programIndex) in programs" :key="programIndex">
      <EpgProgram :program="program"/>
    </li>
  </ul>
</template>

<script>
import EpgProgram from '@/components/organisms/EpgProgram'
export default {
  name: 'EpgPrograms',
  components: {
    EpgProgram
  },
  props: ['programs']
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.program {
  position: relative;
}

.program:first-child {
  margin-top: -1px;
}
</style>
