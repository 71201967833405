import { firestore } from '@/firebase/firebase'
import _ from 'lodash'

const ngItemsRef = firestore.collection('ngItems')

export default {
  namespaced: true,
  unsubscribeNgItems: null,
  state () {
    return {
      words: [],
      accounts: []
    }
  },
  mutations: {
    setWords (state, payload) {
      state.words = payload.words
    },
    setAccounts (state, payload) {
      state.accounts = payload.accounts
    }
  },
  getters: {
    words (state) {
      return state.words
    },
    accounts (state) {
      return state.accounts
    }
  },
  actions: {
    startListener ({ commit }, payload) {
      if (this.unsubscribeNgItems) {
        console.warn('listener is running. ', this.unsubscribeNgItems)
        this.unsubscribeNgItems()
        this.unsubscribeNgItems = null
      }

      // 3. Firestoreからデータを検索する
      this.unsubscribeNgItems = ngItemsRef.doc('items').onSnapshot(doc => {
        // 6. データが更新されるたびに呼び出される
        if (doc.data()) {
          commit('setWords', { words: doc.data().words })
          commit('setAccounts', { accounts: doc.data().accounts })
        }
      },
      (error) => {
        console.error(error.name)
      })
    },
    // 8. リスナーの停止
    stopListener () {
      if (this.unsubscribeNgItems) {
        console.log('listener is stopping. ', this.unsubscribeNgItems)
        this.unsubscribeNgItems()
        this.unsubscribeNgItems = null
      }
    },
    setWord ({ commit, state }, payload) {
      const word = payload.word
      const storeData = { words: _.clone(state.words || []), accounts: _.clone(state.accounts || []) }
      const index = storeData.words.findIndex(storeWord => storeWord === word)

      if (index !== -1) {
        storeData.words.splice(index, 1)
      }

      storeData.words.push(word)

      ngItemsRef.doc('items').set(storeData)
        .then(() => {
        })
        .catch(err => {
          console.error('Error setting document: ', err)
        })
    },
    setAccount ({ commit, state }, payload) {
      const account = payload.account
      const storeData = { words: _.clone(state.words || []), accounts: _.clone(state.accounts || []) }
      const index = storeData.accounts.findIndex(storeAccount => storeAccount === account)

      if (index !== -1) {
        storeData.accounts.splice(index, 1)
      }

      storeData.accounts.push(account)

      ngItemsRef.doc('items').set(storeData)
        .then(() => {
        })
        .catch(err => {
          console.error('Error setting document: ', err)
        })
    },
    removeWord ({ commit, state }, payload) {
      const word = payload.word
      const storeData = { words: _.clone(state.words || []), accounts: _.clone(state.accounts || []) }
      const index = storeData.words.findIndex(storeWord => storeWord === word)

      if (index !== -1) {
        storeData.words.splice(index, 1)
      }

      ngItemsRef.doc('items').set(storeData)
        .then(() => {
        })
        .catch(err => {
          console.error('Error setting document: ', err)
        })
    },
    removeAccount ({ commit, state }, payload) {
      const account = payload.account
      const storeData = { words: _.clone(state.words || []), accounts: _.clone(state.accounts || []) }
      const index = storeData.accounts.findIndex(storeAccount => storeAccount === account)

      if (index !== -1) {
        storeData.accounts.splice(index, 1)
      }

      ngItemsRef.doc('items').set(storeData)
        .then(() => {
        })
        .catch(err => {
          console.error('Error removing document: ', err)
        })
    },
    clear ({ commit }) {
      commit('setWords', { words: [] })
      commit('setAccounts', { accounts: [] })
    }
  }
}
