<template>
  <div id="timeline" class="timeline">
    <div class="hotness" :style="{backgroundPosition: hotness + '% 50%'}"></div>
    <div class="timeline-block">
      <div class="scrollview" :class="{'non-scroll': !autoScrollChangeEnable && autoScroll}" ref="scrollview" @scroll="onManualScroll">
        <div class="timeline-container" ref="timelineContainer">
          <ul class="timeline-contents" ref="timelineContents">
            <li class="tweet-item" v-for="tweet in tweets" :key="tweet.id" :class="{'current-user': tweet.author.id === String(storeUser.twitterUserId)}" @click="goTweetDetail(tweet)">
              <div class="tweet-item-inner">
                <twitter-article :tweet="tweet" :now="lastLoadTime" :quote="true"/>
              </div><!-- /.tweet-item-inner -->
            </li>
          </ul>
        </div><!-- /.scrollview-inner -->
      </div><!-- /.scrollview -->

      <ons-fab class="post-button" position="center bottom" @click="goPost" v-show="tweets.length > 0 || !loading">
        <ons-icon class="ion" icon="ion-md-create" size="30px" fixed-width="false"></ons-icon>
      </ons-fab>

      <div class="restart-button">
        <ons-fab position="right bottom" @click="autoScrollOn" v-if="!autoScroll">
          <ons-icon class="ion" icon="ion-ios-play" size="30px" fixed-width="false"></ons-icon>
        </ons-fab>
      </div>

      <ons-progress-circular class="loader" indeterminate v-show="tweets.length === 0 && loading"></ons-progress-circular>
    </div><!-- /.timeline-block -->
    <div class="post-block">
      <post-parts :is-post-page="false" post-type="tweet" :tweet="null"/>
    </div><!-- /.post-block -->
  </div>
</template>

<script>
import Api from '@/mixins/Api'
import TweetConvert from '@/plugins/TweetConvert'
import TweetFilter from '@/mixins/TweetFilter'
import TwitterArticle from '@/components/molecules/TwitterArticle'
import PostParts from '@/components/organisms/PostParts'
import Config from '@/plugins/Config'

import $ from 'jquery'
export default {
  components: {
    TwitterArticle,
    PostParts
  },
  mixins: [Api, TweetFilter],
  data () {
    return {
      query: null,
      tweets: [],
      stockedTweets: [],
      sinceId: null,
      autoLoading: false,
      loadingTimer: null,
      timelineHeight: 0,
      scrollDistance: 0,
      loadInterval: Config.timelineUpdateInterval,
      scrollDurationDelay: 1000,
      minScrollSpeed: 60,
      autoScroll: true,
      now: null,
      autoScrollChangeEnable: true,
      scrollIsRunning: false,
      pageIsActive: true,
      lastLoadTime: null,
      loading: false,
      hotness: 0
    }
  },
  computed: {
    storeUser () {
      return this.$store.getters['user/data']
    },
    storeTopic () {
      return this.$store.getters['topic/data']
    }
  },
  mounted () {
    // timelineのtransition完了イベント
    this.$refs.timelineContents.addEventListener('transitionend', () => {
      this.onFinishTimelineTransition()
    })

    // ページ表示/非表示イベント
    this.$eventHub.$on('on-show-timeline', this.onShowTimeline)
    this.$eventHub.$on('on-hide-timeline', this.onHideTimeline)
  },
  destroyed () {
    // ページ表示/非表示イベントを削除
    this.$eventHub.$off('on-show-timeline', this.onShowTimeline)
  },
  updated () {
    // ツイート追加後のtimelineの高さを取得
    const newTimelineHeight = this.$refs.timelineContents.offsetHeight

    if (newTimelineHeight > this.timelineHeight) {
      // timelineの高さが増えたときの処理
      this.onAddTweetsToTimeline(newTimelineHeight, this.timelineHeight)
    } else if (newTimelineHeight < this.timelineHeight) {
      // timelineの高さが減ったときの処理
      this.onRemoveTweetsFromTimeline(newTimelineHeight, this.timelineHeight)
    }
  },
  watch: {
    storeTopic (newVal, oldVal) {
      if (newVal && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.start()
      }
    }
  },
  methods: {
    start () {
      // query生成
      this.setQuery()

      // queryを変更して読み込み再開
      this.startLoading(this.query)
    },

    onShowTimeline () {
      this.pageIsActive = true

      // 自動読み込みを開始
      if (!this.autoLoading) {
        this.start()
      }

      // スクロール位置チェックを定期的に行う処理を開始
      if (!this.checkPositionTopTimer) {
        this.startCheckPositionTop()
      }

      // ページ表示/非表示イベント
      this.$eventHub.$off('on-hide-timeline')
      this.$eventHub.$on('on-hide-timeline', this.onHideTimeline)
    },

    onHideTimeline () {
      this.pageIsActive = false

      // 自動読み込みを停止
      this.stopLoading()

      // スクロール位置チェックを定期的に行う処理を停止
      this.stopCheckPositionTop()

      // ページ表示/非表示イベントを削除
      this.$eventHub.$off('on-hide-timeline', this.onHideTimeline)
    },

    /* ============================================================
      スクロール位置チェックを定期的に行う
    ============================================================ */
    startCheckPositionTop () {
      this.stopCheckPositionTop()
      this.checkPositionTopTimer = setInterval(this.checkScrollPositionTop, Config.checkPositionTopInterval)
    },

    stopCheckPositionTop () {
      'ストップ'
      clearInterval(this.checkPositionTopTimer)
      this.checkPositionTopTimer = null
    },

    /* ============================================================
      スクロール位置をチェック→トップにいたら処理
    ============================================================ */
    checkScrollPositionTop () {
      console.log('PositionCheck')
      if (this.getTransform(this.$refs.timelineContents).top === 0) {
        this.scrollIsRunning = false
        this.addStockedTweetsToTimeline()
      }
    },

    /* ============================================================
      クエリーを設定
    ============================================================ */
    setQuery () {
      if (this.storeTopic && this.storeTopic.searchWords && this.storeTopic.searchWords.length > 0) {
        const searchWords = this._.union(this.storeTopic.searchWords, this.storeTopic.additionalSearchWords)
        const convertedSearchWords = searchWords.map(str => {
          const words = str.split(/\s/)
          return words.filter(word => word !== '').map(word => word).join(' ')
        })
        const searchQuery = convertedSearchWords.filter(word => word !== '').map(word => '(' + word + ')').join('OR')

        const convertedNotWords = this.storeTopic.notWords.map(str => {
          const words = str.split(/\s/)
          return words.map(word => word).join(' ')
        })
        const notQuery = convertedNotWords.map(word => ' -(' + word + ')').join('')

        this.query = '(' + searchQuery + ')' + (notQuery.length > 0 ? notQuery : '')
      }
    },

    /* ============================================================
      読み込み
    ============================================================ */
    /* ------------------------------------------------------------
      ツイートの自動読み込みをスタート
    ------------------------------------------------------------ */
    startLoading (query) {
      this.stopLoading()

      this.autoLoading = true
      console.log('Start Loading')
      this.searchTweets(query, Config.tweetMaxResults, this.sinceId, 'recent')

      this.loadingTimer = setInterval(() => {
        this.searchTweets(query, Config.tweetMaxResults, this.sinceId, 'recent')
      }, this.loadInterval)
    },

    /* ------------------------------------------------------------
      ツイートの自動読み込みを停止
    ------------------------------------------------------------ */
    stopLoading () {
      this.autoLoading = false
      console.log('Stop Loading')
      clearInterval(this.loadingTimer)
    },

    /* ------------------------------------------------------------
      ツイートを読み込む
    ------------------------------------------------------------ */
    async searchTweets (query, maxResults, sinceId, resultType) {
      this.loading = true

      const params = {
        query: query,
        max_results: maxResults,
        expansions: 'author_id,attachments.media_keys,referenced_tweets.id,referenced_tweets.id.author_id',
        'tweet.fields': 'created_at',
        'user.fields': 'profile_image_url',
        'media.fields': 'width,height,url,preview_image_url'
      }
      if (sinceId) params.since_id = sinceId

      try {
        const res = await this.searchTweetsApi(params)

        if (res && res.data && res.data.data) {
          // 新規ツイートを扱いやすいように変換
          let newTweets = TweetConvert.convertTweetsFormat(res.data)

          // 新規ツイートをフィルタリング
          newTweets = await this.filterTweets(newTweets)
          newTweets = newTweets.slice(0, Config.tweetAmountToAddTimeline)

          // sinceIdを更新
          if (newTweets && newTweets.length > 0) {
            this.sinceId = newTweets[0].id
          }

          // 新規ツイートを配列に追加
          newTweets = this.stockedTweets.length > 0 ? newTweets.filter(tweet => Number(tweet.id) > Number(this.stockedTweets[0].id)) : newTweets
          this.stockedTweets = newTweets.concat(this.stockedTweets).slice(0, Config.tweetStockAmount - 10)
          this._.orderBy(this.stockedTweets, 'id', 'desc')

          // stockedTweetsから、すでにtweetsに存在するツイートがあれば削除
          this.stockedTweets = this.stockedTweets.filter(scockedTweet => {
            return !(this.tweets.find(timelineTweet => timelineTweet.id === scockedTweet.id))
          })

          if (this.stockedTweets.length > 0) {
            console.log('ツイートをストック')
          }

          // 自動スクロール時で、かつスクロールが終わっているとき、すぐにstockedTweetsをtweetsに追加
          if (this.autoScroll && !this.scrollIsRunning && this.pageIsActive) {
            this.addStockedTweetsToTimeline()
          }

          // Hotnessを設定
          this.setHotness()
        } else {
          // Hotnessを設定
          this.setHotness()
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.lastLoadTime = this.$dayjs().unix()
        this.loading = false
      }
    },

    /* ------------------------------------------------------------
      stockedTweetsをタイムラインに追加
    ------------------------------------------------------------ */
    addStockedTweetsToTimeline () {
      if (this.stockedTweets.length > 0) {
        console.log('ツイートをTimelineに追加')
        const additionalTweets = this.tweets.length > 0 ? this.stockedTweets.filter(tweet => Number(tweet.id) > Number(this.tweets[0].id)) : this.stockedTweets
        this.tweets = additionalTweets.concat(this.tweets)
        this.stockedTweets = []
      }
    },

    /* ============================================================
      タイムライン制御
    ============================================================ */
    /* ------------------------------------------------------------
      タイムラインにツイートが追加されたときの処理
    ------------------------------------------------------------ */
    onAddTweetsToTimeline (newTimelineHeight, oldTimelineHeight) {
      // スクロール距離を計算
      const currentScrollTop = this.getTransform(this.$refs.timelineContents).top
      const actualScrollDistance = (newTimelineHeight - Math.max(oldTimelineHeight, this.$refs.scrollview.offsetHeight)) - currentScrollTop
      const scrollDistance = oldTimelineHeight > 0 ? actualScrollDistance : Math.min(300, actualScrollDistance)

      // スクロール開始位置に移動
      this.$refs.timelineContents.style.transition = 'all 0ms linear'
      this.$refs.timelineContents.style.transform = 'translate3d(0px, ' + (0 - scrollDistance) + 'px, 0)'

      // スクロール開始
      if (this.autoScroll) {
        setTimeout(this.startScroll)
      }

      // this.timelineHeightをアップデート
      this.timelineHeight = newTimelineHeight

      // 上限数を超えるツイートを削除
      this.tweets = this.tweets.slice(0, Config.tweetStockAmount)
    },

    /* ------------------------------------------------------------
      タイムラインからツイートが削除されたときの処理
    ------------------------------------------------------------ */
    onRemoveTweetsFromTimeline (newTimelineHeight, oldTimelineHeight) {
      console.log('上限を超えたツイートを削除')
      // this.timelineHeightをアップデート
      if (newTimelineHeight > 0) {
        this.timelineHeight = newTimelineHeight
        console.log('タイムラインの高さ', this.timelineHeight)

        // timelineContainerの高さをtimelineの高さに合わせる
        // this.$refs.timelineContainer.style.height = this.timelineHeight + 'px'
      }
    },

    /* ------------------------------------------------------------
      スクロール開始
    ------------------------------------------------------------ */
    startScroll () {
      // スクロール距離を計算
      const scrollDistance = (0 - this.getTransform(this.$refs.timelineContents).top)

      // スクロール距離が 0 だったら終了
      if (scrollDistance === 0) {
        this.scrollIsRunning = false
        return
      }

      // durationを計算
      const actualDuration = (this.lastLoadTime * 1000 + this.loadInterval) - this.$dayjs().unix() * 1000 + this.scrollDurationDelay
      const duration = Math.min(actualDuration, Math.round(scrollDistance / this.minScrollSpeed * 1000))

      // スクロール
      this.$refs.timelineContents.style.transition = 'all ' + duration + 'ms linear'
      this.$refs.timelineContents.style.transform = 'translate3d(0px, 0px, 0)'

      // this.scrollIsRunningをtrueにする
      this.scrollIsRunning = true
    },

    /* ------------------------------------------------------------
      スクロールTransitionが終了したときの処理
    ------------------------------------------------------------ */
    onFinishTimelineTransition () {
      // stockedTweetsをtweetsに追加
      if (this.scrollIsRunning) {
        const currentScrollTop = (0 - this.getTransform(this.$refs.timelineContents).top)
        if (currentScrollTop === 0) {
          this.addStockedTweetsToTimeline()
        }
      }

      // scrollIsRunningをfalseに戻す
      if (this.getTransform(this.$refs.timelineContents).top === 0) {
        this.scrollIsRunning = false
      }
    },

    /* ------------------------------------------------------------
      手動スクロール操作時の処理
    ------------------------------------------------------------ */
    onManualScroll () {
      if (this.$refs.scrollview.scrollTop <= 0) {
        this.autoScrollOn()
      } else {
        this.autoScrollOff()
      }
    },

    /* ------------------------------------------------------------
      自動スクロールON
    ------------------------------------------------------------ */
    autoScrollOn () {
      if (!this.autoScroll && this.autoScrollChangeEnable) {
        console.log('オートスクロールON')
        const currentScrollTop = (this.getTransform(this.$refs.timelineContents).top - this.$refs.scrollview.scrollTop)
        this.$refs.scrollview.scrollTop = 0
        this.$refs.timelineContents.style.transition = 'all 0ms linear'
        this.$refs.timelineContents.style.transform = 'translate3d(0px, ' + currentScrollTop + 'px, 0)'

        // timelineContainerの高さをコンテンツ全体の高さに合わせる
        // this.$refs.timelineContainer.style.height = this.$refs.timelineContents.offsetHeight + 'px'

        setTimeout(this.startScroll)
        this.autoScroll = true

        // stockedTweetsをtweetsに追加
        this.addStockedTweetsToTimeline()

        this.autoScrollChangeDebounce()
      }
    },

    /* ------------------------------------------------------------
      自動スクロールOFF
    ------------------------------------------------------------ */
    autoScrollOff () {
      if (this.autoScroll && this.autoScrollChangeEnable) {
        console.log('オートスクロールOFF')
        const currentScrollTop = (0 - this.getTransform(this.$refs.timelineContents).top)
        this.$refs.timelineContents.style.transition = 'all 0ms linear'
        this.$refs.timelineContents.style.transform = 'translate3d(0px, ' + (0 - currentScrollTop) + 'px, 0)'

        // timelineContainerの高さをコンテンツが表示されている高さに合わせる
        // this.$refs.timelineContainer.style.height = (this.$refs.timelineContents.offsetHeight - currentScrollTop) + 'px'

        this.autoScroll = false
        this.scrollIsRunning = false

        this.autoScrollChangeDebounce()
      }
    },

    /* ------------------------------------------------------------
      自動スクロールON/OFF切り替えがレンソクして発生することを防ぐ措置
    ------------------------------------------------------------ */
    autoScrollChangeDebounce () {
      clearTimeout(this.autoScrollChangeDebounceTimer)

      this.autoScrollChangeEnable = false
      this.autoScrollChangeDebounceTimer = setTimeout(() => {
        this.autoScrollChangeEnable = true
      }, 500)
    },

    /* ------------------------------------------------------------
      Transformによるアニメーション位置を取得
    ------------------------------------------------------------ */
    getTransform (target) {
      if (!$(target).css('transform') || $(target).css('transform') === 'none') {
        return { left: 0, top: 0 }
      }

      // eslint-disable-next-line
      const translate = $(target).css('transform').match(/(-?[0-9\.]+)/g)

      var x = Number(translate[4])
      var y = Number(translate[5])

      return { left: x, top: y }
    },

    /* ============================================================
      Hotness設定
    ============================================================ */
    setHotness () {
      const seconds = 60
      const tweets = this.stockedTweets.concat(this.tweets)
      const recentTweets = tweets.filter(tweet => this.$dayjs(tweet.created_at).unix() > this.$dayjs().unix() - seconds)
      if (recentTweets.length > 0) {
        this.hotness = Math.min(
          Math.round(
            recentTweets.length / 60 * 100
          ),
          100
        )
      } else {
        this.hotness = 0
      }
      console.log('Hotness:', this.hotness)
    },

    /* ============================================================
      ツイート詳細へ移動
    ============================================================ */
    goTweetDetail (tweet) {
      this.$eventHub.$emit('push-page', { page: 'TweetDetail', data: { tweet: tweet } })
    },

    /* ============================================================
      投稿画面へ移動
    ============================================================ */
    goPost () {
      this.$eventHub.$emit('push-page', { page: 'Post', data: { topic: this.storeTopic } })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.timeline {
  display: flex;
  width: 100%;
  height: 100%;
}

.timeline-block {
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  min-height: 0;
}

.post-block {
  display: none;
}

@media screen and (min-width:800px) {
  .post-block {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    width: 33.3%;
    min-width: 240px;
    height: 100%;
    border-left: solid 1px $base-light-gray;
  }
}

.scrollview {
  overflow-y: auto;
  height: 100%;
  overflow: auto;
}

.scrollview.non-scroll {
  overflow: hidden;
}

.timeline-container {
  position: relative;
}

.timeline-contents {
  display: block;
  position: absolute;
  width: 100%;
}

.tweet-item {
  position: relative;
  padding: 16px;
  background-color: white;
  border-bottom: solid 1px $base-light-gray;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: 0.3s;
}

.tweet-item:hover {
  background-color: $theme-color-opacity02;
}

.tweet-item.current-user {
  background-color: $theme-color-opacity02;
}

.tweet-item-inner {
  max-width: $max-width;
  margin: 0 auto;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.fab {
  cursor: pointer;
}

.fab--mini__icon .ons-icon {
  transform: scale(0.7, 0.7);
}

@media screen and (min-width:800px) {
  .post-button {
    display: none;
  }
}

.restart-button .fab {
  background-color: rgba(255, 255, 255, 0.5);
  border: solid 1px $theme-color;
  color: $theme-color;

  ons-icon {
    transform: translate(2px, -4.6px);
  }
}

.hotness {
  position: absolute;
  width: 100%;
  height: 5px;
  top: 0;
  left: 0;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  transition-duration: 3s;
  transition-property: background-position;
}
</style>
