import AdminCheck from '@/mixins/AdminCheck'
import Publish from '@/mixins/Publish'
export default {
  mixins: [AdminCheck, Publish],
  methods: {
    openTopicActionSheet () {
      if (this.isAdmin) {
        if (this.storeTopic.publicId) {
          this.$ons.openActionSheet({
            title: '',
            cancelable: true,
            buttons: [
              'パブリックトピック設定を変更',
              'トピック設定を変更',
              {
                label: 'キャンセル',
                icon: 'md-close'
              }
            ]
          }).then(index => {
            switch (index) {
              case 0:
                this.goPublicTopicSetting()
                break
              case 1:
                this.goTopicSetting()
                break
            }
          })
        } else {
          this.$ons.openActionSheet({
            title: '',
            cancelable: true,
            buttons: [
              'トピック設定を変更',
              'トピックをパブリッシュ',
              {
                label: 'キャンセル',
                icon: 'md-close'
              }
            ]
          }).then(index => {
            switch (index) {
              case 0:
                if (this.storeTopic.publicId) {
                  this.goPublicTopicSetting()
                } else {
                  this.goTopicSetting()
                }
                break
              case 1:
                this.publishTopic(this.storeTopic)
                break
            }
          })
        }
      } else {
        this.$ons.openActionSheet({
          title: '',
          cancelable: true,
          buttons: [
            'トピック設定を変更',
            {
              label: 'キャンセル',
              icon: 'md-close'
            }
          ]
        }).then(index => {
          switch (index) {
            case 0:
              this.goTopicSetting()
              break
          }
        })
      }
    },

    /* ============================================================
      トピック設定へ移動
    ============================================================ */
    goPublicTopicSetting () {
      const publicTopic = this.firestorePublicTopics.find(topic => topic.publicId === this.storeTopic.publicId)

      // 設定ページに移動
      this.$eventHub.$emit('push-page', {
        page: 'PublicTopicSetting',
        data: {
          topic: this._.cloneDeep(publicTopic)
        }
      })
    },

    goTopicSetting () {
      this.$emit('push-page', { page: 'TopicSetting', data: {} })
    }
  }
}
