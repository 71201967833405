<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-toolbar-button @click="pop">キャンセル</v-ons-toolbar-button>
      </div>
      <div class="center">地域設定</div>
      <div class="right">
        <v-ons-toolbar-button @click="submit">完了</v-ons-toolbar-button>
      </div>
    </v-ons-toolbar>

    <ons-list>
      <ons-list-item
        v-for="(area, index) in areas"
        :key="index"
        tappable
        modifier="longdivider"
      >
        <label class="left">
          <ons-radio
            name="area"
            :input-id="'radio-' + area.id"
            :checked="area.id === value"
            @change="onChangeArea(area.id)"
          ></ons-radio>
        </label>
        <label :for="'radio-' + area.id" class="center">
          {{area.name}}
        </label>
      </ons-list-item>
    </ons-list>
  </v-ons-page>
</template>

<script>
import Config from '@/plugins/Config'

export default {
  name: 'AreaSettingPage',
  data () {
    return {
      areas: Config.areas,
      value: null
    }
  },
  computed: {
    storeUser () {
      return this.$store.getters['user/data']
    },
    firestoreArea () {
      return this.$store.getters['firestoreUserSettings/area']
    }
  },
  mounted () {
    this.value = this.firestoreArea
  },
  methods: {
    onChangeArea (area) {
      this.value = area
    },

    submit () {
      this.$store.dispatch('firestoreUserSettings/set', { userId: this.storeUser.id, area: this.value })
      this.pop()
    },

    pop () {
      this.$emit('pop-page')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.twitter-article-block {
  position: relative;
  margin: 0 auto;
  padding: 16px 16px 72px;
  overflow: auto;
}

.iphonex .twitter-article-block {
  padding-bottom: 106px;
}

.fabs {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  white-space: nowrap;
}

.iphonex {
  .fabs {
    bottom: 54px;
  }
}

.fab {
  margin: 8px;
  cursor: pointer;
}

.fab--mini__icon .ons-icon {
  transform: scale(0.8, 0.8) translate(0, -0.2em);
}

.favorite-button.favorited {
  background-color: $effect-color;
}

.ng-user-button.is-ng-user {
  background-color: $effect-color;
}
</style>
