export default function shortNumber (val) {
  if (typeof val === 'number') {
    if (val >= 1000000000000) {
      return (Math.round(val / 1000000000000 * 10) / 10) + ' T'
    } else if (val >= 1000000000) {
      return (Math.round(val / 1000000000 * 10) / 10) + ' B'
    } else if (val >= 1000000) {
      return (Math.round(val / 1000000 * 10) / 10) + ' M'
    } else if (val >= 1000) {
      return (Math.round(val / 1000 * 10) / 10) + ' K'
    } else {
      return Math.round(val)
    }
  } else {
    return val
  }
}
