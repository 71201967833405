export default {
  convert (str) {
    // 半角化
    str = this.hankaku(str)

    // 小文字化
    str = str.toLowerCase()

    // カタカナ化
    str = str.replace(/[ぁ-ん]/g, string => {
      return String.fromCharCode(string.charCodeAt(0) + 0x60)
    })

    return str
  },

  hankaku (str) {
    str = str.replace(/[！-～]/g, string => {
      return String.fromCharCode(string.charCodeAt(0) - 0xFEE0)
    })

    // その他文字変換
    str = str.replace(/”/g, '"')
    str = str.replace(/’/g, '\'')
    str = str.replace(/‘/g, '`')
    str = str.replace(/￥/g, '\\')
    str = str.replace(/\s/g, ' ')
    str = str.replace(/〜/g, '~')

    return str
  }
}
