<template>
  <a @click="$emit('click', program)" class="remocon-button" :class="{disabled: !program || !program.topics || program.topics.length === 0}">
    <div class="circle">
      <div class="title">
        <div class="title-inner">{{program.title}}</div>
      </div><!-- /.title -->
      <div class="time">{{program.startTimeStr}} - {{program.endTimeStr}}</div>
    </div><!-- /.circle -->
    <div class="station">{{program.station}}</div>
  </a>
</template>

<script>
export default {
  name: 'RemoconButton',
  props: ['program']
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

a.remocon-button {
  display: block;
  cursor: pointer;

  .circle {
    display: block;
    position: relative;
    width: 117px;
    height: 117px;
    margin: 0 auto;
    padding: 26px 16px;
    border: solid 1px $theme-color;
    background-color: white;
    border-radius: 50%;
    overflow: hidden;
    transition-property: filter;
    transition-duration: 0.3s;

    .title {
      position: relative;
      height: 2.88rem;
      overflow: hidden;
      word-break: break-all;
    }

    .title-inner {
      display: -webkit-box;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      max-height: 2.88rem;
      font-size: 0.8rem;
      color: $text-black;
      line-height: 1.2em;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .time {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 32px;
      background-color: $theme-color;
      font-size: 0.6rem;
      color: $text-white;
      text-align: center;
    }
  }

  .circle:hover {
    filter: brightness(1.2);
  }

  .station {
    font-size: 0.8rem;
    color: $text-black;
    text-align: center;
  }
}

a.remocon-button.disabled {
  cursor: default;
  pointer-events: none;
}

.remocon-button.disabled {
  .circle {
    border-color: transparent;
    opacity: 0.5;

    .title {
      color: $text-light;
    }
    .time {
      background-color: $base-gray;
    }
  }

  .station {
    color: $text-black;
    opacity: 0.5;
  }
}

</style>
