<template>
  <a class="button" @click="goProcessingSignin">
    <ons-icon icon="fa-twitter" fixed-width="false"></ons-icon>
    <div class="login-button-text">Twitterにログイン</div>
  </a>
</template>

<script>
export default {
  name: 'BeforeSignin',
  methods: {
    goProcessingSignin () {
      this.$router.push('/signin/processing')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.button {
  display: block;
  background-color: $base-ultra-dark-gray;
  border: none;
  color: #fff;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
  transition-duration: 0.3s;
  transition-property: background-color;
}

.login-button-text {
  display: inline-block;
  margin-left: 0.5rem;
}
.button ons-icon {
  display: inline-block;
  text-align: center;
}

.button:hover {
  background-color: $theme-color-light;
}
</style>
