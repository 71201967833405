import Uuid from '@/plugins/Uuid'

export default {
  computed: {
    storeUser () {
      return this.$store.getters['user/data']
    }
  },
  methods: {
    /* ============================================================
      ProgramTimelinePageへ移動
    ============================================================ */
    goProgram (program) {
      // storeProgramに登録
      this.$store.commit('program/set', { program: this._.cloneDeep(program) })

      // firestoreHistoryに登録
      this.$store.dispatch('firestoreHistory/set', { userId: this.storeUser.id, program: this._.cloneDeep(program) })

      // ProgramTimelinePageに移動
      this.$eventHub.$emit('push-page', {
        page: 'ProgramTimeline'
      })
    },

    /* ============================================================
      タイムラインへ移動
    ============================================================ */
    goTopic (topic) {
      const newTopic = this._.cloneDeep(topic)
      if (!newTopic.id) {
        newTopic.id = Uuid.generate()
      }

      // storeTopicに登録
      this.$store.commit('topic/set', { topic: this._.cloneDeep(newTopic) })

      // firestoreHistoryに登録
      this.$store.dispatch('firestoreHistory/set', { userId: this.storeUser.id, topic: this._.cloneDeep(newTopic) })

      // タイムラインページに移動
      this.$eventHub.$emit('push-page', {
        page: 'TopicTimeline'
      })
    }
  }
}
