import { render, staticRenderFns } from "./EpgTables.vue?vue&type=template&id=cb25c25e&scoped=true&"
import script from "./EpgTables.vue?vue&type=script&lang=js&"
export * from "./EpgTables.vue?vue&type=script&lang=js&"
import style0 from "./EpgTables.vue?vue&type=style&index=0&id=cb25c25e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb25c25e",
  null
  
)

export default component.exports