import { firestore } from '@/firebase/firebase'
import Config from '@/plugins/Config'

const userSettingsRef = firestore.collection('userSettings')

export default {
  namespaced: true,
  unsubscribeUserSettings: null,
  state () {
    return {
      area: Config.defaultArea
    }
  },
  mutations: {
    set (state, payload) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key]
      })
    }
  },
  getters: {
    area (state) {
      return state.area
    }
  },
  actions: {
    startListener ({ commit }, payload) {
      if (this.unsubscribeUserSettings) {
        console.warn('listener is running. ', this.unsubscribeUserSettings)
        this.unsubscribeUserSettings()
        this.unsubscribeUserSettings = null
      }

      // 3. Firestoreからデータを検索する
      this.unsubscribeUserSettings = userSettingsRef.doc(payload.userId).onSnapshot(doc => {
        // 6. データが更新されるたびに呼び出される
        if (doc.data()) {
          commit('set', doc.data())
        }
      },
      (error) => {
        console.error(error.name)
      })
    },
    // 8. リスナーの停止
    stopListener () {
      if (this.unsubscribeUserSettings) {
        console.log('listener is stopping. ', this.unsubscribeUserSettings)
        this.unsubscribeUserSettings()
        this.unsubscribeUserSettings = null
      }
    },
    set ({ commit, state }, payload) {
      userSettingsRef.doc(payload.userId).set({ area: payload.area })
        .then(() => {
        })
        .catch(err => {
          console.error('Error setting document: ', err)
        })
    },
    clear ({ commit }) {
      commit('set', [])
    }
  }
}
