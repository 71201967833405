<template>
  <div class="post-parts">
    <div class="user-block">
      <user :user="storeUser"/>
    </div><!-- /.user-block -->
    <div class="text-count-block">{{textCount}}</div>
    <div class="main-block">
      <div class="reply-to-block" v-if="tweet">
        <div class="reply-to-inner">
          <twitter-article :tweet="tweet" :now="$dayjs().unix()" :for-detail="true"/>
        </div><!-- /.reply-to-inner -->
      </div><!-- /.reply-to-block -->
      <div class="textarea-block">
        <textarea ref="tweetTextInput" class="tweet-text-input" placeholder="ツイート文" v-model="tweetText" @input="count"></textarea>
      </div><!-- /.textarea-block -->
    </div><!-- /.main-block -->
    <div class="submit-button-block" v-if="!isPostPage">
      <a class="submit-button" @click="post">送信</a>
    </div><!-- /.submit-button-block -->
  </div>
</template>

<script>
import Api from '@/mixins/Api'
import User from '@/components/atoms/User'
import TwitterArticle from '@/components/molecules/TwitterArticle'
import Key from '@/mixins/Key'
import ShortcutSetting from '@/mixins/ShortcutSetting'

export default {
  name: 'PostParts',
  components: {
    User,
    TwitterArticle
  },
  mixins: [Api, Key, ShortcutSetting],
  data () {
    return {
      tweetText: null,
      textCount: 0
    }
  },
  props: ['query', 'isPostPage', 'postType', 'tweet', 'topic'],
  computed: {
    storeUser () {
      return this.$store.getters['user/data']
    },
    storeTopic () {
      return this.$store.getters['topic/data']
    }
  },
  mounted () {
    this.setText()

    if (this.isPostPage) {
      this.$eventHub.$on('submit-post', () => {
        this.post()
      })
    }
  },
  destroyed () {
    if (this.isPostPage) {
      this.$eventHub.$off('submit-post')
    }
  },
  watch: {
    storeTopic (newVal, oldVal) {
      this.setText()
    }
  },
  methods: {
    async post () {
      if (this.tweetText && this.tweetText.length > 0) {
        const params = {
          text: encodeURIComponent(this.tweetText)
        }

        if (this.postType === 'reply' && this.tweet) {
          params.in_reply_to_status_id = this.tweet.id
        } else if (this.postType === 'quote' && this.tweet) {
          params.quote_tweet_id = this.tweet.id
        }

        try {
          await this.postApi(params)

          this.$ons.notification.toast('投稿しました', { timeout: 1000, animation: 'fall' })
          this.setText()

          if (this.isPostPage) {
            this.$eventHub.$emit('submit-post-done')
          }
        } catch (err) {
          this.$ons.notification.toast('投稿に失敗しました', { timeout: 1000, animation: 'fall' })
          console.log(err)
        }
      } else {
        this.$ons.notification.toast('ツイートを入力してください', { timeout: 1000, animation: 'fall' })
      }
    },

    count () {
      this.textCount = this.tweetText ? this.tweetText.length : 0
    },

    setText () {
      this.tweetText = ''

      switch (this.postType) {
        case 'reply':
          this.tweetText += ' @' + this.tweet.author.username
          break
        case 'quote':
          break
        default:
          if (this.storeTopic && this.storeTopic.searchWords && this.storeTopic.searchWords.length > 0) {
            let hashtags = []
            if (this.storeTopic.hashtags && this.storeTopic.hashtags.length > 0) {
              hashtags = this.storeTopic.hashtags.filter(hashtag => hashtag.enable).map(hashtag => hashtag.surface)
            }
            if (this.storeTopic.additionalHashtags && this.storeTopic.additionalHashtags.length > 0) {
              hashtags = this._.union(hashtags, this.storeTopic.additionalHashtags)
            }
            this.tweetText = ' ' + hashtags.join(' ')
          }
      }

      setTimeout(() => {
        this.$refs.tweetTextInput.setSelectionRange(0, 0)
      }, 100)

      this.count()

      this.$refs.tweetTextInput.focus()
    },

    popPage () {
      this.$emit('pop-page')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.post-parts {
  display: grid;
  position: relative;
  grid-template-rows: auto 1fr auto;
  max-width: $max-width;
  height: 100%;
  margin: 0 auto;
  background-color: white;
}

.user-block {
  padding: 16px 112px 16px 16px;
}

.reply-to-block {
  padding: 0 0 16px;

  .reply-to-inner {
    padding: 16px;
    border: solid 1px $base-light-gray;
    border-radius: 4px;
  }
}

.main-block {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  padding: 0 16px 16px;
  height: 100%
}

.reply-to-block {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 0 16px;

  .reply-to-inner {
    padding: 16px;
    border: solid 1px $base-light-gray;
    border-radius: 4px;
  }
}

.textarea-block {
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
}

.tweet-text-input {
  width: 100%;
  height: 100%;
  padding: 16px 16px 72px;
  background-color: $base-light-gray;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  color: $text-black;
  resize: none;
}

.text-count-block {
  position: absolute;
  right: 16px;
  top: 21px;
  font-size: 0.875rem;
  color: $base-gray;
}

.text-count-block:after {
  margin-left: 4px;
  font-size: 0.6rem;
  content: '文字';
}

.submit-button-block {
  flex-grow: 0;
  padding: 0 16px 16px;

  .submit-button {
    display: block;
    width: 100%;
    background-color: $theme-color;
    border-radius: 4px;
    line-height: 44px;
    text-align: center;
    color: white;
  }

  .submit-button:hover {
    background-color: $theme-color-light;
  }
}
</style>
