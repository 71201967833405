<template>
  <div class="twitter-article">
    <div class="avatar">
      <div class="avatar-inner" :style="{backgroundImage: 'url(' + tweet.author.profile_image_url + ')'}"></div>
    </div>
    <div class="user-info">
      <div class="user-name">{{tweet.author.name}}</div>
      <div class="screen-name">{{tweet.author.username}}</div>
    </div>
    <div class="text" v-html="tweet.text"></div>
    <ul class="image-list" v-if="tweet.media && tweet.media.length > 0" :class="'image' + tweet.media.length">
      <li v-for="(image, index) in tweet.media" :key="index" :style="{backgroundImage: 'url(' + (image.url || image.preview_image_url) + ')'}"></li>
    </ul><!-- /.image-list -->

    <!--
    <ul class="quoted-tweet-list" v-if="tweet.referenced_tweets && tweet.referenced_tweets.length > 0">
      <li class="quoted-tweet-item" v-for="referencedTweet in tweet.referenced_tweets" :key="referencedTweet.id">
        <div class="quoted-tweet-container" v-if="referencedTweet.type === 'quoted'">
          <twitter-article :tweet="referencedTweet.tweet" :now="now" :quote="false"/>
        </div>
      </li>
    </ul>--><!-- /.quoted-tweet-list -->

    <div class="timestamp">
      <div class="timestamp-inner">
        {{tweet.created_at | relativeDate('YYYY/MM/DD HH:mm:ss', now)}}
      </div><!-- /.timestamp-inner -->
    </div><!-- /.timestamp -->
  </div><!-- /.twitter-article -->
</template>

<script>
export default {
  name: 'TwitterArticle',
  props: ['tweet', 'now', 'quote'],
  watch: {
    now (newVal, oldVal) {
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.twitter-article {
  display: grid;
  grid-template-columns: 32px 5fr 1fr;
  grid-template-rows: 32px auto;
  grid-gap: 8px;
}

.avatar {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.avatar .avatar-inner {
  display: block;
  width: 32px;
  height: 32px;
  background-size: cover;
  background-color: $base-dark-gray;
  border-radius: 50%;
}

.user-info {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $text-light;
  text-align: left;
  line-height: 32px;

  .user-name {
    display: inline;
    color: $text-light;
  }

  .screen-name {
    display: inline;
    margin-left: 1em;
    font-size: 87.5%;
    color: $text-light;
  }

  .screen-name:before {
    content: '@';
  }
}

.text {
  grid-row: 2 / 3;
  grid-column: 1 / 4;
  color: $text-black;
  line-height: 1.5rem;
  text-align: left;
}

.timestamp {
  grid-row: 1 / 2;
  grid-column: 3 / 4;
  text-align: right;

  .timestamp-inner {
    display: inline;
    white-space: nowrap;
    font-size: 87.5%;
    color: $text-light;
    line-height: 32px;
  }
}

.image-list {
  grid-row: 3 / 4;
  grid-column: 1 / 4;
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;

  li {
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: none;
    background-color: $base-dark-gray;
  }

  li:before {
    content: "";
    display: block;
  }
}

.image-list.image1 {
  li {
    width: 100%;
  }

  li:before {
    padding-top: 56.25%;
  }
}

.image-list.image2 {
  display: grid;

  li:nth-child(1) {
    grid-column: 1 / 2;
  }

  li:nth-child(2) {
    grid-column: 2 / 3;
  }

  li:before {
    padding-top: 112.5%;
  }
}

.image-list.image3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50% 50%;

  li:nth-child(1) {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
  }

  li:nth-child(2) {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
  }

  li:nth-child(3) {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }

  li:nth-child(1):before {
    height: 100%;
  }

  li:nth-child(2):before,
  li:nth-child(3):before {
    padding-top: 56.25%
  }
}

.image-list.image4 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50%  50%;

  li:nth-child(1) {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  li:nth-child(2) {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
  }

  li:nth-child(3) {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }

  li:nth-child(4) {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }

  li:before {
    padding-top: 56.25%
  }
}

.quoted-tweet-list {
  grid-column: 1 / 4;

  .quoted-tweet-container {
    padding: 16px;
    background-color: $base-ultra-light-gray;
    border: solid 1px $base-light-gray;
    border-radius: 4px;
  }
  .quoted-tweet-container:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
