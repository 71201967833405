export default {
  namespaced: true,
  state () {
    return {
      data: null
    }
  },
  mutations: {
    set (state, payload) {
      state.data = payload
    },
    clear (state) {
      state.data = null
    }
  },
  getters: {
    data (state) {
      return state.data
    }
  },
  actions: {
    set ({ commit }, payload) {
      localStorage.user = JSON.stringify(payload)
      commit('set', payload)
    }
  }
}
