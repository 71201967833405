<template>
  <ons-list class="public-topics-list">
    <ons-list-item
      class="tappable"
      expandable
      v-for="category in categories" :key="category.id"
      v-show="topicsOfCategories[category.id] && topicsOfCategories[category.id].length > 0"
      modifier="longdivider"
    >
      <div class="expand-header">{{category.name}}</div>
      <ons-list class="expandable-content">
        <ons-list-item-with-commands
          class="list-item tappable"
          v-for="topic in topicsOfCategories[category.id]" :key="topic.publicId"
          :id="topic.publicId"
          :label="topic.manualTitle || topic.autoTitle"
          :additional-functions="additionalFunctions"
          tappable
          modifier="longdivider"
          @click="goTopicFromPublicTopic($event.id)"
          @public-topic-setting="goPublicTopicSetting($event.id)"
          @remove="removePublicTopic($event.id)"
        />
      </ons-list>
    </ons-list-item>
  </ons-list>
</template>

<script>
import OnsListItemWithCommands from '@/components/molecules/OnsListItemWithCommands_swipe'
import HomeCommon from '@/mixins/HomeCommon'
import Publish from '@/mixins/Publish'
import AdminCheck from '@/mixins/AdminCheck'

export default {
  name: 'PublicTopicsList',
  components: {
    OnsListItemWithCommands
  },
  mixins: [HomeCommon, Publish, AdminCheck],
  props: [
    'topics',
    'topicsOfCategories',
    'categories'
  ],
  computed: {
    additionalFunctions () {
      if (this.isAdmin) {
        return [
          {
            label: '削除',
            color: '#E53734',
            eventName: 'remove'
          }
        ]
      } else {
        return []
      }
    }
  },
  methods: {
    /* ============================================================
      タイムラインへ移動
    ============================================================ */
    goTopicFromPublicTopic (topicId) {
      const topic = this.topics.find(topic => topic.publicId === topicId)

      if (topic) {
        this.goTopic(topic)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

ons-list {
  padding-bottom: 1px; // 一番下のカテゴリーを展開したときに、一番下の線が消えるのを回避する措置
}

ons-list.expandable-content {
  padding: 0;
}

.expanded {
  font-weight: bold;
}

.tappable {
  cursor: pointer;
}

@media screen and (min-width:600px) {
  .topics-block {
    margin-top: -32px;
  }
}

</style>
