<template>
  <div>
    <div class="loading">
      <ons-progress-circular class="loader" indeterminate></ons-progress-circular>
    </div><!-- /.loading -->
    <div class="cancel-button-block">
      <a @click="$router.push('/')">やりなおす</a>
    </div><!-- /.cancel-button-block -->
  </div>
</template>

<script>
import { getAuth, signInWithPopup, TwitterAuthProvider } from 'firebase/auth'
export default {
  name: 'ProcessingSignin',
  mounted () {
    this.signin()
  },
  methods: {
    signin () {
      const provider = new TwitterAuthProvider()
      const auth = getAuth()
      auth.languageCode = 'ja'
      signInWithPopup(auth, provider)
        .then((result) => {
          const credential = TwitterAuthProvider.credentialFromResult(result)
          const user = result.user
          if (user) {
            this.$store.dispatch('user/set', {
              id: user.uid,
              displayName: user.displayName,
              username: user.reloadUserInfo ? user.reloadUserInfo.screenName : null,
              twitterUserId: user.providerData[0].uid,
              photoURL: user.photoURL,
              token: credential.accessToken,
              secret: credential.secret
            })

            this.$router.push('/')
          }
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code
          const errorMessage = error.message
          console.log(errorCode, errorMessage)
          this.$router.push('/signin')
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';
.loading {
  display: block;
  height: 40px;
  padding: 4px 0;
  background-color: $base-ultra-dark-gray;
  border: none;
  border-radius: 3px;
}

.cancel-button-block {
  margin-top: 16px;

  a {
    display: block;
    height: 40px;
    padding: 4px 0;
  }
}
</style>
