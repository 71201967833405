import dayjs from 'dayjs'
export default function relativeDate (val, format, now) {
  const postTime = dayjs(val).unix()
  const gap = now - postTime
  if (Math.round(gap) < 60) {
    return Math.round(gap) + '秒前'
  } else if (Math.round(gap / 60) < 60) {
    return Math.round(gap / 60) + '分前'
  } else if (Math.round(gap / 60 / 60) < 24) {
    return Math.round(gap / 60 / 60) + '時間前'
  } else if (Math.round(gap / 60 / 60 / 24) < 7) {
    return Math.round(gap / 60 / 60 / 24) + '日前'
  } else {
    return dayjs(val).format(format)
  }
}
