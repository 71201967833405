export default {
  data () {
    return {
      commandPress: false,
      targetKeys: []
    }
  },
  created: function () {
    // キー入力受付
    window.onkeydown = (event) => {
      const key = event.key
      const code = event.code
      let keyStr = ''

      // commandキーが押された場合の処理
      if (key === 'Meta') {
        this.commandPress = true
      }

      // spaceキーが押された場合の処理
      if (code === 'Space') {
        this.spacePress = true
      }

      // 修飾キーが入力された場合はイベントを発火させない
      if (key === 'Alt' || key === 'Control' || key === 'Shift' || key === 'Meta') {
        return
      }

      // key文字列生成
      if (event.altKey) {
        keyStr += 'alt + '
      }

      if (this.commandPress) {
        keyStr += 'command + '
      }
      if (event.ctrlKey) {
        keyStr += 'ctrl + '
      }

      if (event.shiftKey) {
        keyStr += 'shift + '
      }

      keyStr += code

      this.$eventHub.$emit('key-press', keyStr)
    }

    window.onkeyup = (event) => {
      const key = event.key
      const code = event.code
      let keyStr = ''

      // commandキーが離された場合の処理
      if (key === 'Meta') {
        this.commandPress = false
      }

      keyStr += code

      this.$eventHub.$emit('key-up', keyStr)
    }

    window.onblur = (event) => {
      this.commandPress = false
    }
  }
}
