<template>
  <v-ons-page id="topicTimelinePage">
    <v-ons-toolbar>
      <div class="left">
        <v-ons-back-button class="toolbar-button-icon"></v-ons-back-button>
      </div>
      <div class="center">
        {{storeTopic.manualTitle || storeTopic.autoTitle}}
      </div>
      <div class="right">
        <v-ons-toolbar-button @click="openTopicActionSheet">
          <ons-icon class="toolbar-button-icon" icon="ion-ios-more" size="20px" fixed-width="false"></ons-icon>
        </v-ons-toolbar-button>
      </div>
    </v-ons-toolbar>

    <div class="page-body">
      <Timeline :topic='storeTopic' />
    </div><!-- /.page-body -->
  </v-ons-page>
</template>

<script>
import Timeline from '@/components/organisms/Timeline'
import TopicActions from '@/mixins/TopicActions'
export default {
  name: 'TopicTimelinePage',
  components: {
    Timeline
  },
  mixins: [TopicActions],
  data () {
    return {
    }
  },
  computed: {
    storeTopic () {
      return this.$store.getters['topic/data']
    },
    firestorePublicTopics () {
      return this.$store.getters['firestorePublicTopics/data']
    }
  },
  mounted () {
    // ページ表示/非表示イベント
    document.getElementById('topicTimelinePage').onShow = (event) => {
      this.$eventHub.$emit('on-show-timeline')
    }
    document.getElementById('topicTimelinePage').onHide = (event) => {
      this.$eventHub.$emit('on-hide-timeline')
    }

    // ga tracking
    /*
    this.$gtag.event('topic', {
      topic_title: this.storeTopic.manualTitle || this.storeTopic.autoTitle
    })
    */
  },
  watch: {
    firestorePublicTopics (newVal, oldVal) {
      if (this.storeTopic.publicId) {
        const topic = this.firestorePublicTopics.find(topic => topic.publicId === this.storeTopic.publicId)
        this.$store.commit('topic/set', { topic: topic })
      }
    }
  },
  methods: {
    openProgramMenu () {
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.toolbar__center {
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-body {
  display: flex;
  position: absolute;
  padding-top: 5px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
}
</style>
