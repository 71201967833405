import axios from 'axios'
import NameIdentification from '@/plugins/NameIdentification'
import Config from '@/plugins/Config'
import Async from '@/plugins/Async'

export default {
  data () {
    return {
      epgTableUrls: [],
      loadingTimer: null,
      programsOfCategories: new Array(2),
      epgTableDataLoadStatus: {},
      epgStartTime: null
    }
  },
  computed: {
    firestorePublicTopics () {
      return this.$store.getters['firestorePublicTopics/data']
    },
    firestoreArea () {
      return this.$store.getters['firestoreUserSettings/area']
    }
  },
  mounted () {
    if (this.firestoreArea) {
      this.setEpgTableUrls()
      this.startLoading()
    }

    this.$eventHub.$on('reload-epg', this.reloadEpgTableData)
  },
  destroyed () {
    this.stopLoading()
    this.$eventHub.$off('reload-epg')
  },
  watch: {
    async firestorePublicTopics (newVal, oldVal) {
      if (!this.programsOfCategories) return

      // トピックを割り当てる
      this.programsOfCategories = await Async.asyncMap(this.programsOfCategories, async stations => {
        if (!stations) return stations
        const convertedStations = await this.setTopics(stations)
        return convertedStations
      })
    },
    firestoreArea (newVal, oldVal) {
      if (this.firestoreArea && newVal !== oldVal) {
        this.programsOfCategories = new Array(2)
        this.setEpgTableUrls()
        this.reloadEpgTableData()
        this.startLoading()
      }
    }
  },
  methods: {
    /* ================================================================================
      EPG Table
    ================================================================================ */
    /* ------------------------------------------------------------
      URLリストを作成
    ------------------------------------------------------------ */
    setEpgTableUrls () {
      let epgTableUrls = []

      const tvUrls = Config.areas.find(area => area.id === this.firestoreArea).areas.map(area => {
        return {
          url: Config.epgTableUrlTemplate.replace(/{area}/, area),
          category: 0,
          area: this.firestoreArea
        }
      })
      epgTableUrls = epgTableUrls.concat(tvUrls)

      const bsUrls = Config.bsEpgTableUrls.map(url => {
        return {
          url: url,
          category: 1,
          area: this.firestoreArea
        }
      })
      epgTableUrls = epgTableUrls.concat(bsUrls)

      this.epgTableUrls = epgTableUrls
    },

    /* ------------------------------------------------------------
      EPG Tableの自動読み込みをスタート
    ------------------------------------------------------------ */
    startLoading () {
      this.stopLoading()

      console.log('Start Loading')
      this.getEpgTableData()

      const nextMinute = this.$dayjs().startOf('minute').add(1, 'minutes')
      const delay = (nextMinute.unix() - this.$dayjs().unix()) * 1000

      this.loadingTimer = setTimeout(() => {
        this.startLoading()
      }, delay)
    },

    /* ------------------------------------------------------------
      EPGの自動読み込みを停止
    ------------------------------------------------------------ */
    stopLoading () {
      console.log('Stop Loading')
      clearTimeout(this.loadingTimer)
    },

    /* ------------------------------------------------------------
      EPGを取得
    ------------------------------------------------------------ */
    getEpgTableData () {
      const dateStr = this.$dayjs().clone().subtract(5, 'hours').startOf('day').add(5, 'hours').format('YYYYMMDDHHmm')

      this.epgTableUrls.forEach(async url => {
        const requestUrl = url.url.replace(/{YYYYMMDDHHmm}/, dateStr)
        if (this.epgTableDataLoadStatus[url.url] && this.epgTableDataLoadStatus[url.url].loading) return
        if (this.epgStartTime && this.$dayjs().isBefore(this.epgStartTime.clone().add(1, 'days'))) return

        try {
          // this.epgTableDataLoadStatus変更
          const epgTableDataLoadStatus = this._.cloneDeep(this.epgTableDataLoadStatus)
          epgTableDataLoadStatus[url.url] = {
            loading: true,
            lastLoadTime: epgTableDataLoadStatus[url.url] && epgTableDataLoadStatus[url.url].lastLoadTime ? epgTableDataLoadStatus[url.url].lastLoadTime : null
          }
          this.epgTableDataLoadStatus = epgTableDataLoadStatus

          // 読み込み処理
          const epgHtml = await this.loadEpg(requestUrl)

          // areaが変更されていたら、読み込んだデータを破棄
          if (url.area === this.firestoreArea) {
            // HTMLをパース
            const dom = this.parseEpgHtml(epgHtml)

            // ヘッダーセルから放送局名を取り出す
            const stationNames = this.getStationNames(dom)

            // 番組セルから番組データを取り出す
            const programs = this.getPrograms(dom)

            // workerに処理を委託
            const epgTableDataWorker = new Worker('../workers/EpgTableData.worker.js', { type: 'module' })
            epgTableDataWorker.postMessage(JSON.stringify({ stationNames: stationNames, programs: programs, category: url.category }))

            // workerから返ってきた結果を処理
            const workerResultEvent = await new Promise(resolve => { epgTableDataWorker.onmessage = resolve })

            try {
              let stations = JSON.parse(workerResultEvent.data)

              // 番組にトピックを割り当てる
              stations = await this.setTopics(stations)

              // 新規に生成した番組データを、既存の番組データに結合
              this.programsOfCategories = this.joinEpgData(stations, this.programsOfCategories, url.category)

              // epgStartTimeを更新
              this.epgStartTime = this.$dayjs().clone().subtract(5, 'hours').startOf('day').add(5, 'hours')
            } catch (err) {
              console.log(err)
            }
          }
        } catch (err) {
          console.log(err)
        } finally {
          const epgTableDataLoadStatus = this._.cloneDeep(this.epgTableDataLoadStatus)
          epgTableDataLoadStatus[url.url] = {
            loading: false,
            lastLoadTime: this.$dayjs()
          }
          this.epgTableDataLoadStatus = epgTableDataLoadStatus
        }
      })
    },

    /* ------------------------------------------------------------
      EPGを読み込む
    ------------------------------------------------------------ */
    async loadEpg (url) {
      try {
        const res = await axios.get(process.env.VUE_APP_SERVER_URL + '/text/?url=' + encodeURIComponent(url), { responseType: 'arraybuffer' })
        const buffer = Buffer.from(res.data, 'binary')
        const text = buffer.toString()
        return text
      } catch (err) {
        this.$eventHub.$emit('show-error-toast')
        return err
      }
    },

    /* ------------------------------------------------------------
      EPG HTMLをパース
    ------------------------------------------------------------ */
    parseEpgHtml (html) {
      const parser = new DOMParser()
      let dom = null

      try {
        // ------------------------------------------------------------
        // XML 文字列から Document オブジェクトを作成する
        // ------------------------------------------------------------
        dom = parser.parseFromString(html, 'text/html')

        // ------------------------------------------------------------
        // パースに失敗したか調べる
        // ------------------------------------------------------------
        if (dom.getElementsByTagName('parsererror').length) {
          dom = null
        }

        return dom
      } catch (err) {
        console.log(err)
      }
    },

    /* ------------------------------------------------------------
      EPG DOMから放送局名を取得
    ------------------------------------------------------------ */
    getStationNames (dom) {
      const headerCells = Array.from(dom.querySelectorAll('.cell-station:not(.cell-top)'))
      const stationNames = headerCells.map(cell => {
        return { name: NameIdentification.hankaku(cell.title), x: parseInt(cell.style.left) }
      })
      return stationNames
    },

    /* ------------------------------------------------------------
      EPG DOMから番組データを取得
    ------------------------------------------------------------ */
    getPrograms (dom) {
      const programCells = Array.from(dom.getElementsByClassName('cell-schedule'))

      const programs = programCells.map(cell => {
        // タイトル取得
        const titleElement = cell.querySelector('.schedule-title') || cell.querySelector('.schedule-titleC')
        const title = titleElement ? titleElement.innerText : null

        // classNames取得
        const className = cell.className

        // cellのstyle取得
        const style = cell.style

        return {
          title: title,
          className: className,
          left: style.left,
          height: style.height
        }
      })

      return programs
    },

    /* ------------------------------------------------------------
      番組にトピックを割り当てる
    ------------------------------------------------------------ */
    async setTopics (stations) {
      // workerに処理を委託
      const setTopicsToEpgTableWorker = new Worker('../workers/SetTopicsToEpgTable.worker.js', { type: 'module' })
      setTopicsToEpgTableWorker.postMessage(JSON.stringify({ stations: stations, publicTopics: this.firestorePublicTopics }))

      // workerから返ってきた結果を処理
      const workerResultEvent = await new Promise(resolve => { setTopicsToEpgTableWorker.onmessage = resolve })
      try {
        return JSON.parse(workerResultEvent.data)
      } catch (err) {
        console.log(err)
        return stations
      }
    },

    /* ------------------------------------------------------------
      生成した番組表データを既存の番組表データと結合
    ------------------------------------------------------------ */
    joinEpgData (stations, programsOfCategories, category) {
      const categories = this._.cloneDeep(programsOfCategories)
      categories[category] = stations.concat(categories[category]).filter(station => station)
      categories[category] = this._.orderBy(categories[category], ['channel'], ['asc'])
      categories[category] = this._.uniqBy(categories[category], 'name')
      return categories
    },

    /* ------------------------------------------------------------
      EPGをリロード
    ------------------------------------------------------------ */
    reloadEpgTableData () {
      this.epgStartTime = null
      this.epgTableDataLoadStatus = {}
      this.getEpgTableData()
    }
  }
}
