import { firestore } from '@/firebase/firebase'
import _ from 'lodash'

const remindsRef = firestore.collection('reminds')

export default {
  namespaced: true,
  unsubscribeReminds: null,
  state () {
    return {
      data: []
    }
  },
  mutations: {
    set (state, payload) {
      state.data = payload
    }
  },
  getters: {
    data (state) {
      return state.data
    }
  },
  actions: {
    startListener ({ commit }, payload) {
      if (this.unsubscribeReminds) {
        console.warn('listener is running. ', this.unsubscribeReminds)
        this.unsubscribeReminds()
        this.unsubscribeReminds = null
      }

      // 3. Firestoreからデータを検索する
      this.unsubscribeReminds = remindsRef.doc(payload.userId).onSnapshot(doc => {
        // 6. データが更新されるたびに呼び出される
        if (doc.data()) {
          commit('set', doc.data().reminds)
        }
      },
      (error) => {
        console.error(error.name)
      })
    },
    // 8. リスナーの停止
    stopListener () {
      if (this.unsubscribeReminds) {
        console.log('listener is stopping. ', this.unsubscribeReminds)
        this.unsubscribeReminds()
        this.unsubscribeReminds = null
      }
    },
    set ({ commit, state }, payload) {
      const program = {
        id: payload.program.id,
        title: payload.program.title,
        startTime: payload.program.startTime,
        endTime: payload.program.endTime,
        station: payload.program.station
      }

      const storeReminds = _.cloneDeep(state.data)
      const index = storeReminds.findIndex(storeRemind => storeRemind.id === program.id)
      if (index !== -1) {
        storeReminds.splice(index, 1)
      }
      storeReminds.push(program)

      remindsRef.doc(payload.userId).set({ reminds: storeReminds })
        .then(() => {
        })
        .catch(err => {
          console.error('Error setting document: ', err)
        })
    },
    remove ({ commit, state }, payload) {
      const storeReminds = _.cloneDeep(state.data)
      const index = storeReminds.findIndex(storeRemind => storeRemind.id === payload.programId)
      if (index !== -1) {
        storeReminds.splice(index, 1)
      }

      remindsRef.doc(payload.userId).set({ reminds: storeReminds })
        .then(() => {
        })
        .catch(err => {
          console.error('Error removing document: ', err)
        })
    },
    clear ({ commit }) {
      commit('set', [])
    }
  }
}
