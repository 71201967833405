export default {
  mounted () {
    this.$eventHub.$on('key-press', this.onKeyPress)
    this.$eventHub.$on('key-up', this.onKeyUp)
  },
  destroyed () {
    this.$eventHub.$off('key-press')
    this.$eventHub.$off('key-up')
  },
  methods: {
    /* ================================================================================
      キー入力
    ================================================================================ */
    onKeyPress (key) {
      switch (key) {
        case 'shift + Enter':
          event.preventDefault()
          if (document.activeElement.className === 'tweet-text-input') {
            this.post()
          }
          break
      }
    },
    onKeyUp (key) {
    }
  }
}
