<template>
  <ons-list-item
    ref="listItem"
    class="list-item"
    :class="{'edit': editMode}"
    tappable
    modifier="longdivider"
    @tap="onClick"
  >
    <div class="center list-item-inner">
      {{label}}
      <div class="list-item-button-block">
        <a
          class="list-item-button"
          v-for="func in additionalFunctions" :key="func.eventName"
          @tap="onTapFunctionButton($event, func.eventName)"
          :style="{backgroundColor: func.color || '#ff0000'}"
        >
          <div class="list-item-button-inner">{{func.label}}</div>
        </a>
      </div><!-- /.list-item-button-block -->
    </div><!-- /.center -->
  </ons-list-item>
</template>

<script>
export default {
  name: 'OnsListItemWithCommands_swipe',
  data () {
    return {
      editMode: false
    }
  },
  props: ['id', 'label', 'additionalFunctions'],
  computed: {
  },
  mounted () {
    var divGD = this.$ons.GestureDetector(this.$refs.listItem)
    divGD.on('dragleft', event => {
      this.swiping = true
      this.editMode = true
    })
    divGD.on('dragright', event => {
      this.swiping = true
      this.editMode = false
    })
    divGD.on('dragend', event => {
      this.swiping = false
    })
  },
  destroyed () {
  },
  watch: {
  },
  methods: {
    onClick () {
      if (!this.swiping) {
        this.$emit('click', { id: this.id })
      }
    },
    onTapFunctionButton (event, eventName) {
      event.stopPropagation()
      this.$emit(eventName, { id: this.id })
      this.editMode = false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.list-item {
  position: relative;
  max-width: $max-width;
  margin: 0 auto;
  cursor: pointer;
  overflow: hidden;
}
.list-item:hover {
  background-color: $base-light-gray;
}

.list-item-inner {
  display: block;
  width: 100%;
  padding-right: 16px;
}

.list-item > *:not(.list-item-button-block),
.list-item > *:not(.list-item-button-block *) {
  width: 100%;
  overflow: hidden;
}

.list-item .list-item-button-block {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding-bottom: 1px;
  transform: translate(100%, 0);
  transition-duration: 0.3s;
  transition-property: transform;
}

.list-item.edit .list-item-button-block {
  transform: translate(0, 0);
}

.list-item a.list-item-button {
  display: table;
  margin-left: 1px;
  padding: 0 16px;
  height: 100%;
  color: white;
  white-space: nowrap;
}

.list-item a.list-item-button:hover {
  filter: brightness(1.3)
}

.list-item .list-item-button-inner {
  display: table-cell;
  vertical-align: middle;
}
</style>
