import _ from 'lodash'
import NameIdentification from '@/plugins/NameIdentification'

export default {
  setTopicsToProgram (program, publicTopics) {
    const convertedProgram = _.cloneDeep(program)
    convertedProgram.topics = []

    if (!publicTopics || !(publicTopics instanceof Array)) return program

    publicTopics.forEach(topic => {
      let match
      let exclude
      const programTitle = convertedProgram.title ? NameIdentification.convert(convertedProgram.title) : null

      if (topic.matchWords && topic.matchWords.length > 0 && topic.matchWords.some(word => word.surface)) {
        match = topic.matchWords.every(word => {
          const surface = NameIdentification.convert(word.surface)
          switch (word.matchCondition) {
            case 0:
              return programTitle.startsWith(surface)
            case 1:
              return programTitle.endsWith(surface)
            case 2:
              return programTitle.includes(surface)
            case 3:
              return programTitle === surface
          }
        })
      } else {
        const topicTitle = NameIdentification.convert(topic.manualTitle || topic.autoTitle)
        match = programTitle.includes(topicTitle)
      }

      if (topic.notMatchWords && topic.notMatchWords.length > 0) {
        exclude = topic.notMatchWords.some(word => {
          if (!word.surface) return false
          const surface = NameIdentification.convert(word.surface)
          switch (word.matchCondition) {
            case 0:
              return programTitle.startsWith(surface)
            case 1:
              return programTitle.endsWith(surface)
            case 2:
              return programTitle.includes(surface)
            case 3:
              return programTitle === surface
          }
        })
      }

      if (match && !exclude) {
        convertedProgram.topics.push(topic)
      }
    })

    return convertedProgram
  }
}
