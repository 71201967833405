export default function shortNumberJa (val) {
  if (typeof val === 'number') {
    if (val >= 1000000000000) {
      return (Math.round(val / 1000000000000 * 10) / 10).toFixed(1) + ' 兆'
    } else if (val >= 100000000) {
      return (Math.round(val / 100000000 * 10) / 10).toFixed(1) + ' 億'
    } else if (val >= 10000) {
      return (Math.round(val / 10000 * 10) / 10).toFixed(1) + ' 万'
    } else {
      return Math.round(val).toLocaleString()
    }
  } else {
    return val
  }
}
