import Topic from '@/classes/Topic'
import Uuid from '@/plugins/Uuid'

export default {
  methods: {
    createTopic (searchWords) {
      const topic = new Topic().getData()

      topic.id = Uuid.generate()
      topic.searchWords = searchWords.filter(word => word)

      // ハッシュタグじゃないワードをハッシュタグ化して検索ワードに追加
      topic.additionalSearchWords = this.getAdditionalSearchWords(topic.searchWords)

      // 自動生成タイトルを設定
      topic.autoTitle = this.getAutoTitle(topic.searchWords)

      // 自動生成ハッシュタグを設定
      topic.hashtags = this.getAutoHashtags(topic.searchWords, null)

      return topic
    },

    getAutoTitle (searchWords) {
      return searchWords[0] || '検索ワードなし'
    },

    getAutoHashtags (searchWords, existedHashtags) {
      // 自動生成ハッシュタグを設定
      let flattenWords = []
      searchWords.forEach(word => {
        flattenWords = flattenWords.concat(word.split(/\s/))
      })
      flattenWords = flattenWords.filter(word => word)

      const hashtagsFromSearchWords = this._.union(
        flattenWords.filter(word => word.charAt(0) === '#' && word !== '#'),
        flattenWords.filter(word => word !== '' && word.charAt(0) !== '#').map(word => '#' + word)
      )
      const hashtags = hashtagsFromSearchWords.map(hashtagFromSearchWords => {
        const existedHashtag = (existedHashtags || []).find(hashtag => hashtag.surface === hashtagFromSearchWords)
        if (existedHashtag) {
          return existedHashtag
        } else {
          return {
            surface: hashtagFromSearchWords,
            enable: true
          }
        }
      })

      return hashtags
    },

    getAdditionalSearchWords (searchWords) {
      // ハッシュタグじゃないワードをハッシュタグ化して検索ワードに追加
      const additionalSearchWords = searchWords.map(word => {
        const splittedWords = word.split(/\s/)
        return splittedWords.filter(splittedWord => splittedWord !== '' && splittedWord.charAt(0) !== '#').map(word => '#' + word).join(' ')
      }).filter(word => word)
      return additionalSearchWords
    }
  }
}
