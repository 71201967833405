export default {
  async asyncMap (array, operation) {
    return Promise.all(array.map(async item => {
      const newItem = await operation(item)
      return newItem
    }))
  },

  async asyncFilter (array, predicate) {
    const evaluateds = await this.asyncMap(array, async item => {
      const shouldExist = await predicate(item)
      return {
        item,
        shouldExist
      }
    })
    return evaluateds
      .filter(evaluated => evaluated.shouldExist)
      .map(evaluated => evaluated.item)
  }
}
