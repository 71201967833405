import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import { getMessaging } from 'firebase/messaging'

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
}

const firebaseApp = firebase.initializeApp(config)
const firestore = firebaseApp.firestore()
// firestore.settings({ timestampsInSnapshots: true })
const messaging = getMessaging(firebaseApp)
const vapidKey = {
  vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY
}
export { firestore, messaging, vapidKey }
