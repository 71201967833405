import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import filters from './filters'
import EventHub from './plugins/EventHub'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import Dayjs from 'vue-dayjs'
import 'onsenui/css/onsenui.css'
import '@/assets/styles/onsen-css-components.css'
import VueOnsen from 'vue-onsenui' // This already imports 'onsenui'
import './registerServiceWorker'
import VueGtag from 'vue-gtag'

Vue.use(filters)
Vue.config.productionTip = false
Vue.use(EventHub)
Vue.use(VueLodash, { lodash: lodash })
Vue.use(Dayjs, { lang: 'ja' })
Vue.use(VueOnsen)
Vue.use(VueGtag, {
  config: {
    id: 'G-EZVZTBZHFX'
  }
}, router)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
