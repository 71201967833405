<template>
  <div class="word-input-set">
    <ul>
      <li class="word-item" v-for="(word, index) in words" :key="index">
        <form @submit="goTopic($event)">
          <div class="input-block">
            <input v-if="matchConditionEnable" ref="wordInput" class="word-input" :placeholder="placeholder" v-model="words[index].surface" @input="updateWords()"/>
            <input v-else ref="wordInput" class="word-input" :placeholder="placeholder" v-model="words[index]" @input="updateWords()"/>
            <a @click="deleteWord(index)" class="delete-word-botton"><ons-icon class="ion" icon="ion-ios-close-circle" fixed-width="false"></ons-icon></a>
          </div>
          <v-ons-select class="match-condition-select" v-model="words[index].matchCondition" v-if="matchConditionEnable">
            <option v-for="matchCondition in matchConditions" :key="matchCondition.id" :value="matchCondition.id">
              {{ matchCondition.name }}
            </option>
          </v-ons-select>
        </form>
      </li>
      <li class="button-block">
        <div class="add-word-button-block">
          <a class="add-word-button" @click="addWord">
            <ons-icon class="ion" icon="ion-ios-add" fixed-width="false"></ons-icon>
            <span v-if="logicalOperator === 'AND'">かつ</span>
            <span v-else>もしくは</span>
          </a>
        </div><!-- /.add-word-button-block -->
      </li>
    </ul>
  </div><!-- /.word-input-set -->
</template>

<script>
export default {
  name: 'WordInputSet',
  data () {
    return {
      timelineEnabled: true,
      matchConditions: [
        { id: 0, name: '前方一致' },
        { id: 1, name: '後方一致' },
        { id: 2, name: '部分一致' },
        { id: 3, name: '完全一致' }
      ]
    }
  },
  props: ['words', 'placeholder', 'submitEnable', 'initialFocus', 'matchConditionEnable', 'logicalOperator'],
  mounted () {
    this.start()
  },
  watch: {
    words (newVal, oldVal) {
      this.start()
    }
  },
  methods: {
    start () {
      // ワードがゼロだったら追加
      if (this.words && this.words.length === 0) {
        this.addWord()
      }
    },

    /* ============================================================
      ワード追加/削除
    ============================================================ */
    addWord () {
      if (this.matchConditionEnable) {
        const words = this._.cloneDeep(this.words)
        words.push({ surface: '', matchCondition: 2 })
        this.updateWords(words)
      } else {
        const words = this._.cloneDeep(this.words)
        words.push('')
        this.updateWords(words)
      }

      if (this.initialFocus) {
        setTimeout(() => {
          const input = this.$refs.wordInput ? this.$refs.wordInput[this.words.length - 1] : null
          if (input) {
            input.focus()
          }
        }, 100)
      }
    },

    deleteWord (index) {
      const words = this._.cloneDeep(this.words)
      words.splice(index, 1)
      this.updateWords(words)
    },

    updateWords (words) {
      if (words) {
        this.$emit('update', { words: this._.cloneDeep(words) })
      } else {
        this.$emit('update', { words: this._.cloneDeep(this.words) })
      }
    },

    /* ============================================================
      タイムラインへ移動
    ============================================================ */
    goTopic (event) {
      event.preventDefault()
      if (this.submitEnable) {
        this.$emit('go-timeline')
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.word-input-set {
  padding: 16px 0;

  .word-item {
    position: relative;
    margin: 0 16px 16px;
  }

  .word-item form {
    display: flex;
    justify-content: stretch;
  }

  .word-item .input-block {
    position: relative;
    flex-grow: 1;
  }

  .word-input {
    display: block;
    width: 100%;
    height: 32px;
    padding: 6px 32px 6px 12px;
    background-color: $base-light-gray;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    color: $text-black;
  }

  .delete-word-botton {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    text-align: center;
    line-height: 32px;
    color: $base-medium-light-gray;
  }

  .delete-word-botton:hover {
    color: $text-black;
  }

  .button-block {
    .add-word-button-block {
      padding: 0 16px;
    }

    a.add-word-button {
      display: block;
      line-height: 32px;
      font-size: 0.875rem;
      color: $base-gray;
    }
    a.add-word-button:hover{
      color: $link-color-light;
    }
  }

  ons-select.match-condition-select {
    flex-grow: 0;
    height: 32px;
    margin-left: 8px;
    padding: 0 12px;
    background-color: $base-light-gray;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    color: $text-black;
  }
}
</style>
