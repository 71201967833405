import axios from 'axios'
import NameIdentification from '@/plugins/NameIdentification'
import Config from '@/plugins/Config'
import Async from '@/plugins/Async'

export default {
  data () {
    return {
      currentEpgUrls: [],
      loadingTimer: null,
      programsOfCategories: new Array(2),
      currentEpgLoadStatus: {}
    }
  },
  computed: {
    firestorePublicTopics () {
      return this.$store.getters['firestorePublicTopics/data']
    },
    firestoreArea () {
      return this.$store.getters['firestoreUserSettings/area']
    }
  },
  mounted () {
    if (this.firestoreArea) {
      this.setCurrentEpgUrls()
      this.startLoading()
    }
  },
  destroyed () {
    this.stopLoading()
  },
  watch: {
    async firestorePublicTopics (newVal, oldVal) {
      // トピックを割り当てる
      this.programsOfCategories = await Async.asyncMap(this.programsOfCategories, async programs => {
        if (!programs) return
        const convertedPrograms = await this.setTopics(programs)
        return convertedPrograms
      })
    },
    firestoreArea (newVal, oldVal) {
      if (this.firestoreArea && newVal !== oldVal) {
        this.programsOfCategories = new Array(2)
        this.setCurrentEpgUrls()
        this.reloadCurrentEpgData()
        this.startLoading()
      }
    }
  },
  methods: {
    /* ================================================================================
      EPG
    ================================================================================ */
    /* ------------------------------------------------------------
      URLリストを作成
    ------------------------------------------------------------ */
    setCurrentEpgUrls () {
      let currentEpgUrls = []

      const tvUrls = Config.areas.find(area => area.id === this.firestoreArea).areas.map(area => {
        return {
          url: Config.currentEpgUrlTemplate.replace(/{area}/, area),
          category: 0,
          area: this.firestoreArea
        }
      })
      currentEpgUrls = currentEpgUrls.concat(tvUrls)

      const bsUrls = Config.bsCurrentEpgUrls.map(url => {
        return {
          url: url,
          category: 1,
          area: this.firestoreArea
        }
      })
      currentEpgUrls = currentEpgUrls.concat(bsUrls)

      this.currentEpgUrls = currentEpgUrls
    },

    /* ------------------------------------------------------------
      EPGの自動読み込みをスタート
    ------------------------------------------------------------ */
    startLoading () {
      this.stopLoading()

      console.log('Start Loading')
      this.getCurrentEpgData()

      const nextMinute = this.$dayjs().startOf('minute').add(1, 'minutes')
      const delay = (nextMinute.unix() - this.$dayjs().unix()) * 1000

      this.loadingTimer = setTimeout(() => {
        this.startLoading()
      }, delay)
    },

    /* ------------------------------------------------------------
      EPGの自動読み込みを停止
    ------------------------------------------------------------ */
    stopLoading () {
      console.log('Stop Loading')
      clearTimeout(this.loadingTimer)
    },

    /* ------------------------------------------------------------
      放送中の番組を取得
    ------------------------------------------------------------ */
    async getCurrentEpgData () {
      this.currentEpgUrls.forEach(async url => {
        try {
          // this.currentEpgLoadStatus変更
          const currentEpgLoadStatus = this._.cloneDeep(this.currentEpgLoadStatus)
          currentEpgLoadStatus[url.url] = {
            loading: true,
            lastLoadTime: currentEpgLoadStatus[url.url] && currentEpgLoadStatus[url.url].lastLoadTime ? currentEpgLoadStatus[url.url].lastLoadTime : null
          }
          this.currentEpgLoadStatus = currentEpgLoadStatus

          // 読み込み処理
          const epgRss = await this.loadEpg(url.url)

          // areaが変更されていたら、読み込んだデータを破棄
          if (url.area === this.firestoreArea) {
            // 結果XMLをパース
            const data = this.parseXml(epgRss)

            if (data['rdf:RDF'] && data['rdf:RDF'].item) {
              let programs = data['rdf:RDF'].item

              // 番組データを加工
              programs = this.convertPrograms(programs)

              // channelがない番組を削除
              programs = programs.filter(program => program.channel)

              // トピックを割り当てる
              programs = await this.setTopics(programs)

              // 放送局カテゴリー別に整形
              this.programsOfCategories = this.getProgramsOfCategories(programs, url.category)
            }
          }
        } catch (err) {
          console.log(err)
        } finally {
          const currentEpgLoadStatus = this._.cloneDeep(this.currentEpgLoadStatus)
          currentEpgLoadStatus[url.url] = {
            loading: false,
            lastLoadTime: this.$dayjs().unix()
          }
          this.currentEpgLoadStatus = currentEpgLoadStatus
        }
      })
    },

    /* ------------------------------------------------------------
      EPGを読み込む
    ------------------------------------------------------------ */
    async loadEpg (url) {
      try {
        const res = await axios.get(process.env.VUE_APP_SERVER_URL + '/text/?url=' + encodeURIComponent(url), { responseType: 'arraybuffer' })
        const buffer = Buffer.from(res.data, 'binary')
        const text = buffer.toString()
        return text
      } catch (err) {
        this.$eventHub.$emit('show-error-toast')
        return err
      }
    },

    /* ------------------------------------------------------------
      XMLをパース
    ------------------------------------------------------------ */
    parseXml (epgRss) {
      let data = {}
      const parseString = require('xml2js').parseString
      parseString(epgRss, (err, result) => {
        if (err) {
          console.log(err)
        } else {
          data = result
        }
      })
      return data
    },

    /* ------------------------------------------------------------
      番組データを加工
    ------------------------------------------------------------ */
    convertPrograms (programs) {
      programs = programs.map(program => this.convertProgram(program))
      return programs
    },

    convertProgram (program) {
      const convertedProgram = this._.cloneDeep(program)

      /* ------------------------------------------------------------
        タイトルを変換
      ------------------------------------------------------------ */
      let title = convertedProgram.title[0]

      // 半角化
      title = NameIdentification.hankaku(title)

      convertedProgram.title = title

      /* ------------------------------------------------------------
        放送局名を追加
      ------------------------------------------------------------ */
      const stationMatch = convertedProgram.description[0].match(/\[(.+?)[(Ch.|\]]/)
      convertedProgram.station = stationMatch && stationMatch.length > 1 ? NameIdentification.hankaku(stationMatch[1]) : null

      /* ------------------------------------------------------------
        チャンネル番号を追加
      ------------------------------------------------------------ */
      if (program.category === 0) {
        const channelMatch = convertedProgram.description[0].match(/\(Ch.(\d)\)/)
        convertedProgram.channel = channelMatch && channelMatch.length > 1 ? channelMatch[1] : null
      } else {
        const station = Config.stations.find(station => station.name === convertedProgram.station)
        if (station) {
          convertedProgram.channel = station.channel
        } else {
          convertedProgram.channel = null
        }
      }

      /* ------------------------------------------------------------
        放送開始時刻、終了時刻を追加
      ------------------------------------------------------------ */
      const startTimeMatch = convertedProgram.description[0].match(/\d+?\/\d+? \d+?:\d{2}/)
      const startTimeStr = startTimeMatch && startTimeMatch.length > 0 ? this.$dayjs().format('YYYY/') + startTimeMatch[0] : null
      if (startTimeStr) {
        const startTime = this.$dayjs(startTimeStr)

        const endTimeMatch = convertedProgram.description[0].match(/～(\d+?:\d{2})/)
        const endTimeStr = endTimeMatch && endTimeMatch.length > 1 ? this.$dayjs().format('YYYY/') + startTime.format('MM/DD') + ' ' + endTimeMatch[1] : null
        if (endTimeStr) {
          let endTime = this.$dayjs(endTimeStr)
          if (endTime.isBefore(convertedProgram.startTime)) {
            endTime = endTime.add(1, 'days')
          }
          convertedProgram.startTime = startTime
          convertedProgram.startTimeStr = convertedProgram.startTime.format('H:mm')
          convertedProgram.endTime = endTime
          convertedProgram.endTimeStr = convertedProgram.endTime.format('H:mm')
        } else {
          convertedProgram.endTime = null
          convertedProgram.endTimeStr = null
        }

        /* 年を跨いだときにおかしくなる問題がまだある */
      }

      /* ------------------------------------------------------------
        番組IDを追加
      ------------------------------------------------------------ */
      convertedProgram.id = convertedProgram.station + '-' + convertedProgram.startTime.format('YYYYMMDDHHmm')

      return convertedProgram
    },

    /* ------------------------------------------------------------
      番組にトピックを割り当てる
    ------------------------------------------------------------ */
    async setTopics (programs) {
      // workerに処理を委託
      const setTopicsToCurrentProgramsWorker = new Worker('../workers/SetTopicsToCurrentPrograms.worker.js', { type: 'module' })
      setTopicsToCurrentProgramsWorker.postMessage(JSON.stringify({ programs: programs, publicTopics: this.firestorePublicTopics }))

      // workerから返ってきた結果を処理
      const workerResultEvent = await new Promise(resolve => { setTopicsToCurrentProgramsWorker.onmessage = resolve })
      try {
        return JSON.parse(workerResultEvent.data)
      } catch (err) {
        console.log(err)
        return programs
      }
    },

    /* ------------------------------------------------------------
      放送局カテゴリー別に整形
    ------------------------------------------------------------ */
    getProgramsOfCategories (programs, category) {
      // 局カテゴリーを追加
      programs = programs.map(program => {
        program.category = category
        return program
      })

      // 新規に生成した番組データを、既存の番組データに結合
      const programsOfCategories = this._.cloneDeep(this.programsOfCategories)
      programsOfCategories[category] = programs.concat(programsOfCategories[category]).filter(program => program)
      programsOfCategories[category] = this._.orderBy(programsOfCategories[category], ['channel'], ['asc'])
      programsOfCategories[category] = this._.uniqBy(programsOfCategories[category], 'station')
      return programsOfCategories
    },

    /* ------------------------------------------------------------
      EPGをリロード
    ------------------------------------------------------------ */
    reloadCurrentEpgData () {
      this.currentEpgLoadStatus = {}
      this.getCurrentEpgData()
    }

  }
}
