import AddComma from './AddComma'
import ShortNumber from './ShortNumber'
import ShortNumberJp from './ShortNumberJp'
import Percentage from './Percentage'
import RelativeDate from './RelativeDate'

export default {
  install (Vue) {
    Vue.filter('addComma', AddComma)
    Vue.filter('shortNumber', ShortNumber)
    Vue.filter('shortNumberJp', ShortNumberJp)
    Vue.filter('percentage', Percentage)
    Vue.filter('relativeDate', RelativeDate)
  }
}
