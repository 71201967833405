import { firestore } from '@/firebase/firebase'
import dayjs from 'dayjs'
import _ from 'lodash'
import Config from '@/plugins/Config'

const searchHistoryRef = firestore.collection('searchHistory')

export default {
  namespaced: true,
  unsubscribeSearchHistory: null,
  state () {
    return {
      data: []
    }
  },
  mutations: {
    set (state, payload) {
      state.data = payload
    }
  },
  getters: {
    data (state) {
      return _.orderBy(state.data, ['updatedAt'], ['desc'])
    }
  },
  actions: {
    startListener ({ commit }, payload) {
      if (this.unsubscribeSearchHistory) {
        console.warn('listener is running. ', this.unsubscribeSearchHistory)
        this.unsubscribeSearchHistory()
        this.unsubscribeSearchHistory = null
      }

      // 3. Firestoreからデータを検索する
      this.unsubscribeSearchHistory = searchHistoryRef.doc(payload.userId).onSnapshot(doc => {
        // 6. データが更新されるたびに呼び出される
        if (doc.data()) {
          commit('set', doc.data().items)
        }
      },
      (error) => {
        console.error(error.name)
      })
    },
    // 8. リスナーの停止
    stopListener () {
      if (this.unsubscribeSearchHistory) {
        console.log('listener is stopping. ', this.unsubscribeSearchHistory)
        this.unsubscribeSearchHistory()
        this.unsubscribeSearchHistory = null
      }
    },
    set ({ commit, state }, payload) {
      const item = payload.topic || payload.program
      item.updatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss')

      let storeItems = _.cloneDeep(state.data)
      if (!storeItems) {
        storeItems = []
      }
      const index = storeItems.findIndex(storeItem => storeItem.id === item.id)
      if (index !== -1) {
        storeItems.splice(index, 1)
      }
      storeItems.unshift(item)

      storeItems = storeItems.slice(0, Config.searchHistoryLimit)

      searchHistoryRef.doc(payload.userId).set({ items: storeItems })
        .then(() => {
        })
        .catch(err => {
          console.error('Error setting document: ', err)
        })
    },
    setAll ({ commit, state }, payload) {
      searchHistoryRef.doc(payload.userId).set({ items: payload.items })
        .then(() => {
        })
        .catch(err => {
          console.error('Error setting document: ', err)
        })
    },
    remove ({ commit, state }, payload) {
      const storeItems = _.cloneDeep(state.data)
      const index = storeItems.findIndex(storeItem => storeItem.id === payload.id)
      if (index !== -1) {
        storeItems.splice(index, 1)
      }

      searchHistoryRef.doc(payload.userId).set({ items: storeItems })
        .then(() => {
        })
        .catch(err => {
          console.error('Error removing document: ', err)
        })
    },
    clear ({ commit }) {
      commit('set', [])
    }
  }
}
