<template id="main">
  <v-ons-page>
    <v-ons-toolbar>
      <div class="center">
        <div class="logo">miruru</div>
      </div><!-- /.center -->
      <div class="right">
        <!-- EPGリロードボタン -->
        <v-ons-toolbar-button v-if="activeIndex === 1">
          <ons-icon
            class="toolbar-button-icon"
            icon="ion-ios-refresh"
            size="20px"
            fixed-width="false"
            @click="$eventHub.$emit('reload-epg')"
          ></ons-icon>
        </v-ons-toolbar-button>
        <!-- /EPGリロードボタン -->
        <!-- 固定の機能ボタン -->
        <v-ons-toolbar-button>
          <ons-icon
            class="toolbar-button-icon"
            icon="ion-ios-more"
            size="20px"
            fixed-width="false"
            @click="openFunctionActionSheet"
          ></ons-icon>
        </v-ons-toolbar-button>
        <!-- /固定の機能ボタン -->
      </div><!-- /.right -->
    </v-ons-toolbar>

    <v-ons-tabbar
      class="main-tab"
      position="auto"
      :tabs="tabs"
      :visible="true"
      :index.sync="activeIndex"
    >
    </v-ons-tabbar>
  </v-ons-page>
</template>

<script>
import Remocon from '@/components/organisms/Remocon'
import EpgTables from '@/components/organisms/EpgTables'
import PublicTopics from '@/components/organisms/PublicTopics'
import History from '@/components/organisms/History'
import Search from '@/components/organisms/Search'
import AdminCheck from '@/mixins/AdminCheck'
import ErrorToast from '@/mixins/ErrorToast'

export default {
  name: 'HomePage',
  mixins: [AdminCheck, ErrorToast],
  data () {
    return {
      tabs: [
        {
          icon: 'ion-ios-clock',
          label: '放送中',
          page: Remocon,
          persistent: false,
          props: {
            // myProp: 'This is a page prop!'
          },
          key: 'remoconPage'
        },
        {
          icon: 'ion-ios-book',
          label: '番組表',
          page: EpgTables,
          persistent: false,
          props: {
            // myProp: 'This is a page prop!'
          },
          key: 'epgTablesPage'
        },
        {
          icon: 'ion-ios-walk',
          label: '番組をさがす',
          page: PublicTopics,
          persistent: false,
          props: {
            // myProp: 'This is a page prop!'
          },
          key: 'publicTopicsPage'
        },
        {
          icon: 'ion-ios-list',
          label: '履歴',
          page: History,
          persistent: false,
          props: {
            // myProp: 'This is a page prop!'
          },
          key: 'historyPage'
        },
        {
          icon: 'ion-ios-search',
          label: 'フリー検索',
          page: Search,
          persistent: false,
          props: {
            // myProp: 'This is a page prop!'
          },
          key: 'searchPage'
        }
      ],
      activeIndex: 0
    }
  },
  computed: {
    firestoreSettings () {
      return this.$store.getters['firestoreSettings/data']
    },

    olympicsTabEnabled () {
      return this.firestoreSettings.summerOlympics || this.firestoreSettings.winterOlympics
    }
  },
  mounted () {
    this.$eventHub.$on('push-page', event => {
      this.$emit('push-page', event)
    })
  },
  destroyed () {
    this.$eventHub.$off('push-page')
  },
  methods: {
    /* ============================================================
      Action Sheet
    ============================================================ */
    openFunctionActionSheet () {
      this.$ons.openActionSheet({
        title: '',
        cancelable: true,
        buttons: [
          '地域設定',
          'ログアウト',
          {
            label: 'キャンセル',
            icon: 'md-close'
          }
        ]
      }).then(index => {
        switch (index) {
          case 0:
            this.goAreaSetting()
            break
          case 1:
            this.signout()
            break
        }
      })
    },
    goAreaSetting () {
      this.$eventHub.$emit('push-page', { page: 'AreaSetting' })
    },
    signout () {
      localStorage.removeItem('user')
      this.$router.push('/signin')
    }
  }
}
</script>

<style scoped lang="scss">
.logo {
  display: inline-block;
  width: 120px;
  height: 100%;
  background-image: url('../../assets/images/logo-w.svg');
  background-size: contain;
  background-position: 0 50%;
  background-repeat: no-repeat;
  text-indent: -9999px;
}

.toolbar-button-icon-active {
  opacity: 0.5;
}

</style>
