<template>
  <v-ons-page id="publicTopics">
    <div class="page-body">
      <h1 class="page-title">
        <div class="page-title-inner">
          <ons-icon
            class="title-icon"
            icon="ion-ios-walk"
            fixed-width="false"
          ></ons-icon>
          <div class="label">番組をさがす</div>
        </div>
      </h1>
      <div class="public-topics-block">
        <div class="search-block">
          <div class="word-input-container">
            <input ref="wordInput" class="word-input" placeholder="番組を検索" v-model="searchWord"/>
            <a @click="searchWord = null" class="delete-word-botton"><ons-icon class="ion" icon="ion-ios-close-circle" fixed-width="false"></ons-icon></a>
          </div><!-- /.word-input-container -->
        </div><!-- /.search-block -->

        <section class="summer-olympics-block" v-if="this.firestoreSettings.summerOlympics && !searchWord && topics && topics.length > 0">
          <h2>夏期五輪</h2>
          <PublicTopicsList :topics="topics" :topics-of-categories="topicsOfCategories" :categories="categoriesOfSummerOlympics"/>
        </section><!-- /.summer-olympics-block -->

        <section class="summer-paralympics-block" v-if="this.firestoreSettings.summerParalympics && !searchWord && topics && topics.length > 0">
          <h2>夏期パラリンピック</h2>
          <PublicTopicsList :topics="topics" :topics-of-categories="topicsOfCategories" :categories="categoriesOfSummerParalympics"/>
        </section><!-- /.summer-paralympics-block -->

        <section class="winter-olympics-block" v-if="this.firestoreSettings.winterOlympics && !searchWord && topics && topics.length > 0">
          <h2>冬期五輪</h2>
          <PublicTopicsList :topics="topics" :topics-of-categories="topicsOfCategories" :categories="categoriesOfWinterOlympics"/>
        </section><!-- /.winter-olympics-block -->

        <section class="winter-paralympics-block" v-if="this.firestoreSettings.winterParalympics && !searchWord && topics && topics.length > 0">
          <h2>冬期パラリンピック</h2>
          <PublicTopicsList :topics="topics" :topics-of-categories="topicsOfCategories" :categories="categoriesOfWinterParalympics"/>
        </section><!-- /.winter-paralympics-block -->

        <section class="default-block" v-if="!searchWord && topics && topics.length > 0">
          <h2 v-if="firestoreSettings.summerOlympics || firestoreSettings.summerParalympics || firestoreSettings.winterOlympics || firestoreSettings.winterParalympics">通常の番組</h2>
          <PublicTopicsList :topics="topics" :topics-of-categories="topicsOfCategories" :categories="defaultCategories"/>
        </section><!-- /.default-block -->

        <section class="search-results-block" v-else-if="searchWord && searchResults && searchResults.length > 0">
          <PublicTopicsSearchResults :topics="searchResults"/>
        </section><!-- /.search-results-block -->

        <div class="no-items" v-else>
          <div class="no-items-inner">番組がありません</div>
        </div><!-- /.no-items -->
      </div><!-- /.topics-block -->
    </div><!-- /.page-body -->
  </v-ons-page>
</template>

<script>
import PublicTopicsList from '@/components/organisms/PublicTopicsList'
import PublicTopicsSearchResults from '@/components/organisms/PublicTopicsSearchResults'
import Config from '@/plugins/Config'
import NameIdentification from '@/plugins/NameIdentification'

export default {
  name: 'PublicTopics',
  components: {
    PublicTopicsList,
    PublicTopicsSearchResults
  },
  data () {
    return {
      additionalFunctions: [],
      categoriesOfSummerOlympics: Config.categories.filter(category => category.id >= 1000 && category.id < 1100).map(category => {
        const newCategory = this._.cloneDeep(category)
        newCategory.name = category.name.split('_')[1]
        return newCategory
      }),
      categoriesOfSummerParalympics: Config.categories.filter(category => category.id >= 1100 && category.id < 2000).map(category => {
        const newCategory = this._.cloneDeep(category)
        newCategory.name = category.name.split('_')[1]
        return newCategory
      }),
      categoriesOfWinterOlympics: Config.categories.filter(category => category.id >= 2000 && category.id < 2100).map(category => {
        const newCategory = this._.cloneDeep(category)
        newCategory.name = category.name.split('_')[1]
        return newCategory
      }),
      categoriesOfWinterParalympics: Config.categories.filter(category => category.id >= 2100 && category.id < 3000).map(category => {
        const newCategory = this._.cloneDeep(category)
        newCategory.name = category.name.split('_')[1]
        return newCategory
      }),
      searchWord: null
    }
  },
  computed: {
    firestoreSettings () {
      return this.$store.getters['firestoreSettings/data']
    },
    topics () {
      const topics = this.$store.getters['firestorePublicTopics/data']
      return this._.orderBy(this._.clone(topics), ['categoryId'], ['asc'])
    },
    defaultCategories () {
      return Config.categories.filter(category => (category.enabled || this.isAdmin) && category.id < 1000)
    },
    topicsOfCategories () {
      return this._.groupBy(this.topics, 'categoryId')
    },
    searchResults () {
      return this.topics.filter(topic => {
        const title = NameIdentification.convert(topic.manualTitle || topic.autoTitle)
        const searchWord = NameIdentification.convert(this.searchWord)
        return title.includes(searchWord) || topic.searchWords.some(word => word.includes(searchWord))
      }, this)
    }
  },
  watch: {
    isAdmin (newVal, oldVal) {
      if (this.isAdmin) {
        switch (this.$vnode.key) {
          case 'PublicTopics':
            this.additionalFunctions = [
              {
                label: '削除',
                color: '#E53734',
                eventName: 'remove'
              }
            ].concat(this.additionalFunctions)
            break
        }
      }
    }
  },
  methods: {
    /* ============================================================
      タイムラインへ移動
    ============================================================ */
    goTopicFromPublicTopic (topicId) {
      const topic = this.topics.find(topic => topic.publicId === topicId)

      if (topic) {
        this.goTopic(topic)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.page-body {
  max-width: $max-width;
  margin: 0 auto;

  section:not(:last-child) {
    margin-bottom: 32px;
  }

  h2 {
    position: sticky;
    top: 0;
    margin: 4px 0;
    padding: 4px 16px;
    background-color: white;
    z-index: 1;
    font-size: 14px;
    color: $theme-color;
  }

  ons-list {
    padding-bottom: 1px; // 一番下のカテゴリーを展開したときに、一番下の線が消えるのを回避する措置
  }

  ons-list.expandable-content {
    padding: 0;
  }

  .expanded {
    font-weight: bold;
  }

  .tappable {
    cursor: pointer;
  }

  .no-items {
    position: relative;
    padding: 64px 0;
    text-align: center;
  }
}

@media screen and (min-width:600px) {
  .topics-block {
    margin-top: -32px;
  }
}

.search-block {
  max-width: $max-width;
  margin: 0 auto;
  padding: 16px;

  .word-input-container {
    position: relative;
  }

  .word-input {
    width: 100%;
    height: 32px;
    padding: 6px 32px 6px 12px;
    background-color: $base-light-gray;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    color: $text-black;
  }

  .delete-word-botton {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    text-align: center;
    line-height: 32px;
    color: $base-medium-light-gray;
  }

  .delete-word-botton:hover {
    color: $text-black;
  }
}
</style>
