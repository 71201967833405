<template>
  <v-ons-page id="history">
    <h1 class="page-title">
      <div class="page-title-inner">
        <ons-icon
          class="title-icon"
          icon="ion-ios-list"
          fixed-width="false"
        ></ons-icon>
        <div class="label">履歴</div>
      </div>
    </h1>
    <div class="history-block">
      <ons-list v-if="searchHistory && searchHistory.length > 0">
        <ons-list-item-with-commands
          class="list-item tappable"
          v-for="item in searchHistory" :key="item.id"
          :id="item.id"
          :label="item.title || item.manualTitle || item.autoTitle"
          :additional-functions="additionalFunctions"
          tappable
          modifier="longdivider"
          @click="goTimelineFromHistory($event.id)"
          @remove="removeHistoryItem($event.id)"
        />
      </ons-list>

      <div class="no-items" v-else>
        <div class="no-items-inner">履歴がありません</div>
      </div><!-- /.no-items -->
    </div><!-- /.history-block -->
  </v-ons-page>
</template>

<script>
import HomeCommon from '@/mixins/HomeCommon'
import OnsListItemWithCommands from '@/components/molecules/OnsListItemWithCommands_swipe'
import Publish from '@/mixins/Publish'

export default {
  name: 'History',
  components: {
    OnsListItemWithCommands
  },
  mixins: [HomeCommon, Publish],
  data () {
    return {
      searchHistory: [],
      additionalFunctions: [
        {
          label: '削除',
          color: '#E53734',
          eventName: 'remove'
        }
      ]
    }
  },
  computed: {
    storeUser () {
      return this.$store.getters['user/data']
    },
    firestoreHistory () {
      return this.$store.getters['firestoreHistory/data']
    },
    firestorePublicTopics () {
      return this.$store.getters['firestorePublicTopics/data']
    }
  },
  watch: {
    firestoreHistory (newVal, oldVal) {
      this.searchHistory = this._.clone(this.firestoreHistory).slice(0, 100)
      this.clearPublicIdFromDeletedPublicTopicHistory()
    },
    firestorePublicTopics (newVal, oldVal) {
      this.clearPublicIdFromDeletedPublicTopicHistory()
    }
  },
  methods: {
    /* ============================================================
      TimelinePageへ移動
    ============================================================ */
    goTimelineFromHistory (id) {
      const item = this.searchHistory.find(item => item.id === id)
      if (item) {
        if (item.topics) {
          this.goProgram(item)
        } else {
          this.goTopic(item)
        }
      }
    },

    removeHistoryItem (id) {
      this.$store.dispatch('firestoreHistory/remove', { userId: this.storeUser.id, id: id })
    },

    clearPublicIdFromDeletedPublicTopicHistory () {
      let changed = false
      const items = this._.cloneDeep(this.firestoreHistory)
      const newItems = items.map(item => {
        if (item.publicId && !this.firestorePublicTopics.find(publicTopic => publicTopic.publicId === item.publicId)) {
          item.publicId = ''
          changed = true
        }
        return item
      })
      if (changed) {
        this.$store.dispatch('firestoreHistory/setAll', { userId: this.storeUser.id, items: newItems })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.history-block {
  .tappable {
    cursor: pointer;
  }

  .no-items {
    position: relative;
    padding: 64px 0;
    text-align: center;
  }
}
</style>
