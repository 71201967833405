<template>
  <div class="twitter-article">
    <div class="avatar">
      <a :href="'https://twitter.com/' + tweet.author.username" target="_blank" :style="{backgroundImage: 'url(' + tweet.author.profile_image_url + ')'}"></a>
    </div>
    <div class="user-info">
      <a :href="'https://twitter.com/' + tweet.author.username" target="_blank">
        <div class="name">{{tweet.author.name}}</div>
        <div class="username">{{tweet.author.username}}</div>
      </a>
    </div>
    <div class="text" v-html="convertedTweet.full_text"></div>
    <ul class="image-list" v-if="tweet.media && tweet.media.length > 0" :class="'image' + tweet.media.length">
      <li v-for="(image, index) in tweet.media" :key="index">
        <a :href="'https://twitter.com/' + tweet.author.username + '/status/' + tweet.id" target="_blank">
          <img :src="image.url || image.preview_image_url"/>
        </a>
      </li>
    </ul><!-- /.image-list -->

    <!--
    <ul class="quoted-tweet-list" v-if="tweet.referenced_tweets && tweet.referenced_tweets.length > 0">
      <li class="quoted-tweet-item" v-for="referencedTweet in tweet.referenced_tweets" :key="referencedTweet.id">
        <div class="quoted-tweet-container" v-if="referencedTweet.type === 'quoted'">
          <twitter-article-detail :tweet="referencedTweet.tweet" :now="now" :quote="false"/>
        </div>
      </li>
    </ul>--><!-- /.quoted-tweet-list -->

    <div class="timestamp">
      <a :href="'https://twitter.com/' + tweet.author.username + '/status/' + tweet.id" target="_blank">{{tweet.created_at | relativeDate('YYYY/MM/DD HH:mm:ss', now)}}</a>
    </div>

    <div class="twitter-icon">
      <ons-icon icon="fa-twitter" fixed-width="false"></ons-icon>
    </div><!-- /.twitter-icon -->
  </div><!-- /.twitter-article -->
</template>

<script>
import TweetConvert from '@/plugins/TweetConvert'
import _ from 'lodash'
export default {
  name: 'TwitterArticleDetail',
  data () {
    return {
      convertedTweet: {}
    }
  },
  props: ['tweet', 'now', 'quote'],
  created () {
    this.convertTweet()
  },
  watch: {
    tweet (newVal, oldVal) {
      this.convertTweet()
    },

    now (newVal, oldVal) {
    }
  },
  methods: {
    convertTweet () {
      this.convertedTweet = _.cloneDeep(this.tweet)
      this.convertedTweet.full_text = TweetConvert.convertToLink(this.tweet.text)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.twitter-article {
  display: grid;
  grid-template-columns: 48px 5fr;
  grid-template-rows: 48px auto;
  grid-gap: 12px;
  position: relative;
  margin: 0 auto;
  max-width: $max-width;
}

.avatar {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.avatar a {
  display: block;
  width: 48px;
  height: 48px;
  background-size: cover;
  background-color: $base-dark-gray;
  border-radius: 50%;
}

.user-info {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  overflow: hidden;
  color: $text-black;
  text-align: left;

  .name {
    display: block;
    padding-right: 32px;
    font-weight: bold;
    color: $text-black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .username {
    display: block;
    padding-right: 32px;
    font-size: 87.5%;
    color: $text-light;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .screen-name:before {
    content: '@';
  }
}

.text {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  font-size: 1.2rem;
  color: $text-black;
  text-align: left;
}

.timestamp a {
  white-space: nowrap;
  font-size: 87.5%;
  color: $text-light;
}

.image-list {
  grid-row: 3 / 4;
  grid-column: 1 / 3;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  width: 100%;
  overflow: hidden;

  li {
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: none;
    background-color: $base-dark-gray;
    border-radius: 4px;
    overflow: hidden;
  }

  li:not(:last-child) {
    margin-bottom: 8px;
  }

  li a {
    display: block;
  }
}

.image-list img {
  width: 100%;
}

.quoted-tweet-list {
  grid-column: 1 / 3;

  .quoted-tweet-container {
    padding: 16px;
    background-color: $base-ultra-light-gray;
    border: solid 1px $base-light-gray;
    border-radius: 4px;
  }
  .quoted-tweet-container:not(:last-child) {
    margin-bottom: 8px;
  }
}

.twitter-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  color: #1da1f2;
}
</style>
