const _config = {
  searchHistoryLimit: 100,
  timelineUpdateInterval: 10000,
  trendsUpdateInterval: 5 * 60 * 1000,
  tweetAmountToAddTimeline: 20,
  tweetMaxResults: 30,
  tweetStockAmount: 50,
  checkPositionTopInterval: 1000,
  defaultArea: 23,
  areas: [
    { id: 10, name: '北海道（札幌）', areas: [10] },
    { id: 11, name: '北海道（函館）', areas: [11] },
    { id: 12, name: '北海道（旭川）', areas: [12] },
    { id: 13, name: '北海道（帯広）', areas: [13] },
    { id: 14, name: '北海道（釧路）', areas: [14] },
    { id: 15, name: '北海道（北見）', areas: [15] },
    { id: 16, name: '北海道（室蘭）', areas: [16] },
    { id: 22, name: '青森', areas: [22] },
    { id: 20, name: '岩手', areas: [20] },
    { id: 17, name: '宮城', areas: [17] },
    { id: 18, name: '秋田', areas: [18] },
    { id: 19, name: '山形', areas: [19] },
    { id: 21, name: '福島', areas: [21] },
    { id: 26, name: '茨城', areas: [26] },
    { id: 28, name: '栃木', areas: [28] },
    { id: 25, name: '群馬', areas: [25] },
    { id: 29, name: '埼玉', areas: [29, 23] },
    { id: 27, name: '千葉', areas: [27, 23] },
    { id: 23, name: '東京', areas: [23, 24] },
    { id: 24, name: '神奈川', areas: [24, 23] },
    { id: 31, name: '新潟', areas: [31] },
    { id: 32, name: '山梨', areas: [32] },
    { id: 30, name: '長野', areas: [30] },
    { id: 37, name: '富山', areas: [37] },
    { id: 34, name: '石川', areas: [34] },
    { id: 36, name: '福井', areas: [36] },
    { id: 39, name: '岐阜', areas: [39] },
    { id: 35, name: '静岡', areas: [35] },
    { id: 33, name: '愛知', areas: [33] },
    { id: 38, name: '三重', areas: [38] },
    { id: 45, name: '滋賀', areas: [45] },
    { id: 41, name: '京都', areas: [41] },
    { id: 40, name: '大阪', areas: [40] },
    { id: 42, name: '兵庫', areas: [42] },
    { id: 44, name: '奈良', areas: [44] },
    { id: 43, name: '和歌山', areas: [43] },
    { id: 49, name: '鳥取', areas: [49] },
    { id: 48, name: '島根', areas: [48] },
    { id: 47, name: '岡山', areas: [47] },
    { id: 46, name: '広島', areas: [46] },
    { id: 50, name: '山口', areas: [50] },
    { id: 53, name: '徳島', areas: [53] },
    { id: 52, name: '香川', areas: [52] },
    { id: 51, name: '愛媛', areas: [51] },
    { id: 54, name: '高知', areas: [54] },
    { id: 55, name: '福岡', areas: [55] },
    { id: 61, name: '佐賀', areas: [61] },
    { id: 57, name: '長崎', areas: [57] },
    { id: 56, name: '熊本', areas: [56] },
    { id: 60, name: '大分', areas: [60] },
    { id: 59, name: '宮崎', areas: [59] },
    { id: 58, name: '鹿児島', areas: [58] },
    { id: 62, name: '沖縄', areas: [62] }
  ],
  currentEpgUrlTemplate: 'https://tv.so-net.ne.jp/rss/schedulesByCurrentTime.action?group=10&stationAreaId={area}',
  epgTableUrlTemplate: 'https://www.tvkingdom.jp/chart/{area}.action?head={YYYYMMDDHHmm}&span=24&group=10',
  bsCurrentEpgUrls: [
    'https://tv.so-net.ne.jp/rss/schedulesByCurrentTime.action?group=21',
    'https://tv.so-net.ne.jp/rss/schedulesByCurrentTime.action?group=22'
  ],
  bsEpgTableUrls: [
    'https://www.tvkingdom.jp/chart/bs1.action?head={YYYYMMDDHHmm}&span=24&group=21',
    'https://www.tvkingdom.jp/chart/bs2.action?head={YYYYMMDDHHmm}&span=24&group=22'
  ],
  stations: [
    // 北海道
    { name: 'HBC北海道放送', channel: 1 },
    { name: 'NHKEテレ1・札幌', channel: 2 },
    { name: 'NHKEテレ1・函館', channel: 2 },
    { name: 'NHKEテレ1・旭川', channel: 2 },
    { name: 'NHKEテレ1・帯広', channel: 2 },
    { name: 'NHKEテレ1・釧路', channel: 2 },
    { name: 'NHKEテレ1・北見', channel: 2 },
    { name: 'NHKEテレ1・室蘭', channel: 2 },
    { name: 'NHK総合1・札幌', channel: 3 },
    { name: 'NHK総合1・函館', channel: 3 },
    { name: 'NHK総合1・旭川', channel: 3 },
    { name: 'NHK総合1・帯広', channel: 3 },
    { name: 'NHK総合1・釧路', channel: 3 },
    { name: 'NHK総合1・北見', channel: 3 },
    { name: 'NHK総合1・室蘭', channel: 3 },
    { name: '札幌テレビ', channel: 5 },
    { name: 'HTB', channel: 6 },
    { name: 'TVh', channel: 7 },
    { name: '北海道文化放送', channel: 8 },

    // 東北
    { name: 'NHK総合1・仙台', channel: 3 },
    { name: 'NHK総合1・秋田', channel: 1 },
    { name: 'NHK総合1・山形', channel: 1 },
    { name: 'NHK総合1・盛岡', channel: 1 },
    { name: 'NHK総合1・福島', channel: 1 },
    { name: 'NHK総合1・青森', channel: 3 },
    { name: 'NHKEテレ1・仙台', channel: 2 },
    { name: 'NHKEテレ1・秋田', channel: 2 },
    { name: 'NHKEテレ1・山形', channel: 2 },
    { name: 'NHKEテレ1・盛岡', channel: 2 },
    { name: 'NHKEテレ1・福島', channel: 2 },
    { name: 'NHKEテレ1・青森', channel: 2 },
    { name: 'TBCテレビ', channel: 1 },
    { name: 'RAB青森放送', channel: 1 },
    { name: 'ミヤギテレビ', channel: 4 },
    { name: 'ABS秋田放送1', channel: 4 },
    { name: '山形放送', channel: 4 },
    { name: 'テレビ岩手', channel: 4 },
    { name: '福島中央テレビ', channel: 4 },
    { name: '東日本放送', channel: 5 },
    { name: '秋田朝日放送', channel: 5 },
    { name: '山形テレビ', channel: 5 },
    { name: '岩手朝日テレビ', channel: 5 },
    { name: 'KFB福島放送', channel: 5 },
    { name: '青森朝日放送', channel: 5 },
    { name: 'TUY', channel: 6 },
    { name: 'IBCテレビ', channel: 6 },
    { name: 'テレビユー福島', channel: 6 },
    { name: 'ATV青森テレビ', channel: 6 },
    { name: '仙台放送', channel: 8 },
    { name: 'AKT秋田テレビ', channel: 8 },
    { name: 'さくらんぼテレビ', channel: 8 },
    { name: 'めんこいテレビ', channel: 8 },
    { name: 'FTV福島テレビ', channel: 8 },

    // 関東
    { name: 'NHK総合1・東京', channel: 1 },
    { name: 'NHKEテレ1・東京', channel: 2 },
    { name: 'tvk', channel: 3 },
    { name: '群馬テレビ', channel: 3 },
    { name: 'チバテレ', channel: 3 },
    { name: 'とちぎテレビ', channel: 3 },
    { name: 'テレ玉', channel: 3 },
    { name: 'チバテレ', channel: 3 },
    { name: '日テレ', channel: 4 },
    { name: 'テレビ朝日', channel: 5 },
    { name: 'TBS', channel: 6 },
    { name: 'テレビ東京', channel: 7 },
    { name: 'フジテレビ', channel: 8 },
    { name: 'TOKYO MX1', channel: 9 },
    { name: 'NHK総合1・前橋', channel: 10 },
    { name: 'NHK総合1・水戸', channel: 10 },
    { name: 'NHK総合1・宇都宮', channel: 10 },

    // 中部
    { name: 'NHK総合1・長野', channel: 1 },
    { name: 'NHK総合1・新潟', channel: 1 },
    { name: 'NHK総合1・甲府', channel: 1 },
    { name: 'NHK総合1・名古屋', channel: 3 },
    { name: 'NHK総合1・金沢', channel: 1 },
    { name: 'NHK総合1・静岡', channel: 1 },
    { name: 'NHK総合1・福井', channel: 1 },
    { name: 'NHK総合1・富山', channel: 3 },
    { name: 'NHK総合1・津', channel: 3 },
    { name: 'NHK総合1・岐阜', channel: 3 },
    { name: 'NHKEテレ1・長野', channel: 2 },
    { name: 'NHKEテレ1・新潟', channel: 2 },
    { name: 'NHKEテレ1・甲府', channel: 2 },
    { name: 'NHKEテレ1・名古屋', channel: 2 },
    { name: 'NHKEテレ1・金沢', channel: 2 },
    { name: 'NHKEテレ1・静岡', channel: 2 },
    { name: 'NHKEテレ1・福井', channel: 2 },
    { name: 'NHKEテレ1・富山', channel: 2 },
    { name: 'NHKEテレ1・津', channel: 2 },
    { name: 'NHKEテレ1・岐阜', channel: 2 },
    { name: '東海テレビ', channel: 1 },
    { name: 'KNBテレビ', channel: 1 },
    { name: 'TeNY', channel: 4 },
    { name: '山梨放送', channel: 4 },
    { name: '中京テレビ', channel: 4 },
    { name: 'テレビ金沢', channel: 4 },
    { name: 'Daiichi-TV', channel: 4 },
    { name: '長野朝日放送', channel: 5 },
    { name: '新潟テレビ21', channel: 5 },
    { name: 'CBCテレビ', channel: 5 },
    { name: 'HAB', channel: 5 },
    { name: '静岡朝日テレビ', channel: 5 },
    { name: 'SBC信越放送', channel: 6 },
    { name: 'BSN', channel: 6 },
    { name: 'UTY', channel: 6 },
    { name: 'メ~テレ', channel: 6 },
    { name: 'MRO', channel: 6 },
    { name: 'SBS', channel: 6 },
    { name: 'チューリップテレビ', channel: 6 },
    { name: '三重テレビ1', channel: 7 },
    { name: '長野放送', channel: 8 },
    { name: '新潟総合テレビ', channel: 8 },
    { name: '石川テレビ', channel: 8 },
    { name: 'テレビ静岡', channel: 8 },
    { name: '富山テレビ放送', channel: 8 },
    { name: 'ぎふチャン', channel: 8 },
    { name: 'テレビ愛知', channel: 10 },

    // 近畿
    { name: 'NHK総合1・大阪', channel: 1 },
    { name: 'NHK総合1・京都', channel: 1 },
    { name: 'NHK総合1・神戸', channel: 1 },
    { name: 'NHK総合1・和歌山', channel: 1 },
    { name: 'NHK総合1・奈良', channel: 1 },
    { name: 'NHK総合1・大津', channel: 1 },
    { name: 'NHKEテレ1・大阪', channel: 2 },
    { name: 'サンテレビ1', channel: 3 },
    { name: 'BBCびわ湖放送', channel: 3 },
    { name: 'MBS毎日放送', channel: 4 },
    { name: 'KBS京都', channel: 5 },
    { name: 'WTV', channel: 5 },
    { name: 'ABCテレビ', channel: 6 },
    { name: 'テレビ大阪', channel: 7 },
    { name: '関西テレビ', channel: 8 },
    { name: '奈良テレビ', channel: 9 },
    { name: 'よみうりテレビ', channel: 10 },

    // 中国
    { name: 'NHK総合1・広島', channel: 1 },
    { name: 'NHK総合1・岡山', channel: 1 },
    { name: 'NHK総合1・松江', channel: 3 },
    { name: 'NHK総合1・鳥取', channel: 3 },
    { name: 'NHK総合1・山口', channel: 1 },
    { name: 'NHKEテレ1・広島', channel: 2 },
    { name: 'NHKEテレ1・岡山', channel: 2 },
    { name: 'NHKEテレ1・松江', channel: 2 },
    { name: 'NHKEテレ1・鳥取', channel: 2 },
    { name: 'NHKEテレ1・山口', channel: 2 },
    { name: '日本海テレビ', channel: 1 },
    { name: 'RCCテレビ', channel: 3 },
    { name: 'tysテレビ山口', channel: 3 },
    { name: '広島テレビ', channel: 4 },
    { name: 'RNC西日本テレビ', channel: 4 },
    { name: '山口放送1', channel: 4 },
    { name: '広島ホームテレビ', channel: 5 },
    { name: '瀬戸内海放送', channel: 5 },
    { name: 'yab山口朝日', channel: 5 },
    { name: 'RSKテレビ', channel: 6 },
    { name: 'BSSテレビ', channel: 6 },
    { name: 'テレビせとうち', channel: 7 },
    { name: 'テレビ新広島', channel: 8 },
    { name: 'OHK', channel: 8 },
    { name: 'さんいん中央テレビ1', channel: 8 },

    // 四国
    { name: 'NHK総合1・松山', channel: 1 },
    { name: 'NHK総合1・高松', channel: 1 },
    { name: 'NHK総合1・徳島', channel: 3 },
    { name: 'NHK総合1・高知', channel: 1 },
    { name: 'NHKEテレ1・松山', channel: 2 },
    { name: 'NHKEテレ1・高松', channel: 2 },
    { name: 'NHKEテレ1・徳島', channel: 2 },
    { name: 'NHKEテレ1・高知', channel: 2 },
    { name: '四国放送', channel: 1 },
    { name: '南海放送', channel: 4 },
    { name: '高知放送', channel: 4 },
    { name: '愛媛朝日テレビ', channel: 5 },
    { name: 'あいテレビ', channel: 6 },
    { name: 'テレビ高知', channel: 6 },
    { name: 'テレビ愛媛', channel: 8 },
    { name: '高知さんさんテレビ', channel: 8 },

    // 九州・沖縄
    { name: 'NHK総合1・福岡', channel: 3 },
    { name: 'NHK総合1・熊本', channel: 1 },
    { name: 'NHK総合1・長崎', channel: 1 },
    { name: 'NHK総合1・鹿児島', channel: 3 },
    { name: 'NHK総合1・宮崎', channel: 1 },
    { name: 'NHK総合1・大分', channel: 1 },
    { name: 'NHK総合1・佐賀', channel: 1 },
    { name: 'NHK総合1・沖縄', channel: 1 },
    { name: 'NHKEテレ1・福岡', channel: 2 },
    { name: 'NHKEテレ1・熊本', channel: 2 },
    { name: 'NHKEテレ1・長崎', channel: 2 },
    { name: 'NHKEテレ1・鹿児島', channel: 2 },
    { name: 'NHKEテレ1・宮崎', channel: 2 },
    { name: 'NHKEテレ1・大分', channel: 2 },
    { name: 'NHKEテレ1・佐賀', channel: 2 },
    { name: 'NHKEテレ1・沖縄', channel: 2 },
    { name: 'KBCテレビ', channel: 1 },
    { name: 'MBC南日本放送', channel: 1 },
    { name: 'RKK熊本放送', channel: 3 },
    { name: 'NBC長崎放送', channel: 3 },
    { name: 'テレビ宮崎', channel: 3 },
    { name: 'OBS大分放送', channel: 3 },
    { name: 'STSサガテレビ', channel: 3 },
    { name: 'RBCテレビ', channel: 3 },
    { name: 'RKB毎日放送', channel: 4 },
    { name: 'くまもと県民', channel: 4 },
    { name: '長崎国際テレビ', channel: 4 },
    { name: '鹿児島讀賣テレビ', channel: 4 },
    { name: 'TOSテレビ大分', channel: 4 },
    { name: 'FBS福岡放送', channel: 5 },
    { name: 'KAB熊本朝日放送', channel: 5 },
    { name: 'NCC長崎文化放送', channel: 5 },
    { name: 'KKB鹿児島放送', channel: 5 },
    { name: 'OAB大分朝日放送', channel: 5 },
    { name: '琉球朝日放送', channel: 5 },
    { name: 'MRT宮崎放送', channel: 6 },
    { name: 'TVQ九州放送', channel: 7 },
    { name: 'テレビ西日本', channel: 8 },
    { name: 'テレビ熊本', channel: 8 },
    { name: 'テレビ長崎', channel: 8 },
    { name: '鹿児島テレビ放送', channel: 8 },
    { name: '沖縄テレビ', channel: 8 },
    { name: 'NHKEテレ1・北九州', channel: 10 },
    { name: 'NHK総合1・北九州', channel: 11 },

    // BS
    { name: 'NHK BS1', channel: 101 },
    { name: 'NHK BSプレミアム', channel: 103 },
    { name: 'BS日テレ', channel: 141 },
    { name: 'BS朝日', channel: 151 },
    { name: 'BS-TBS', channel: 161 },
    { name: 'BSテレ東', channel: 171 },
    { name: 'BSフジ', channel: 181 },
    { name: 'WOWOWプライム', channel: 191 },
    { name: 'WOWOWライブ', channel: 192 },
    { name: 'WOWOWシネマ', channel: 193 },
    { name: 'BS11イレブン', channel: 211 },
    { name: 'BS12 トゥエルビ', channel: 222 }
  ],
  categories: [
    { id: -1, name: '未設定', enabled: false },
    { id: 0, name: 'ドラマ', enabled: true },
    { id: 5, name: 'アニメ/特撮', enabled: true },
    { id: 4, name: 'バラエティ', enabled: true },
    { id: 9, name: 'ドキュメンタリー/教養', enabled: true },
    { id: 7, name: 'ニュース/報道', enabled: true },
    { id: 8, name: '情報/ワイドショー', enabled: true },
    { id: 2, name: 'スポーツ', enabled: true },
    { id: 3, name: '音楽', enabled: true },
    { id: 1, name: '映画', enabled: true },
    { id: 6, name: '趣味/教育', enabled: true },
    { id: 10, name: '劇場/公演', enabled: true },
    { id: 11, name: '福祉', enabled: true },
    { id: 12, name: 'その他', enabled: true },
    { id: 1099, name: '夏期五輪_総合', enabled: true },
    { id: 1000, name: '夏期五輪_水泳', enabled: true },
    { id: 1001, name: '夏期五輪_アーチェリー', enabled: true },
    { id: 1002, name: '夏期五輪_陸上', enabled: true },
    { id: 1003, name: '夏期五輪_バドミントン', enabled: true },
    { id: 1004, name: '夏期五輪_野球・ソフトボール', enabled: true },
    { id: 1005, name: '夏期五輪_バスケットボール', enabled: true },
    { id: 1006, name: '夏期五輪_ボクシング', enabled: true },
    { id: 1007, name: '夏期五輪_カヌー', enabled: true },
    { id: 1008, name: '夏期五輪_自転車', enabled: true },
    { id: 1009, name: '夏期五輪_馬術', enabled: true },
    { id: 1010, name: '夏期五輪_フェンシング', enabled: true },
    { id: 1011, name: '夏期五輪_サッカー', enabled: true },
    { id: 1012, name: '夏期五輪_ゴルフ', enabled: true },
    { id: 1013, name: '夏期五輪_体操', enabled: true },
    { id: 1014, name: '夏期五輪_ハンドボール', enabled: true },
    { id: 1015, name: '夏期五輪_ホッケー', enabled: true },
    { id: 1016, name: '夏期五輪_柔道', enabled: true },
    { id: 1017, name: '夏期五輪_空手', enabled: true },
    { id: 1018, name: '夏期五輪_近代五種', enabled: true },
    { id: 1019, name: '夏期五輪_ボート', enabled: true },
    { id: 1020, name: '夏期五輪_ラグビー', enabled: true },
    { id: 1021, name: '夏期五輪_セーリング', enabled: true },
    { id: 1022, name: '夏期五輪_射撃', enabled: true },
    { id: 1023, name: '夏期五輪_スケートボード', enabled: true },
    { id: 1024, name: '夏期五輪_スポーツクライミング', enabled: true },
    { id: 1025, name: '夏期五輪_サーフィン', enabled: true },
    { id: 1026, name: '夏期五輪_卓球', enabled: true },
    { id: 1027, name: '夏期五輪_テコンドー', enabled: true },
    { id: 1028, name: '夏期五輪_テニス', enabled: true },
    { id: 1029, name: '夏期五輪_トライアスロン', enabled: true },
    { id: 1030, name: '夏期五輪_バレーボール', enabled: true },
    { id: 1031, name: '夏期五輪_ウエイトリフティング', enabled: true },
    { id: 1032, name: '夏期五輪_レスリング', enabled: true },
    { id: 1199, name: '夏期パラ_総合', enabled: true },
    { id: 1100, name: '夏期パラ_アーチェリー', enabled: true },
    { id: 1101, name: '夏期パラ_バドミントン', enabled: true },
    { id: 1102, name: '夏期パラ_ボッチャ', enabled: true },
    { id: 1103, name: '夏期パラ_カヌー', enabled: true },
    { id: 1104, name: '夏期パラ_自転車', enabled: true },
    { id: 1105, name: '夏期パラ_馬術', enabled: true },
    { id: 1106, name: '夏期パラ_サッカー', enabled: true },
    { id: 1107, name: '夏期パラ_ゴールボール', enabled: true },
    { id: 1108, name: '夏期パラ_柔道', enabled: true },
    { id: 1109, name: '夏期パラ_陸上', enabled: true },
    { id: 1110, name: '夏期パラ_パワーリフティング', enabled: true },
    { id: 1111, name: '夏期パラ_水泳', enabled: true },
    { id: 1112, name: '夏期パラ_ボート', enabled: true },
    { id: 1113, name: '夏期パラ_射撃', enabled: true },
    { id: 1114, name: '夏期パラ_バレーボール', enabled: true },
    { id: 1115, name: '夏期パラ_卓球', enabled: true },
    { id: 1116, name: '夏期パラ_テコンドー', enabled: true },
    { id: 1117, name: '夏期パラ_トライアスロン', enabled: true },
    { id: 1118, name: '夏期パラ_バスケットボール', enabled: true },
    { id: 1119, name: '夏期パラ_フェンシング', enabled: true },
    { id: 1120, name: '夏期パラ_ラグビー', enabled: true },
    { id: 1121, name: '夏期パラ_テニス', enabled: true },
    { id: 2099, name: '冬期五輪_総合', enabled: true },
    { id: 2000, name: '冬期五輪_アイスホッケー', enabled: true },
    { id: 2001, name: '冬期五輪_アルペンスキー', enabled: true },
    { id: 2002, name: '冬期五輪_カーリング ', enabled: true },
    { id: 2003, name: '冬期五輪_クロスカントリースキー', enabled: true },
    { id: 2004, name: '冬期五輪_ショートトラック', enabled: true },
    { id: 2005, name: '冬期五輪_スキージャンプ', enabled: true },
    { id: 2006, name: '冬期五輪_スケルトン', enabled: true },
    { id: 2007, name: '冬期五輪_スノーボード', enabled: true },
    { id: 2008, name: '冬期五輪_スピードスケート', enabled: true },
    { id: 2009, name: '冬期五輪_ノルディック複合', enabled: true },
    { id: 2010, name: '冬期五輪_バイアスロン', enabled: true },
    { id: 2011, name: '冬期五輪_フィギュアスケート', enabled: true },
    { id: 2012, name: '冬期五輪_フリースタイルスキー', enabled: true },
    { id: 2013, name: '冬期五輪_ボブスレー', enabled: true },
    { id: 2014, name: '冬期五輪_リュージュ', enabled: true },
    { id: 2199, name: '冬期パラ_総合', enabled: true },
    { id: 2100, name: '冬期パラ_アルペンスキー', enabled: true },
    { id: 2101, name: '冬期パラ_クロスカントリースキー', enabled: true },
    { id: 2102, name: '冬期パラ_バイアスロン', enabled: true },
    { id: 2103, name: '冬期パラ_スノーボード', enabled: true },
    { id: 2104, name: '冬期パラ_パラアイスホッケー', enabled: true },
    { id: 2105, name: '冬期パラ_車いすカーリング ', enabled: true }
  ],
  epgTable: {
    hours: 24,
    columnWidth: 120,
    heightPerMin: 3
  }
}

export default _config
