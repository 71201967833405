export default class Topic {
  constructor () {
    this.id = ''
    this.publicId = ''
    this.categoryId = -1
    this.manualTitle = ''
    this.autoTitle = ''
    this.searchWords = []
    this.additionalSearchWords = []
    this.notWords = []
    this.hashtags = []
    this.additionalHashtags = []
    this.matchWords = []
    this.notMatchWords = []
  }

  getData () {
    const result = {}
    Object.keys(this).map((key) => { result[key] = this[key] })
    return result
  }
}
