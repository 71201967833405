import { firestore } from '@/firebase/firebase'
import _ from 'lodash'
import dayjs from 'dayjs'
import NameIdentification from '@/plugins/NameIdentification'

const publicTopicsRef = firestore.collection('publicTopics')

export default {
  namespaced: true,
  unsubscribePublicTopics: null,
  state () {
    return {
      data: []
    }
  },
  mutations: {
    set (state, payload) {
      state.data = payload
    }
  },
  getters: {
    data (state) {
      return state.data
    }
  },
  actions: {
    startListener ({ commit }, payload) {
      if (this.unsubscribePublicTopics) {
        console.warn('listener is running. ', this.unsubscribePublicTopics)
        this.unsubscribePublicTopics()
        this.unsubscribePublicTopics = null
      }

      // 3. Firestoreからデータを検索する
      this.unsubscribePublicTopics = publicTopicsRef.doc('topics').onSnapshot(doc => {
        // 6. データが更新されるたびに呼び出される
        if (doc.data()) {
          commit('set', doc.data().topics)
        }
      },
      (error) => {
        console.error(error.name)
      })
    },
    // 8. リスナーの停止
    stopListener () {
      if (this.unsubscribePublicTopics) {
        console.log('listener is stopping. ', this.unsubscribePublicTopics)
        this.unsubscribePublicTopics()
        this.unsubscribePublicTopics = null
      }
    },
    set ({ commit, state }, payload) {
      const topic = payload.topic
      topic.updatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss')

      let storeTopics = _.cloneDeep(state.data)
      const index = storeTopics.findIndex(storeTopic => storeTopic.publicId === topic.publicId)
      if (index !== -1) {
        storeTopics[index] = topic
      } else {
        storeTopics.push(topic)
      }

      // タイトル順にソート
      storeTopics = storeTopics.map(topic => {
        const title = topic.manualTitle || topic.autoTitle

        // タイトルを小文字,カタカナに変換し、sortKeyとして登録
        topic.sortKey = NameIdentification.convert(title)

        return topic
      })
      storeTopics = _.orderBy(storeTopics, ['categoryId', 'sortKey'], ['asc', 'asc'])

      // Firestoreに保存
      publicTopicsRef.doc('topics').set({ topics: storeTopics })
        .then(doc => {
          publicTopicsRef.doc(dayjs().format('YYYY-MM-DD')).set({ topics: storeTopics })
        })
        .catch(err => {
          console.error('Error setting document: ', err)
        })
    },
    setAll ({ commit }, payload) {
      publicTopicsRef.doc('topics').set({ topics: payload.topics })
        .then(doc => {
          publicTopicsRef.doc(dayjs().format('YYYY-MM-DD')).set({ topics: payload.topics })
        })
        .catch(err => {
          console.error('Error setting document: ', err)
        })
    },
    remove ({ commit, state }, payload) {
      const storeTopics = _.cloneDeep(state.data)
      const index = storeTopics.findIndex(storeTopic => storeTopic.publicId === payload.topicId)
      if (index !== -1) {
        storeTopics.splice(index, 1)
      }

      publicTopicsRef.doc('topics').set({ topics: storeTopics })
        .then(doc => {
        })
        .catch(err => {
          console.error('Error removing document: ', err)
        })
    },
    clear ({ commit }) {
      commit('set', [])
    }
  }
}
