<template>
  <v-ons-page id="postPage">
    <v-ons-toolbar>
      <div class="left">
        <v-ons-toolbar-button @click="pop">キャンセル</v-ons-toolbar-button>
      </div>
      <div class="center">{{title}}</div>
      <div class="right">
        <v-ons-toolbar-button @click="submitPost">送信</v-ons-toolbar-button>
      </div>
    </v-ons-toolbar>

    <div class="post-block page-body">
      <post-parts :is-post-page="true" :post-type="postType || 'tweet'" :topic="topic" :tweet="tweet || null"/>
    </div><!-- /.post-block -->
  </v-ons-page>
</template>

<script>
import PostParts from '@/components/organisms/PostParts'
export default {
  name: 'PostPage',
  components: {
    PostParts
  },
  data () {
    return {
      title: null,
      tweet: null,
      postType: null
    }
  },
  mounted () {
    this.$eventHub.$on('submit-post-done', this.pop)

    // postTypeごとの設定
    switch (this.postType) {
      case 'reply':
        this.title = '返信'
        break
      case 'quote':
        this.title = 'コメントを付けてリツイート'
        break
      default:
        this.title = 'ツイート'
        break
    }
  },
  destroyed () {
    this.$eventHub.$off('submit-post-done')
  },
  methods: {
    submitPost () {
      this.$eventHub.$emit('submit-post')
    },

    pop () {
      this.$emit('pop-page')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

</style>
