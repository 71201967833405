<template>
  <div class="topic-setting">
    <section v-if="!isMixedTopic">
      <h2>タイトル</h2>
      <div class="title-input-block">
        <input ref="titleInput" class="title-input" placeholder="タイトル" v-model="localTopic.manualTitle"/>
        <a @click="clearTitle" class="delete-title-botton ion"><ons-icon class="ion" icon="ion-ios-close-circle" fixed-width="false"></ons-icon></a>
      </div><!-- /.title-input-block -->
    </section>

    <section v-if="isAdmin && !isMixedTopic">
      <h2>カテゴリー</h2>
      <div class="category-input-block">
        <v-ons-select v-model="localTopic.categoryId">
          <option v-for="category in categories" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </v-ons-select>
      </div><!-- /.category-input-block -->
    </section>

    <section>
      <h2>検索ワード</h2>
      <div class="search-words-input-block">
        <word-input-set
          :words="localTopic.searchWords"
          placeholder="検索ワード"
          :submit-enable="false"
          :initial-focus="false"
          @update = "localTopic.searchWords = $event.words; updateHashtags();updateAdditionalSearchWords()"
        />
      </div><!-- /.search-words-input-block -->
    </section>

    <section>
      <h2>除外ワード</h2>
      <div class="not-words-input-block">
        <word-input-set
          :words="localTopic.notWords"
          placeholder="除外ワード"
          :submit-enable="false"
          :initial-focus="false"
          @update = "localTopic.notWords = $event.words"
        />
      </div><!-- /.not-words-input-block -->
    </section>

    <section>
      <h2>投稿用ハッシュタグ</h2>
      <div class="hashtags-input-block">
        <ul class="hashtag-list">
          <li class="hashtag-item" v-for="(hashtag, index) in localTopic.hashtags" :key="index" :class="{disabled: !hashtag.enable}">
            <a @click="toggleHashtagEnable(index)">
              <div class="surface">{{hashtag.surface}}</div>
              <div class="check">
                <ons-icon class="ion" icon="ion-ios-checkmark" size="32px"></ons-icon>
              </div><!-- /.check -->
            </a>
          </li><!-- /.hashtag-item-->
        </ul>
        <word-input-set
          :words="localTopic.additionalHashtags"
          placeholder="投稿用ハッシュタグ"
          :submit-enable="false"
          :initial-focus="false"
          @update = "localTopic.additionalHashtags = $event.words"
        />
      </div><!-- /.search-words-input-block -->
    </section>

    <section v-if="isAdmin && !isMixedTopic">
      <h2>番組名へのマッチングワード</h2>
      <div class="match-words-input-block">
        <word-input-set
          :words="localTopic.matchWords"
          placeholder="マッチングワード"
          :submit-enable="false"
          :initial-focus="false"
          :match-condition-enable="true"
          logical-operator="AND"
          @update = "localTopic.matchWords = $event.words"
        />
      </div><!-- /.search-words-input-block -->
    </section>

    <section v-if="isAdmin && !isMixedTopic">
      <h2>番組名へのマッチング除外ワード</h2>
      <div class="not-match-words-input-block">
        <word-input-set
          :words="localTopic.notMatchWords"
          placeholder="マッチング除外ワード"
          :submit-enable="false"
          :initial-focus="false"
          :match-condition-enable="true"
          @update = "localTopic.notMatchWords = $event.words"
        />
      </div><!-- /.not-words-input-block -->
    </section>
  </div><!-- /.topic-setting-block -->
</template>

<script>
import WordInputSet from '@/components/organisms/WordInputSet'
import TopicSettingCommon from '@/mixins/TopicSettingCommon'
import Publish from '@/mixins/Publish'
import AdminCheck from '@/mixins/AdminCheck'
import Config from '@/plugins/Config'

export default {
  name: 'TopicSetting',
  components: {
    WordInputSet
  },
  mixins: [TopicSettingCommon, Publish, AdminCheck],
  data () {
    return {
      localTopic: {
        manualTitle: '',
        searchWords: [],
        notWords: [],
        additionalHashtags: []
      },
      categories: Config.categories
    }
  },
  props: ['topic', 'isPublicTopic', 'isMixedTopic'],
  computed: {
    storeUser () {
      return this.$store.getters['user/data']
    }
  },
  mounted () {
    this.$eventHub.$on('submit-topic-setting', (callback) => this.submit(callback))

    // トピックを設定
    this.setLocalTopic()
  },
  destroyed () {
    this.$eventHub.$off('submit-topic-setting')
  },
  watch: {
    topic (newVal, oldVal) {
      // トピックを設定
      this.setLocalTopic()
    }
  },
  methods: {
    clearTitle () {
      this.localTopic.manualTitle = ''
    },

    setLocalTopic () {
      if (this.topic) {
        this.localTopic = this._.cloneDeep(this.topic)
      }
    },

    toggleHashtagEnable (index) {
      this.localTopic.hashtags[index].enable = !this.localTopic.hashtags[index].enable
    },

    updateHashtags () {
      this.localTopic.hashtags = this.getAutoHashtags(this.localTopic.searchWords, this.localTopic.hashtags)
    },

    updateAdditionalSearchWords () {
      this.localTopic.additionalSearchWords = this.getAdditionalSearchWords(this.localTopic.searchWords)
    },

    submit (callback) {
      const topic = this._.cloneDeep(this.localTopic)
      topic.searchWords = topic.searchWords ? topic.searchWords.filter(word => word) : []
      topic.notWords = topic.notWords ? topic.notWords.filter(word => word) : []
      topic.matchWords = topic.matchWords ? topic.matchWords.filter(word => word.surface) : []
      topic.notMatchWords = topic.notMatchWords ? topic.notMatchWords.filter(word => word.surface) : []
      topic.additionalHashtags = topic.additionalHashtags ? topic.additionalHashtags.filter(hashtag => hashtag) : []
      topic.autoTitle = this.getAutoTitle(topic.searchWords)

      // callback実行
      callback(topic)

      // firestoreHistoryに登録
      this.$store.dispatch('firestoreHistory/set', { userId: this.storeUser.id, topic: this._.cloneDeep(topic) })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.topic-setting {
  max-width: $max-width;
  margin: 0 auto 16px;
}

section {
  margin-top: 8px;
  background-color: white;
  border: solid 1px $base-light-gray;
  border-radius: 4px;

  h2 {
    margin: 0;
    padding: 8px 16px;
    // background-color: $base-gray;
    font-size: 0.875rem;
    font-weight: bold;
    // color: $text-white;
  }
}

.category-input-block {
  position: relative;
  margin: 16px;

  ons-select {
    width: 100%;
    height: 32px;
    padding: 0 12px;
    background-image: none;
    background-color: $base-light-gray;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    color: $text-black;
  }
}

.title-input-block {
  position: relative;
  margin: 16px;

  .title-input {
    width: 100%;
    height: 32px;
    padding: 6px 32px 6px 12px;
    background-color: $base-light-gray;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    color: $text-black;
  }

  .delete-title-botton {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    text-align: center;
    line-height: 32px;
    color: $base-medium-light-gray;
  }

  .delete-title-botton:hover {
    color: $text-black;
  }
}

.hashtags-input-block {
  .hashtag-list {
    padding-top: 16px;
  }
  .hashtag-item {
    padding: 0 16px 16px;
  }

  a {
    display: block;
    position: relative;
    padding: 5px 31px 5px 11px;
    border: solid 1px $base-light-gray;
    border-radius: 4px;
    line-height: 20px;
    color: $text-black;
  }

  .disabled a {
    color: $text-light;
  }

  .check {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
  }

  .disabled .check {
    display: none;
  }

  .word-input-set {
    margin-top: -16px;
  }
}

</style>
