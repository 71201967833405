<template>
  <v-ons-page id="remocon">
    <div class="page-body">
      <v-ons-toolbar class="segment-toolbar" :inline="true">
        <div class="center segment-buttons-container">
          <v-ons-segment class="segment-buttons" tabbar-id="tabbar" :index.sync="segmentIndex">
            <button>地上波</button>
            <button>BS</button>
          </v-ons-segment>
        </div>
      </v-ons-toolbar>
      <v-ons-tabbar
        id="tabbar"
        class="segment-tab"
        position="auto"
        :tabs="tabs"
        :index.sync="segmentIndex"
        swipeable
      >
      </v-ons-tabbar>
    </div><!-- /.page-body -->
  </v-ons-page>
</template>

<script>
import HomeCommon from '@/mixins/HomeCommon'
import RemoconButtons from '@/components/organisms/RemoconButtons'
import CurrentEpgData from '@/mixins/CurrentEpgData'

export default {
  name: 'Remocon',
  mixins: [HomeCommon, CurrentEpgData],
  data () {
    return {
      segmentIndex: 0,
      tabbarIndex: 0,
      tabs: [
        {
          page: RemoconButtons,
          props: {
            programs: [],
            loading: false
          },
          key: 'regular',
          category: 0
        },
        {
          page: RemoconButtons,
          props: {
            programs: [],
            loading: false
          },
          key: 'bs',
          category: 1
        }
      ]
    }
  },
  watch: {
    programsOfCategories (newVal, oldVal) {
      this.tabs[0].props.programs = this.programsOfCategories[0]
      this.tabs[1].props.programs = this.programsOfCategories[1]
    },
    currentEpgLoadStatus (newVal, oldVal) {
      this.tabs = this.tabs.map(tab => {
        tab.props.loading = this.currentEpgUrls.filter(url => url.category === tab.category).some(url => {
          const lastLoadTime = this.currentEpgLoadStatus[url.url] ? this.currentEpgLoadStatus[url.url].lastLoadTime : 0
          return (this.currentEpgLoadStatus[url.url] ? this.currentEpgLoadStatus[url.url].loading : false) && (this.$dayjs().unix() - lastLoadTime > 90)
        })
        return tab
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/import';

.segment-toolbar {
  position: absolute;
  width: fit-content;
  top: auto;
  left: 50%;
  bottom: 16px;
  transform: translate(-50%, 0);
  background: none;
  box-shadow: none;
  z-index: 100000;
}

.segment-buttons-container {
  max-width: 196px;
  margin: 0;
  padding: 0 8px;
  background-color: $white-opacity05;
  border-radius: 8px;
}

.segment-buttons {
  width: 180px;
}

.segment-buttons-container.toolbar--material__center {
  height: 52px;

  .segment-buttons {
    transform: translate(0, -4px);
  }
}
</style>
